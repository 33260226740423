<template>
  <div class="splitDeckSettings">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pl-3 pr-3">
            <div class="header col-12 col-md-12 pt-3">
              <!-- Card Text Settings German -->
              <div v-if="getCurrentLocale == 'de'">
                <div class="headline border-bottom border-dark mb-3 ">
                  <h1>
                    Wenn du Die Karten in zwei Stapel aufteilen willst kannst du das
                    hier einstellen.
                  </h1>
                </div>
                <div class="custom-control2 custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    checked
                    @change="handleChange"
                  />
                  <label class="custom-control-label" for="customSwitch1"
                    >Zwei Kartenstapel verwenden</label
                  >
                </div>
                <ul class="list-group list-group-flush unassigned-list">
                  <li
                    class="list-group-item unassigned-list-element"
                    v-for="option in unassignedDeckOptions"
                    :key="option.id"
                  >
                    <p class="unassigned-list-element__text">{{ option.title }}</p>
                    <div style="display: flex;" v-if="isTwoDecks">
                      <button
                        class="unassigned-list-element__assign-button"
                        @click="assignToDeck(option, 'deck1')"
                      >
                        Kartendeck 1
                      </button>
                      <button
                        class="unassigned-list-element__assign-button"
                        @click="assignToDeck(option, 'deck2')"
                      >
                        Kartendeck 2
                      </button>
                    </div>
                  </li>
                </ul>
                <div class="deck-wrapper" v-if="isTwoDecks">
                  <div class="deck">
                    <p class="font-weight-bold">Kartendeck 1</p>

                    <ul class="list-group list-group-flush">
                      <ul class="list-group list-group-flush">
                        <p v-if="deckOneOptions.length === 0">
                          Dem Kartendeck 2 sind keine Karten zugeteilt
                        </p>
                        <li
                          class="deck-element list-group-item"
                          v-for="option in deckOneOptions"
                          :key="option.id"
                        >
                          <p class="deck-element__text">{{ option.title }}</p>
                          <button
                            class="deck-element__unassign-button"
                            @click="assignToDeck(option, '')"
                          >
                            Karte Entfernen
                          </button>
                        </li>
                      </ul>
                    </ul>
                  </div>

                  <div class="deck">
                    <p class="font-weight-bold">Kartendeck 2</p>

                    <ul class="list-group list-group-flush">
                      <p v-if="deckTwoOptions.length === 0">
                        Dem Kartendeck 2 sind keine Karten zugeteilt
                      </p>
                      <li
                        class="deck-element list-group-item"
                        v-for="option in deckTwoOptions"
                        :key="option.id"
                      >
                        <p class="deck-element__text">{{ option.title }}</p>
                        <button
                          class="deck-element__unassign-button"
                          @click="assignToDeck(option, '')"
                        >
                          Karte Entfernen
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="button-wrapper">
                  <button
                    type="next"
                    class="btn btn-lg grey button-wrapper__button"
                    @click="goBack()"
                  >
                    Zurück
                  </button>

                  <button
                    type="next"
                    class="btn btn-lg grey button-wrapper__button"
                    @click="submitSplitDeckSettings()"
                  >
                    Weiter
                  </button>
                </div>
              </div>
              <div v-else>
                <div class="headline border-bottom border-dark mb-3 ">
                  <h1>
                    Here you can decide whether you want to seperate the cards into
                    two decks
                  </h1>
                </div>
                <div class="custom-control2 custom-switch">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="customSwitch1"
                    checked
                    @change="handleChange"
                  />
                  <label class="custom-control-label" for="customSwitch1"
                    >Split deck into two decks
                  </label>
                </div>
                <ul class="list-group list-group-flush unassigned-list">
                  <li
                    class="list-group-item unassigned-list-element"
                    v-for="option in unassignedDeckOptions"
                    :key="option.id"
                  >
                    <p class="unassigned-list-element__text">{{ option.title }}</p>
                    <div style="display: flex;" v-if="isTwoDecks">
                      <button
                        class="unassigned-list-element__assign-button"
                        @click="assignToDeck(option, 'deck1')"
                      >
                        Deck 1
                      </button>
                      <button
                        class="unassigned-list-element__assign-button"
                        @click="assignToDeck(option, 'deck2')"
                      >
                        Deck 2
                      </button>
                    </div>
                  </li>
                </ul>

                <div class="deck-wrapper" v-if="isTwoDecks">
                  <div class="deck">
                    <p class="font-weight-bold">Deck 1</p>

                    <ul class="list-group list-group-flush">
                      <ul class="list-group list-group-flush">
                        <p v-if="deckOneOptions.length === 0">
                          No options assigned to Deck 1
                        </p>
                        <li
                          class="deck-element list-group-item"
                          v-for="option in deckOneOptions"
                          :key="option.id"
                        >
                          <p class="deck-element__text">{{ option.title }}</p>
                          <button
                            class="deck-element__unassign-button"
                            @click="assignToDeck(option, '')"
                          >
                            Unassign
                          </button>
                        </li>
                      </ul>
                    </ul>
                  </div>

                  <div class="deck">
                    <p class="font-weight-bold">Deck 2</p>

                    <ul class="list-group list-group-flush">
                      <p v-if="deckTwoOptions.length === 0">
                        No options assigned to Deck 2
                      </p>
                      <li
                        class="deck-element list-group-item"
                        v-for="option in deckTwoOptions"
                        :key="option.id"
                      >
                        <p class="deck-element__text">{{ option.title }}</p>
                        <button
                          class="deck-element__unassign-button"
                          @click="assignToDeck(option, '')"
                        >
                          Unassign
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="button-wrapper">
                  <button
                    type="next"
                    class="btn btn-lg grey button-wrapper__button"
                    @click="goBack()"
                  >
                    Back
                  </button>

                  <button
                    type="next"
                    class="btn btn-lg grey button-wrapper__button"
                    @click="submitSplitDeckSettings()"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "../components/TabCreateCards.vue";
export default {
  name: "SplitDeckSettings",
  components: {
    Navbar,
    TabCreateCards
  },
  created() {
    // make database call
    // in the end, set
    // this.options = api result
  },
  data() {
    return {
      isTwoDecks: true,
      options: [],
    };
  },
  methods: {
    assignToDeck(option, deck) {
      if (
        this.options.filter((element) => element.deckType == "deck1").length ==
          1 &&
        option.deckType == "deck1"
      ) {
        alert("Deck 1 cannot be Empty!");
        return;
      }
      option.deckType = deck;
      if (option.status != "create") {
        option.status = "update";
      }
      if (this.$store.getters.getHighestChangedLayer != "L1") {
        this.$store.commit("updateHighestChangedLayer", "L1");
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    submitSplitDeckSettings() {
      // make api call to save it in database
      this.$store.commit("updateL1Nodes", this.options);
      this.$router.push("cardViewSettings");
    },
    handleChange(event) {
      const { checked: isChecked } = event.target;
      this.isTwoDecks = isChecked;

      if (!isChecked) {
        this.assignAllToDeck("deck1");
      }
    },
    assignAllToDeck(deck) {
      this.options.forEach((option) => {
        option.deckType = deck;
      });
    },
  },
  mounted() {
    // check if need to reset to deck1
    this.options = this.$store.getters.getL1Nodes;
  },
  computed: {
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
    unassignedDeckOptions() {
      return this.options.filter(
        (option) => option.deckType === undefined || option.deckType === ""
      );
    },
    deckOneOptions() {
      return this.options.filter((option) => option.deckType === "deck1");
    },
    deckTwoOptions() {
      return this.options.filter((option) => option.deckType === "deck2");
    },
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to loggedIn
    next((vm) => {
      if (vm.getLoggedIn == false) {
        console.log("Accessed view all page without initializing a game.");
        vm.$router.push("/loggedIn");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
.deck-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.deck {
  padding: 0 1rem;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
  }

  + .deck {
    margin-top: 2rem;
  }

  @media screen and (min-width: 600px) {
    width: 50%;

    + .deck {
      margin-top: 0;
    }
  }
}

.unassigned-list-element {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    margin: 0;
    padding: 0;
  }

  &__assign-button {
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0.25rem 0.5rem;
    margin: 0 4px;
  }
}

.deck-element {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    margin: 0;
    padding: 0;
  }

  &__unassign-button {
    border-radius: 4px;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0.25rem 0.5rem;
    margin: 0 4px;
  }
}

.unassigned-list {
  margin-top: 3rem;
}

.button-wrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: center;

  &__button {
    margin: 1%;
  }
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}
</style>
