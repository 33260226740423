<!-- Help Page showing Design Card Tutorial -->
<template>
  <div class="container">
    <navbar pos="Design Cards To Go" />

    <main
      class="d-flex justify-content-center mb-3 pl-7 pr-7 ml-0 mr-0"
      style="padding-top: 5px;"
    >
      <!-- Tutorial in German -->
      <!-- This tutorial is not up to date
      <div v-if="getCurrentLocale == 'de'">
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">Design Karten selbst erstellen</h1>
        </header>
      -->
      
        <!-- Carousel Tutorial German-->
        <!--
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style="width: 100%; padding: 5px;"
          data-interval="0"
        >
          <ol
            class="carousel-indicators"
            style=" margin-bottom:1%; margin-top:99%;"
          >
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="10"
            ></li>
          </ol>
          <div class="carousel-inner" style="margin-bottom: 20px;">-->
            <!-- Carousel Page 1 -->
            <!--
            <div class="carousel-item active">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Auf der linken Seite siehst du die LogIn Seite. Um zu dieser
                  zu gelangen kannst du "Neues Kartendeck erstellen" in der
                  Navigationsleiste am oberen Bildschirmrand auswählen. Um ein
                  neues Kartendeck erstellen zu können musst du dich hier
                  einloggen.
                  <br />
                  Zu deiner rechten siehst du das Pop-Up Fenster, wo du deine
                  E-Mail Addresse und dein Passwort eingeben kannst. Solltest du
                  noch keinen Account erstellt haben oder dein Passwort
                  vergessen haben, so kannst du dies hier ebenfalls tun.
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_Create.png"
                      alt="Screenshot von der Login seite"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_LogIn.png"
                      alt="Screenshot vom pop up fenster zum einloggen"
                      class="small-image"
                      style="width: 290px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div>-->
            <!-- Carousel Page 2-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Nachdem du dich eingeloggt hast, kommst du zu der Seite die
                  hier links zu sehen ist. Alle Kartendecks die je erstellt
                  wurden sind hier zu finden. Der Info-Button oben, erläutert
                  einem diese Seite und wenn du mehr zum Erstellen der
                  Kartendecks lernen möchtest findest du dort einen Link zu
                  diesem Tutorial.
                  <br />
                  An dieser Stelle hast du vier Möglichkeiten weiter zu machen:
                  Du kannst neue Kartendecks erstellen, bestehende verändern,
                  duplizieren oder löschen. (Wichtig: Du kannst nur Kartendecks
                  verändern oder löschen die du selbst erstellt hast!) Das
                  Duplizieren von bestehenden Kartendecks ist besonders
                  hilfreich, wenn du eines davon verändern möchtest. Zur
                  Erstellund eines neuen Decks gelangst du wenn du auf das Feld
                  "Neues Kartendeck erstellen".
                  <br />

                  Beim Klick auf eine der Karten öffnet sich ein Pop-Up Fenster,
                  worin du aus den bereits genannten Optionen wählen kannst (zu
                  sehen ist das auf der rechten Seite). <br />
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_DeckSelection.png"
                      alt="Screenshot of the Deck Selection page"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CRUD.png"
                      alt="Screenshot of the pop-up window, showing edit, copy delete options"
                      class="small-image"
                      style="width: 253px;"
                    />
                  </div>
                </div>
              </div>
            </div> -->

            <!-- Carousel Page 3-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Wenn du "Neues Kartendeck erstellen" oder "Deck kopieren"
                  ausgewählt hast landest du auf dieser Seite, hier kannst du
                  deinem Deck einen Namen geben:<br />
                </p>

                <img
                  src="@/assets/tutorialScreenshots/CreateTutorial/DE_NameSettings.png"
                  alt="Screenshot der Seite zur Namensgebung der selbst erstellten Designkarten"
                  class="image"
                  style="width: 500px;"
                />
                <br />
                <p class="paragraph">
                  Die nächste Seite bittet dich um Informationen zu deinem
                  Kartendeck. Wie können diese Karten deine BenutzerInnen
                  fordern? Sei kreativ!
                </p>
                <img
                  src="@/assets/tutorialScreenshots/CreateTutorial/DE_EnterInformation.png"
                  alt="Screenshot der Seite zur Beschreibung des Kartendecks"
                  class="image"
                  style="width: 500px;"
                />
              </div>
            </div> -->
            <!-- Carousel Page 4-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Jetzt geht der eigentliche Spaß los!
                  <br />
                  Wenn du auf dieser Seite (im linken Bild zu sehen) bist kannst
                  du deine Karten hinzufügen und so viele davon wie du möchtest!
                  Dazu klicke einfach auf das grüne Plus am unteren
                  Bildschirmrand. Daraufhin öffnet sich ein Fenster, wo du dir
                  Namen und den Kartentext aussuchen kannst. Hier kannst du dir
                  auch eine Farbe aussuchen, die deine Karten haben sollen.
                  Kunterbunt oder alle die gleiche Farbe, das bleibt dir
                  überlassen!
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CardTextSettings.png"
                      alt="Screenshot von der Übersicht der erstellten Karten"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CardTextModal.png"
                      alt="Screenshot vom Popup fenster der Kartentexterstellung"
                      class="small-image"
                      style="width: 308px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div>-->
            <!-- Carousel Page 5-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Wenn du merkst du hast eine Karte erstellt die du eigentlich
                  gar nicht haben möchtest, oder dir der Text darauf nicht
                  gefällt, dann klicke einfach darauf um sie zu ändern oder
                  löschen.
                  <br />
                  Und das Beste daran: Der Text wird gespeichert, somit ist es
                  ganz leicht Rechtschreibfehler zu beheben ohne alles neu
                  schreiben zu müssen!
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CardNameSettingsList.png"
                      alt="Screenshot der Liste erstellter Karten"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CardNameSettingsChange.png"
                      alt="Screenshot des Popup Fenster zum ändern von Kartentext"
                      class="small-image"
                      style="width: 301px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Carousel Page 6-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Hier kannst du dich entscheiden, ob du dein Kartendeck in zwei
                  Decks teilen möchtest oder ob dir eines zum Spielen reicht.
                  Auf der linken Seite siehst du erstere Option (Kartendeck
                  teilen) und auf der Rechten siehst du zweitere Option (nicht
                  teilen). Die Auswahl triffst du mit dem Regler "Zwei
                  Kartenstapel verwenden".
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_DeckSeparationSettings.png"
                      alt="Screenshot der Option Kartendeck Teilen"
                      class="image"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_DeckSeparationSettingsNoSplit.png"
                      alt="Screenshot der Option Kartendeck nicht Teilen"
                      class="image"
                      style="width: 646px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Carousel Page 7-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Wenn du dich entschieden hast die Karten aufzuteilen, dann
                  kannst du sie aus dem einen Stapel entfernen um sie dem
                  anderen Stapel hinzuzufügen. Sobald du zufrieden bist mit der
                  Verteilung der Karten kommst du durch Klick auf "Weiter" auf
                  die nachfolgende Seite.
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_DeckSeparationSettingsSplit.png"
                      alt="Screenshot der Option Kartendeck Teilen, Karte entfernt"
                      class="image"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_DeckSeparationSettingsSplitFinished.png"
                      alt="Screenshot der Option Kartendeck Teilen, Karte dem anderen Deck hinzugefügt."
                      class="image"
                      style="width: 646px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Carousel Page 8-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Deine Karten brauchen eine Anleitung um so verwendet zu werden
                  wie du dir das gedacht hast. Schreibe dazu zu jeder der drei
                  Kategorien eine kurze Beschreibung (Allgemeine Infos,
                  Mehrspieler-Modus und Einzelspieler-Modus) indem du auf jede
                  klickst.
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_InstructionSettings.png"
                      alt="Screenshot der Instruktionsseite"
                      class="image"
                    />
                  </div>
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_InstructionSettingsModal.png"
                      alt="Screenshot des Popup fensters zum befüllen der instruktionen"
                      class="small-image"
                      style="width: 386px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- Carousel Page 9-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Zu guter Letzt brauchen die Karten einen neuen Schliff. Wo
                  möchtest du den Text sehen? Oben? Unten? In der Mitte? Wenn du
                  fertig bist, klicke auf Speichern und Voilà - Das Kartendeck
                  gehört dir!
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CardViewSettings.png"
                      alt="Screenshot der Seite zum designen der Karte"
                      class="image"
                    />
                  </div>
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/DE_CardViewSettingsSaving.png"
                      alt="Screenshot des speicher prozesses"
                      class="small-image"
                      style="width: 317px; margin-left: 5px"
                    />
                  </div>
                </div>
                <p>
                  Zu Beachten: Die Erstellung funktioniert nicht, es startet den
                  Speicherprozess aber schließt ihn nie ab.
                </p>
              </div>
            </div> -->
            <!-- Carousel Page 10-->
            <!--
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  <br /><br />
                  <button
                    type="button"
                    class="btn btn-secondary btn-lg btn-block "
                    style="background: green;"
                    @click="goToCreate()"
                  >
                    Super du hast das Tutorial geschafft! Hier kommst du zur
                    Kartenerstellung
                  </button>
                  <br /><br />
                </p>
              </div>
            </div> 

            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div> -->
        <!-- Back Button -->
        <!--
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6 maa.5.5 0 0 1 .708 0z"
            />
          </svg>
          Zurück
        </button>
      </div>
      -->


      <!-- Tutorial in English -->
      <div>
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">
            Creating your own Design Cards
          </h1>
        </header>

        <!-- Carousel Tutorial English-->
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style="width: 100%; padding: 10px;"
          data-interval="0"
        >
          <ol
            class="carousel-indicators"
            style=" margin-bottom:1%; margin-top:99%;"
          >
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="10"
            ></li>
          </ol>
          <div class="carousel-inner" style="margin-bottom: 20px;">
            <!-- Carousel Page 1 -->
            <div class="carousel-item active">
              <div class="container tutorial-container">
                <p class="paragraph">
                  On the left side you see the LogIn Page. You can find this
                  page by clicking on the "Create new card deck" - Tab in the
                  Navigation Bar on top of the page. Since you first have to log
                  in to create a new card deck, you are asked to do so here:
                  <br />

                  On the right side you find the window that opens, for you to
                  log in. If you have an account you can simply log on to it, if
                  you don't, you can create a new account or reset your password
                  if you forgot it. (The 'create new account' and 'reset password' -
                  functionality has not been implemented yet)
                  <br />
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/EN_Create.png"
                      alt="Screenshot of the Deck Selection page"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/EN_LogIn.png"
                      alt="Screenshot of the pop-up window, showing edit, copy delete options"
                      class="small-image"
                      style="width: 300px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 2-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  On the left side you see the overview of the card decks
                  available. The information button on the top of the page gives
                  a little guidance on what to do here, and brings you back to
                  this tutorial if you want to learn more. <br />
                  There are three available options: 
                  You can create new card decks, change or delete existing ones 
                  (under the condition that you were the original creator). <br />
                  Clicking on a card brings you to a pop-up window where you 
                  can choose one of the before mentioned options 
                  (seen on the picture on the right hand side) <br />
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/EN_DeckSelection.png"
                      alt="Screenshot of the Deck Selection page"
                      class="image"
                      style="width: 400px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/edit_delete_deck_modal.png"
                      alt="Screenshot of the pop-up window, showing edit, copy delete options"
                      class="image"
                      style="width: 372px;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 3-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  
                  The creation and editing process consists of three parts, 
                  the first settings, where you specify general information of your deck. 
                  Then you create your instruction cards, and finally you can
                  start adding cards to your deck. <br />
                  After clicking 'Create New Card Deck' you start of by completing the first settings. 
                  Use the navigation menu on the left side, as seen in the picture, to navigate back and forth.
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/navigation_menu.png"
                      alt="Screenshot of the navigation menu"
                      class="navigation-menu-image"
                    />
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 4-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                   First, you will be forwarded to the page, where you can select a name for your card deck.<br />
                </p>

                <img
                  src="@/assets/tutorialScreenshots/CreateTutorial/EN_NameSettings.png"
                  alt="Screenshot of the Name Settings page"
                  class="image"
                  style="width: 500px;"
                />
                <br />
                <p class="paragraph">
                  Next you will be asked to enter some information about your
                  deck. How will these design cards help challenge its users? Be
                  creative!
                </p>
                <img
                  src="@/assets/tutorialScreenshots/CreateTutorial/EN_EnterInformation.png"
                  alt="Screenshot of the Deck Information Page"
                  class="image"
                  style="width: 500px;"
                />
              </div>
            </div>
            <!-- Carousel Page 5-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                 Then, you can decide if you want to split your deck in two. 
                 If you choose this option, you can later assign the created cards 
                 to two sperate decks within your deck. In the playing mode two cards 
                 will be drawn at the same time, one from each deck 
                 (e.g., from the TMAP Design Cards a Role card, and an Interaction, Impact or Motivation card)
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_split_setting.png"
                      alt="Screenshot of the Settings page for card deck splitting"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 6-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Next, add some categories in which you want to group your cards. 
                  By clicking on the green plus, a pop-up window opens, in which you 
                  can specify the name of your category, as well as an optional description. 
                  You can edit or delete a category simply by clicking on it.
                </p>

                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_categories_setting.png"
                      alt="Screenshot of the Settings page for card categories"
                      class="image"
                      style="width: 500px;"
                    />
                  </div>

                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/modal_category_edit.png"
                      alt="Screenshot of modal category edits"
                      class="small-image"
                      style="width: 300px; margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 7-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  In the last step of the first settings, you can choose a layout 
                  for your cards. The layout specifies the amount of text fields, 
                  which can be edited, as seen in the picture. 
                  You can later specify the texts of the ‘YOUR TEXT HERE’ text placeholders. 
                  Please note that the layout can not be changed once submitted.
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_layout_setting.png"
                      alt="Screenshot of the Settings page for the card layout"
                      class="image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 8-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                 Now your game needs some instructions. 
                 For each of the three categories write a short description
                 to help your users play. To update them, press each of the three buttons 
                 and fill out the description. The instructions will be placed on cards 
                 and added to the beginning of your deck. 
                 You can also pick a color for your instruction cards. 
                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/instructions_setting.png"
                      alt="Screenshot of the Settings page for instruction cards"
                      class="image"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 9-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Finally, you can add cards to your deck. 
                  By clicking on the green plus button, a pop-up window opens, 
                  as seen in the right picture. You can decide on text, 
                  which should be placed on the card’s available text positions. The picture on the right 
                  shows the pop-up window for the third layout. Seeing that the third layout has the
                  available positions top, middle, bottom and backside, you can enter a text 
                  for these positions.
                  As the split option has been chosen, you need to assign your card to either 
                  ‘Deck 1’ or ‘Deck 2’. Additionally, you can assign a category 
                  to your deck and pick a color. <br />

                  View your added cards directly, as seen in the left picture. 
                  You can delete them by clicking on the trash icon above. 
                  Edit a card by clicking directly on the card or the edit icon above. 
                  After you are finished, proceed by clicking on the 'Save' button. And Voilà - A card deck of your own!

                </p>
                <div style=" display: flex; justify-content:space-around;">
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_preview_setting.png"
                      alt="Screenshot of the card preview"
                      class="card-modal-image"
                      style="height: 380px"
                    />
                  </div>
                  <div>
                    <img
                      src="@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_adding_modal_layout3.png"
                      alt="Screenshot of the pop-up window for card adding"
                      class="card-modal-image"
                      style="margin-left: 5px"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Carousel Page 10-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  <br /><br />
                  <button
                    type="button"
                    class="btn btn-secondary btn-lg btn-block "
                    style="background: green;"
                    @click="goToCreate()"
                  >
                    Allright you finished this tutorial so lets start creating!
                  </button>
                  <br /><br />
                </p>
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Back
        </button>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HelpDesignCards",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
    };
  },
  methods: {
    goToCreate() {
      this.$router.push("/create");
    },
    goBack() {
      this.$router.push("/help");
    },
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    this.german = true;
  },
};
</script>

<style scoped lang="scss">
.about,
.carousel-button-color {
  color: black;
}
.tutorial-container {
  width: 80% !important;
}
.paragraph {
  text-align: left;
  font-size: 18px;
}
.blockquote {
  font-size: 18px;
  color: rgb(56, 0, 0);
}

h2 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 10px;
}
.header {
  position: relative;
  color: black;
  user-select: none;
}

.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}

main {
  background: rgb(252, 248, 241);
  padding: 10px;
  margin-top: 5% !important;
}

.col {
  padding: 10px 0;
  text-align: center;
}

.button-back {
  margin-bottom: 10px;
}

.button-continue {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button-pos {
  position: absolute;
  bottom: 0;
  width: 80%;
}

.image {
  width: 650px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.small-image {
  width: 350px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.navigation-menu-image {
  height: 250px;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card-modal-image {
  height: 400px;
  max-height: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
//https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators .active {
  background-color: rgba(0, 0, 0, 0.274);
}
</style>
