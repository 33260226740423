<!--
Author: Patrick Gmasz

This is the card which are shown as cover in the list of card decks.
-->
<template>
  <div class="listcard">
    <div class="card-top" :style="handleTop" />
    <div
      class="card-mid d-flex text-center justify-content-center align-items-center"
      :style="handleMid"
    >
      <p class="mr-3 mt-2 ml-3">{{ gameName }}</p>
    </div>
    <div class="card-bottom" :style="handleBottom" />
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "listcard",
  props: {
    gameName: {
      type: String,
      default: null,
    },
    cardColor: {
      type: String,
      default: "832015",
    },
  },
  methods: {
    adjustBrightness(col, amt) {
      //https://stackoverflow.com/a/37600815
      var R = parseInt(col.substring(0, 2), 16);
      var G = parseInt(col.substring(2, 4), 16);
      var B = parseInt(col.substring(4, 6), 16);

      R = R + amt;
      G = G + amt;
      B = B + amt;

      if (R > 255) R = 255;
      else if (R < 0) R = 0;

      if (G > 255) G = 255;
      else if (G < 0) G = 0;

      if (B > 255) B = 255;
      else if (B < 0) B = 0;

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return RR + GG + BB;
    },
    handleCardSize() {
      var width = document.getElementsByClassName("listcard")[0].offsetWidth;
      var card_mid = $(".card-mid");

      card_mid.css("font-size", width * 0.11);

      var card = $(".listcard");
      card.css("height", (width / 3) * 4);
    },
  },
  computed: {
    handleTop() {
      var topColor = this.adjustBrightness(this.cardColor, 60);
      return {
        "--top-color": "#" + topColor,
      };
    },
    handleMid() {
      //https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
      var r = parseInt(this.cardColor.substr(0, 2), 16);
      var g = parseInt(this.cardColor.substr(2, 2), 16);
      var b = parseInt(this.cardColor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      var font_color = yiq >= 120 ? "black" : "white"; //Auto font color sensitivity

      return {
        "--mid-color": "#" + this.cardColor,
        color: font_color,
      };
    },
    handleBottom() {
      var bottomColor = this.adjustBrightness(this.cardColor, 60);

      return {
        "--bottom-color": "#" + bottomColor,
      };
    },
  },
  mounted() {
    this.handleCardSize();
  },
  created() {
    window.addEventListener("resize", this.handleCardSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleCardSize);
  },
};
</script>

<style scoped lang="scss">
.listcard {
  max-width: 250px;
}

.listcard:hover {
  cursor: pointer;
}

.card-top {
  height: 20%;
  background: var(--top-color);
  user-select: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card-mid {
  height: 55%;
  background: var(--mid-color);
  font-weight: bold;

  white-space: pre-wrap;
  user-select: none;
  overflow: hidden;
}

.card-bottom {
  height: 25%;
  background: var(--bottom-color);
  user-select: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
