<template>
  <div class="HowToPlay">
    <navbar pos="Design Cards To Go" />
    <div class="container">
      <div class="d-flex justify-content-center mb-3 pl-4 pr-4">
        <div v-if="getCurrentLocale == 'de'">
          <div class="header">
            <div class="headline border-bottom border-dark mb-2">
              <h1>Anleitung</h1>
            </div>
            <p class="paragraph">
              Auf der Website kann mit verschiedenen Designkartendecks online
              spielen. Unter dem Tab "Play" in der Menüleiste sind alle
              verfügbaren Kartendecks sichtbar. Bei diesen kann man mit einem
              Klick folgende Funktionen ausführen:
            </p>
            <ul class="paragraph">
              <li>
                <b>Deckvorschau:</b> Hier kann man alle Karten ansehen und nach
                Kategorien filtern.
              </li>
              <li>
                <b>Spielen:</b> Hier kann man mit dem ausgewählten Deck spielen.
              </li>
            </ul>
            <p class="paragraph">
              Mit den Pfeilen (bzw. Pfeiltasten) kann man zwischen den Karten
              wechseln. Ein Klick auf die Karte (bzw. Leertaste) dreht sie um.
            </p>
            <div class="headline border-bottom border-dark mb-2">
              <h2>TMAP-Designkarten</h2>
            </div>
            <p class="paragraph">
              Die TMAP (technology-mediated audience participation)-Designkarten
              sind ein Tool um spielend <b>Ideen</b> zu entwickeln. Die Ideen
              dienen dazu, bei <b>Veranstaltungen mit Live-Musik</b> das
              <b>Publikum möglichst gut einzubinden</b>. Gesucht sind Ideen, die
              den Einsatz von <b>technischen Hilfsmitteln</b> erfordern.
              <a href="https://cs.univie.ac.at/oliver.hoedl">Oliver Hödl</a> hat
              diese Karten erfunden und erklärt sie in diesem
              <a href="https://www.youtube.com/watch?v=Foz_YU2A-MA">Video</a>.
              <br />
              Designkarten allgemein sind ein Kartenspiel, dass die SpielerInnen
              inspirieren soll. Dabei kann niemand verlieren oder gewinnen, denn
              eigentlich gewinnen alle, weil dadurch die Kreativität der
              TeilnehmerInnen gesteigert wird. Alle können dieses Spiel spielen,
              jedoch ist es wahrscheinlich am sinnvollsten für Leute, die ein
              Interesse daran haben, bei einer Veranstaltung mit Livemusik das
              Publikum gut in die Show einzubinden.
            </p>

            <div class="row">
              <img src="@/assets/lila.png" class="card" />
              <img src="@/assets/rot.png" class="card" />
              <img src="@/assets/grün.png" class="card" />
              <img src="@/assets/blau.png" class="card" />
              <img src="@/assets/gelb.png" class="card" />
            </div>

            <p class="paragraph">
              Das Deck ist unterteilt in eine lila Anleitungskarte, rote
              Rollenkarten, grüne Interaktionskarten, blaue Effektkarten und
              gelbe Motivationskarten. Jede Karte spricht dabei einen anderen
              Aspekt, der wichtig ist, um das Publikum in die Show zu
              einzubinden. Das Spiel kann alleine oder in einer Gruppe gespielt
              werden.
            </p>

            <h4 style="text-align: left">Vorbereitung</h4>
            <p class="paragraph">
              Um das Spiel zu spielen, sollten Sie an eine
              <b>Ausgangssituation</b>
              mit Live-Musik denken. Beispiele dafür könnten ein
              Orchesterauftritt bei einem Konzert, ein DJ-Auftritt bei einem
              Elektrofestival oder ein Chorauftritt in der Kirche sein.
              Überlegen Sie sich am besten wo und mit wie vielen Leuten die
              Veranstaltung stattfindet.
              <br />
              Sie sollten auch <b>Stifte und Papier</b> bereithalten, um Ihre
              Ideen notieren zu können.
            </p>
            <h4 style="text-align: left">Während dem Spiel</h4>
            <p class="paragraph">
              Am Beginn jeder Runde bekommen Sie <b>zwei Karten</b>. Eine der
              Karten ist eine <b>Rollenkarte (rot)</b>, die zweite Karte (grün,
              blau oder gelb) gibt Ihre <b>Aufgabe</b> an. Die Aufgaben sind
              absichtlich sehr offen formuliert, damit Sie Ihrer Kreativität
              freien Lauf lassen können. Lesen Sie immer zuerst die Vorderseite,
              auf der Rückseite stehen Hinweise, falls Sie diese benötigen.
            </p>
            <p class="paragraph">
              Versetzen Sie sich nun in Ihre Rolle (rote Karte) im Zusammenhang
              mit der Ausgangssituation und versuchen Sie Ideen passend zu Ihrer
              Aufgabe zu finden. Gesucht sind Ideen, die das Publikum mit Hilfe
              von technischen Hilfsmitteln gut einbinden. Lassen Sie sich etwas
              Zeit und notieren Sie Ihre Ideen.
            </p>
            <div class="row">
              <img src="@/assets/rolle.png" class="bigcard" />
              <img src="@/assets/aufgabe.png" class="bigcard" />
            </div>

            <p class="paragraph">Zu diesen Karten könnten Ideen sein:</p>
            <ul class="paragraph">
              <li>
                Als TeilnehmerIn könnte ich mich mit dem Smartphone per App
                beteiligen und über den nächsten Song abstimmen.
              </li>
              <li>
                Ich könnte durch klatschen meine Zustimmung für einen Vorschlag
                zeigen.
              </li>
            </ul>
            <p class="paragraph">
              <b>Weitere Runden:</b> Es können beliebig viele Runden gespielt
              werden, indem man neue Karten (mit dem Button unten) zieht. Die
              vorherigen Ideen (vielleicht auch die von anderen SpielerInnen)
              zur Ausgangssituation können so erweitert oder von einem anderen
              Blickwinkel betrachtet werden. Es können aber auch ganz neue Ideen
              entstehen. Wir empfehlen mindestens drei Runden zu spielen.
            </p>
            <h4 style="text-align: left">Erweiterungen & Hinweise</h4>
            <p class="paragraph">
              <b>Gruppenmodus:</b> Beim Spielen in einer Gruppe drücken alle
              SpielerInnen gleichzeitig auf "Spielen". Alle SpielerInnen
              bekommen unterschiedliche Karten und denken alleine darüber nach
              (wie oben beschrieben). Nach der Denkphase gibt es eine offene
              Diskussion, in der alle ihre Ideen vergleichen. Am besten stellen
              alle SpielerInnen ihre Ideen nach der Reihe vor.
              <br />
              Bei einer Gruppengröße von sechs oder mehr Personen empfehlen wir,
              kleinere Gruppen von drei bis vier Personen zu bilden.
            </p>

            <p class="paragraph">
              <b>Die Karten können nicht falsch verwendet werden!</b> Es wurde
              bewiesen, dass Tools wie diese hilfreich sind, um die
              Vorstellungskraft der BenutzerInnen zu verbessern. Lassen Sie
              Ihrer Kreativität also freien Lauf. Dennoch kann es sein, dass
              einzelne Karten Sie auf keine Ideen bringen. In diesem Fall können
              Sie einfach eine neue Karte ziehen. <br />
              Es kann auch sein, dass Ihre
              <b>Rolle nicht mit der Aufgabe zusammenpasst</b>. Das kann leider
              nicht verändert werden, da beide Karten zufällig gezogen werden
              und es mehr als 300 Kombinationsmöglichkeiten gibt. Am besten, Sie
              konzentrieren sich nur auf Ihre Rolle oder Ihre Aufgabe.
            </p>
          </div>
        </div>
        <div v-else>
          <div class="header">
            <div class="headline border-bottom border-dark mb-2">
              <h1>Instructions</h1>
            </div>
            <p class="paragraph">
              On the website you can play online with different design card
              decks. Under the "Play" tab in the menu bar all available card
              decks are shown. With these you can perform the following
              functions by clicking the deck:
            </p>
            <ul class="paragraph">
              <li>
                <b>Deck Preview:</b> Here you can view all cards of the deck and
                filter them by category.
              </li>
              <li><b>Play:</b> Here you can play with the selected deck.</li>
            </ul>
            <p class="paragraph">
              Use the arrows (or arrow keys) to switch between the cards.
              Clicking on the card (or space bar) turns it around.
            </p>
            <div class="headline border-bottom border-dark mb-2">
              <h2>TMAP-Design cards</h2>
            </div>
            <p class="paragraph">
              The TMAP (technology-mediated audience participation) design cards
              are a tool for developing <b>ideas</b> in a fun way. The ideas
              should involve the <b>audience</b> at
              <b>events with live music</b> and require the use of
              <b>technical aids</b>.
              <a href="https://cs.univie.ac.at/oliver.hoedl">Oliver Hödl</a> has
              invented these cards and explains them in this
              <a href="https://www.youtube.com/watch?v=Foz_YU2A-MA">video</a>.
              <br />
              Design cards in general are a card game that should inspire the
              players. No one can lose or win, actually everyone wins, because
              it increases the creativity of the participants. Everyone can play
              this game, however it probably makes the most sense for people who
              have an interest to engage the audience well in a show.
            </p>

            <div class="row">
              <img src="@/assets/lila.png" class="card" />
              <img src="@/assets/rot.png" class="card" />
              <img src="@/assets/grün.png" class="card" />
              <img src="@/assets/blau.png" class="card" />
              <img src="@/assets/gelb.png" class="card" />
            </div>

            <p class="paragraph">
              The deck is divided into a purple instruction card, red role
              cards, green interaction cards, blue impact cards and yellow
              motivation cards. Each card is about a different aspect that is
              important to engage the audience well in a show. The game can be
              played alone or in a group.
            </p>

            <h4 style="text-align: left">Preparation</h4>
            <p class="paragraph">
              To play the game, you should think of an
              <b>initial situation</b> with live music. Examples could be an
              orchestra performance at a concert, a DJ performance at an
              electric festival, or a choir performance in church. Also think
              about where and with how many people in the audience the show
              should take place.<br />
              You should also have <b>pen and paper</b> ready to write down your
              ideas.
            </p>
            <h4 style="text-align: left">While playing</h4>
            <p class="paragraph">
              At the beginning of each round you get <b>two cards</b>. The first
              card is a <b>role card (red)</b>, the second card (green, blue or
              yellow) indicates your <b>task</b>. The tasks are intentionally
              formulated very openly so that nothing limits your creativity.
              Always read the front first, there are notes on the back if you
              need them.
            </p>
            <p class="paragraph">
              Put yourself in your role (red card) minding the context of the
              initial situation and try to find ideas suitable for your task.
              Look for ideas that engage the audience well using technical
              tools. Take some time and write down your ideas.
            </p>
            <div class="row">
              <img src="@/assets/role.png" class="bigcard" />
              <img src="@/assets/task.png" class="bigcard" />
            </div>

            <p class="paragraph">Ideas for these cards could be:</p>
            <ul class="paragraph">
              <li>
                As a participant, I could use my smartphone to take part via app
                and vote on the next song.
              </li>
              <li>I could show my approval to a proposal by clapping.</li>
            </ul>
            <p class="paragraph">
              <b>Further rounds:</b> Any number of rounds can be played by
              drawing new cards (using the button at the bottom). Previous ideas
              (perhaps those of other players) for the initial situation can
              thus be expanded or viewed from a different angle. However,
              completely new ideas can also emerge. We recommend playing at
              least three rounds.
            </p>
            <h4 style="text-align: left">Extensions & Notes</h4>
            <p class="paragraph">
              <b>Group mode:</b> When playing in a group, all players press
              "Play" at the same time. Everyone has different cards and thinks
              about them independently (as described above). After the thinking
              phase, there is an open discussion in which everyone compares
              their ideas. It is best if all players present their ideas in
              turn.
              <br />
              If the group size is six or more, we recommend forming smaller
              groups of three to four people.
            </p>

            <p class="paragraph">
              <b>The cards can't be used incorrectly!</b> It has been proven
              that tools like these are helpful in improving the imagination of
              users. So let your creativity run wild. Still, individual cards
              may not give you any ideas. In this case, you can simply draw a
              new card. <br />
              It can happen, that your
              <b>role card does not make sense in combination to your task.</b>
              Unfortunately, this cannot be changed since both cards are drawn
              randomly and there are more than 300 different combination
              possibilities. Just concentrate on your role card or your second
              card in this case.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HowToPlay",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
    };
  },
  methods: {
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    this.german = true;
  },
};
</script>

<style scoped lang="scss">
.about,
.header {
  position: relative;
  color: black;
  user-select: none;
  padding: 30px;
}
.card {
  width: 100px;
  max-width: 100%;
  height: auto;
  margin: 10px;
  background: transparent;
  display: block;
  align-content: center;
}
.bigcard {
  width: 330px;
  max-width: 100%;
  height: auto;
  margin: 10px;
  background: transparent;
  display: block;
  align-content: center;
}
.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}
.paragraph {
  text-align: left;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  content: "";
  z-index: -1;
  background: rgb(252, 239, 225);
}
</style>
