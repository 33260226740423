import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state: {
    currentCard: 1,
    allCards: 2,
    turnAround: false,
    selectedGame: Object,
    cardDeck: "",
    reload: false,
    categories: Array,
    filter: "All",
    selectedParentCardL1: Object,
    selectedParentCardL2: Object,
    selectedParentCardL3: Object,
    createNewGame: false,
    editGame: false,
    L1Nodes: [],
    L2Nodes: [],
    L3Nodes: [],
    L4Nodes: [],
    instructionCards: [],
    newGame: Object,
    highestChangedLayer: "L4",
    L1FirstVisit: true,
    instructionsFirstVisit: true,
    tempID: -1,
    gameNames: [],
    loggedIn: false,
    currentLocale: "en",
    frameworkCategories: [],
    deckSplit: "",
    firstSettingsSubmitted: false,
  },
  mutations: {
    updateLoggedIn(state, value) {
      if (!value) { //if the user logs out, the storage should be cleared
        sessionStorage.clear();
      }
      this.resetAllStates;
      state.loggedIn = value;
    },
    updateCurrentLocale(state, value) {
      state.currentLocale = value;
    },
    incrementTempID(state) {
      --state.tempID;
    },
    updateCurrentCard(state, value) {
      state.currentCard = value;
    },
    incrementCurrentCard(state) {
      if (state.currentCard + 1 > state.allCards) {
        state.currentCard = 1;
      } else {
        state.currentCard++;
      }
    },
    decrementCurrentCard(state) {
      if (state.currentCard - 1 < 1) {
        state.currentCard = state.allCards;
      } else {
        state.currentCard--;
      }
    },
    updateAllCards(state, value) {
      state.allCards = value;
    },
    updateTurnAround(state) {
      state.turnAround = !state.turnAround;
    },
    updateSelectedGame(state, value) {
      state.selectedGame = value;
    },
    updateCardDeck(state, value) {
      state.cardDeck = value;
    },
    updateReload(state) {
      state.reload = !state.reload;
    },
    updateCategories(state, value) {
      state.categories = value;
    },
    updateFilter(state, value) {
      state.filter = value;
    },
    updateSelectedParentCardL1(state, value) {
      state.selectedParentCardL1 = value;
    },
    updateSelectedParentCardL2(state, value) {
      state.selectedParentCardL2 = value;
    },
    updateSelectedParentCardL3(state, value) {
      state.selectedParentCardL3 = value;
    },
    updateCreateNewGame(state, value) {
      state.createNewGame = value;
    },
    updateEditGame(state, value) {
      state.editGame = value;
    },
    updateL1Nodes(state, value) {
      state.L1Nodes = [...value];
    },
    updateL2Nodes(state, value) {
      state.L2Nodes = [...value];
    },
    updateL3Nodes(state, value) {
      state.L3Nodes = [...value];
    },
    updateL4Nodes(state, value) {
      state.L4Nodes = [...value];
    },
    updateInstructionCards(state, value) {
      state.instructionCards = value;
    },
    updateNewGame(state, value) {
      state.newGame = value;
    },
    updateHighestChangedLayer(state, value) {
      state.highestChangedLayer = value;
    },
    updateL1FirstVisit(state, value) {
      state.L1FirstVisit = value;
    },
    updateInstructionsFirstVisit(state, value) {
      state.instructionsFirstVisit = value;
    },
    resetAllStates(state) {
      state.selectedParentCardL1 = Object;
      state.selectedParentCardL2 = Object;
      state.selectedParentCardL3 = Object;
      state.createNewGame = false;
      state.editGame = false;
      state.L1Nodes = [];
      state.L2Nodes = [];
      state.L3Nodes = [];
      state.L4Nodes = [];
      state.instructionCards = [];
      state.newGame = Object;
      state.highestChangedLayer = "L4";
      state.L1FirstVisit = true;
      state.selectedGame = Object;
      state.tempID = -1;
      state.instructionsFirstVisit = true;
      state.currentLocale = "en";
      state.frameworkCategories = [];
      state.deckSplit = "";
      state.firstSettingsSubmitted = false; //variable to help with navigation through card creation
    },
    updateGameNames(state, value) {
      state.gameNames = value;
    },
    updateDeckSplit(state, value) {
      state.deckSplit = value;
    },
    updateGenerationLevel(state, value) {
      state.selectedGame.generationLevel = value;
    },
    updateGameName(state, value) {
      state.selectedGame.gameName = value;
    },
    updateDescription(state, value) {
      state.selectedGame.description = value;
    },
    updateFirstSettingsSubmitted(state, value) {
      state.firstSettingsSubmitted = value;
    },
    updateFrameworkCategories(state, value) {
      state.frameworkCategories = value;
    },
  },
  modules: {},
  getters: {
    getLoggedIn: (state) => state.loggedIn,
    getCurrentLocale: (state) => state.currentLocale,
    getCurrentCard: (state) => state.currentCard,
    getAllCards: (state) => state.allCards,
    getTurnAround: (state) => state.turnAround,
    getSelectedGame: (state) => state.selectedGame,
    getCardDeck: (state) => state.cardDeck,
    getReload: (state) => state.reload,
    getCategories: (state) => state.categories,
    getFilter: (state) => state.filter,
    getSelectedParentCardL1: (state) => state.selectedParentCardL1,
    getSelectedParentCardL2: (state) => state.selectedParentCardL2,
    getSelectedParentCardL3: (state) => state.selectedParentCardL3,
    getCreateNewGame: (state) => state.createNewGame,
    getEditGame: (state) => state.editGame,
    getNewGame: (state) => state.newGame,
    getL1Nodes: (state) => state.L1Nodes,
    getInstructionCards: (state) => state.instructionCards,
    getL2Nodes: (state) => state.L2Nodes,
    getL3Nodes: (state) => state.L3Nodes,
    getL4Nodes: (state) => state.L4Nodes,
    getHighestChangedLayer: (state) => state.highestChangedLayer,
    getL1FirstVisit: (state) => state.L1FirstVisit,
    getInstructionsFirstVisit: (state) => state.instructionsFirstVisit,
    getTempID: (state) => state.tempID,
    getGameNames: (state) => state.gameNames,
    getFrameworkCategories: (state) => state.frameworkCategories,
    isDeckSplit: (state) => state.deckSplit,
    getFirstSettingsSubmitted: (state) => state.firstSettingsSubmitted,
  },
});
