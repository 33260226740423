<template>
  <div class="CardCategoriesSetting">
    <navbar pos="Design Cards To Go" />
    <div class="row">
        <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
      <div class="container">
        <div class="row justify-content-center mb-3 pl-4 pr-4">
        <div class="header col-12 col-md-12 pt-3">
          <div>
            <div class="headline border-bottom border-dark mb-3 ">
              <h1> {{ texts.headerText[getCurrentLocale] }} </h1>
            </div>
            <p>
              {{ texts.descriptionText[getCurrentLocale] }}
            </p>
            <div v-if="categories.length>0"
              style="background-color: white; border-radius: 3px; padding: 1rem;"
            >
              
                <div
                v-for="category in categoriesToDisplay"
                :key="category.id"
                style="text-align: left; padding: 0.5rem 0;"
                class="option-item"
                @click="initalizeSelectedCategory(category)"
              >
                <button
                  class="card-list btn btn-lg btn-block"
                  data-toggle="modal"
                  data-target="#modalAdd"
                  @click="initalizeSelectedCategory(category)"
                >
                  <div>
                    <i class="far fa-edit"></i>
                  </div>
                  <p style="margin: 0 0 0 0.5rem; padding: 0;">
                    {{ category.name }}
                  </p>
                </button>
              </div>
            </div>
            <div style="margin: 5px">
                <button
                  type="button"
                  class="btn btn-lg add-button"
                  data-toggle="modal"
                  data-target="#modalAdd"
                  @click="resetSelectedCategory"
                >
                  <i class="fas fa-plus"></i>
                </button>
            </div>
            <button 
                type="button" 
                class="btn btn-lg secondary-action-btn" 
                @click="goBack"
                >
                {{ texts.goBackButtonText[getCurrentLocale] }}
            </button>
            <button
              type="next"
              class="btn btn-lg primary-action-btn"
              @click="goForward"
            >
              {{ texts.goForwardButtonText[getCurrentLocale] }}
            </button>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    
    <!-- Modals in English-->
    <div v-if="getCurrentLocale == 'en'"
      class="modal fade"
      id="modalAdd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">
              
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <!-- <span aria-hidden="true">&times;</span> -->
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="nameInput">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  aria-describedby="nameHelp"
                  placeholder="Enter a name"
                  v-model="selectedCategory.name"
                />
              </div> 
              <p v-if="error.value" style="color: red">{{ error.text[getCurrentLocale] }}</p>
              <div class="form-group">
                <label for="descriptionInput">Description:</label>
                <div class="textBox">
                  <textarea
                    class="form-control"
                    id="descriptionInput"
                    rows="3"
                    placeholder="Enter a description (optional)"
                    v-model="selectedCategory.description"
                  ></textarea>
                </div>
              </div>
            </form>
            <div class="d-flex">
                <div class="mr-auto p-2">
                    <template v-if="!addModus">
                        <button
                            type="button"
                            class="btn btn-lg delete-btn"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#modalDeleteCategory"
                        >
                            <i class="fas fa-solid fa-trash"></i> Delete
                        </button>
                    </template>
                </div>
                <div class="p-2">
                    <button
                        type="button"
                        class="btn btn-lg secondary-action-btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="closeModal"
                        >
                        Cancel
                        </button>
                        
                </div> 
                <div class="p-2">
                    <button
                        type="submit"
                        class="btn btn-lg primary-action-btn"
                        @click="saveChanges"
                        >
                        Save
                    </button>
                </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal of deleting a category-->
    <div v-if="getCurrentLocale == 'en'">
      <div 
        class="modal fade"
        id="modalDeleteCategory"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <i class="far fa-times-circle fa-2x"></i>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="text-center">
                Please note, that cards of this category will be deleted as well. Do you really want to delete the selected category?
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4" data-dismiss="modal" aria-label="Close">Cancel</button>
              <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4 delete-button" data-dismiss="modal" @click="deleteCategory">DELETE</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals in German -->
    <div v-if="getCurrentLocale == 'de'"
      class="modal fade"
      id="modalAdd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">
              
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <!-- <span aria-hidden="true">&times;</span> -->
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="nameInput">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  aria-describedby="nameHelp"
                  placeholder="Gib einen Namen ein"
                  v-model="selectedCategory.name"
                />
              </div> 
              <p v-if="error.value" style="color: red">{{ error.text[getCurrentLocale] }}</p>
              <div class="form-group">
                <label for="descriptionInput">Beschreibung:</label>
                <div class="textBox">
                  <textarea
                    class="form-control"
                    id="descriptionInput"
                    rows="3"
                    placeholder="Füge eine Beschreibung hinzu (optional)"
                    v-model="selectedCategory.description"
                  ></textarea>
                </div>
              </div>
            </form>
            <div class="d-flex">
                <div class="mr-auto p-2">
                    <template v-if="!addModus">
                        <button
                            type="button"
                            class="btn btn-lg delete-btn"
                            data-dismiss="modal"
                            data-toggle="modal"
                            data-target="#modalDeleteCategory"
                        >
                            <i class="fas fa-solid fa-trash"></i> Löschen
                        </button>
                    </template>
                </div>
                <div class="p-2">
                    <button
                        type="button"
                        class="btn btn-lg secondary-action-btn"
                        data-dismiss="modal"
                        aria-label="Close"
                        id="closeModal"
                        >
                        Abbrechen
                        </button>
                        
                </div> 
                <div class="p-2">
                    <button
                        type="submit"
                        class="btn btn-lg primary-action-btn"
                        @click="saveChanges"
                        >
                        Speichern
                    </button>
                </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal of deleting a category -->
    <div v-if="getCurrentLocale == 'de'">
      <div 
        class="modal fade"
        id="modalDeleteCategory"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <i class="far fa-times-circle fa-2x"></i>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p class="text-center">
                Bitte beachte, dass Karten mit dieser Kategorie auch gelöscht werden. Willst du diese Kategorie wirklich löschen?
              </p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4" data-dismiss="modal" aria-label="Close">Abbrechen</button>
              <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4 delete-button" data-dismiss="modal" @click="deleteCategory">LÖSCHEN</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "../components/TabCreateCards.vue";
import $ from "jquery";
export default {
  name: "CardCategoriesSetting",
  components: {
    Navbar,
    TabCreateCards,
  },
  data() {
    return {
      error: {
        value: false,
        text: "",
      },
      texts: {
        headerText: {
          en: "Add Your Card Categories",
          de: "Füge deine Kartenkategorien hinzu"
        },
        descriptionText: {
          en: "You can add new categories and edit them by clicking on them:",
          de: "Du kannst neue Kategorien hinzufügen oder sie bearbeiten indem du sie anklickst:"
        },
        goBackButtonText: {
          en: "Back",
          de: "Zurück"
        },
        goForwardButtonText: {
          en: "Next",
          de: "Weiter"
        },
      },
      selectedCategory: {id: "", name: "", description: ""},
      categoriesToDisplay: [],//does not include categories which have the modification status 'DELETE'
      addModus: true,
    };
  },
  computed: {
    categories: {
        get() {
            return this.$store.getters.getFrameworkCategories;
        },
        set(newCategories) {
            this.$store.commit("updateFrameworkCategories", newCategories);
        }
    },
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    }
  },
  mounted() {
    var allCategories = this.$store.getters.getFrameworkCategories;
    for (var category of allCategories) {
      if (Object.prototype.hasOwnProperty.call(category, "modification")) {
        if (category.modification !== "DELETED") {
          this.categoriesToDisplay.push(category);
        }
      } else {
        this.categoriesToDisplay.push(category);
      }
    }
  },
  methods: {
    openModalAdd() {
      this.error.value = false;
      $('#modalAddCard').modal('show')
    },
    initalizeSelectedCategory(category) {
        //initialize values of modal with a copy to prevent auto save without clicking on saving button
        this.error.value = false;
        this.selectedCategory = Object.assign({}, this.categories.find(element => element.name === category.name));
        this.addModus = false;
    },
    saveChanges() {
       if (!this.checkCategory(this.selectedCategory)) {
        console.log("incorrect category name");
        return;
       }

      if (this.addModus) { //category is newly added
          this.selectedCategory.id = this.$store.getters.getTempID;
          this.$store.commit("incrementTempID");
          this.$set(this.selectedCategory, 'modification', 'ADDED');
          this.categories.push(this.selectedCategory);
          this.categoriesToDisplay.push(this.selectedCategory);
      } else {
        let category = this.categories.find(element => element.id === this.selectedCategory.id);
        category.name = this.selectedCategory.name;
        category.description = this.selectedCategory.description;
        if (this.selectedCategory.id >= 0) {//category does not only has a temporary id, which means it was not newly added
          this.$set(category, 'modification', 'UPDATED');
        }
      }
      this.$store.commit("updateFrameworkCategories", this.categories);
      $('#modalAdd').modal('hide');
    },
    /**
     * checks if category with this name already exists and is not empty
     */
    checkCategory(category) {
      if (this.categoriesToDisplay.findIndex(element => {
          return element.name == category.name;
        }) !== -1) {
        this.error.value = true;
        this.error.text = {
          en: "This name already exists. Please choose a unique one.",
          de: "Dieser Name existiert bereits. Bitte wähle einen Einzigartigen aus."
        }
        return false;
      }
      if (category.name === '' || category.name.trim().length == 0 ) {
        this.error.value = true;
        this.error.text = {
          en: "Please enter a name.",
          de: "Bitte wähle einen Namen."
        }
        return false;
      }
      return true;
    },
    resetSelectedCategory() {
        $('#modalAdd').on('hidden.bs.modal', function () {
            $(this).find('form').trigger('reset');
        });
        this.selectedCategory = {id: "", name: "", description: ""};
        this.addModus = true;
        this.error.value = false;
    },
    deleteCategory() {     
      //remove the category out of the displayed categories
      var indexOfCategory = this.categories.findIndex(element => {
        return element.id === this.selectedCategory.id;
      });
      this.categoriesToDisplay.splice(indexOfCategory, 1);
      if (this.selectedCategory.id < 0) { //category has not yet been saved to database and will be deleted permanently
        this.categories.splice(indexOfCategory, 1);
      } else {
        //remove temporarely by flagging category as 'DELETED'
        let category = this.categories.find(element => element.id === this.selectedCategory.id);
        this.$set(category, 'modification', 'DELETED');
      }
      $('#modalAdd').modal('hide');
    },
    goForward() {
        this.$router.push("cardGenerationLevel");
    },
    goBack() {
        this.$router.push("deckSplitSetting");
    }
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to create
    next((vm) => {
      if (!vm.getLoggedIn && !(vm.getCreateNewGame|| vm.getEditGame)) {
          console.log("Accessed view all page without initializing a game.");
          vm.$router.push("/create");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
.option-item {
  &:hover {
    cursor: pointer;
  }
}

.active {
  background-color: rgb(217, 217, 255);
}

#header {
  margin-top: 20px;
}
.form-control {
  height: 200%;
}
.btn {
  margin-top: 1%;
  margin: 1%;
}
.delete-button {
  background: rgb(150, 1, 1) !important;
  border: 0.2ch;
}

.delete-button:hover {
  background: rgb(211, 15, 15) !important;
  border: 0.2ch;
}

.fa-times-circle {
  color: rgb(150, 1, 1);
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}
.add-button {
  background: rgba(0, 192, 58, 0.9) !important;
  border: 0.5ch;
  margin: 10px;
  padding: 15px;
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.delete-btn {
    color: white;
    background: rgb(150, 1, 1);
    margin-top: 1%;
    border: transparent;
    margin: 0% !important;
    margin-right: 0.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
}   

.delete-btn:hover {
    color: white;
    background: rgb(211, 15, 15);
    margin: 0% !important;
    margin-right: 0.5%;
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
}

.add-button:hover {
  background: rgb(2, 216, 66) !important;
}

.card-list {
  display: inline-flex !important;
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
    background: rgb(53, 52, 52);
    color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}
.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}
</style>