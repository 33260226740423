import { render, staticRenderFns } from "./Bottombar.vue?vue&type=template&id=80a8831c&scoped=true&"
import script from "./Bottombar.vue?vue&type=script&lang=js&"
export * from "./Bottombar.vue?vue&type=script&lang=js&"
import style0 from "./Bottombar.vue?vue&type=style&index=0&id=80a8831c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80a8831c",
  null
  
)

export default component.exports