<template>
<div>
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
            <router-link class="nav-link" to="nameSettings">Name</router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" to="enterInformation">Description</router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" to="deckSplitSetting">Deck Split</router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" to="cardCategoriesSetting">Card Categories</router-link>
        </li>
        <li class="nav-item">
            <router-link class="nav-link" to="cardGenerationLevel">Card Layout</router-link>
        </li>
    </ul>
</div>
    
</template>

<script>
export default {
    name: "TabCreateCards",
}
</script>

<style scoped lang="scss">
    .nav-tabs {
        background-color: rgba(252, 248, 241, 0.95);
        margin-left: 5%;
    }
    .nav-link {
        color: rgb(0, 0, 0);
        text-align: left;
    }
    
    .nav-item {
        width: 100%;
    }

    .nav-link:hover {
        background: #e5e1e1;
    }
    
    .card-setting-active-link{
        background-color: #d1cece;
        font-weight: bolder;
    }

    .card-setting-active-link:hover{
        background-color: #d1cece;
        font-weight: bolder;
    }

</style>