<!-- Help Page showing Design Card Tutorial -->
<template>
  <div class="container">
    <navbar pos="Design Cards To Go" />

    <main
      class="d-flex justify-content-center mb-3 pl-4 pr-4 ml-1 mr-1"
      style="padding-top: 10px;"
    >
      <!-- Tutorial in German -->
      <div v-if="getCurrentLocale == 'de'">
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">Spielanleitung</h1>
        </header>
        <!-- Carousel Tutorial German-->
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style="width: 100%; padding: 10px;"
          data-interval="0"
        >
          <ol
            class="carousel-indicators"
            style=" margin-bottom:1%; margin-top:99%;"
          >
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner" style="margin-bottom: 20px;">
            <div class="carousel-item active">
              <div class="container tutorial-container">
                <!-- Carousel Page 1 -->
                <p class="paragraph">
                  Auf der "Design Cards To Go"- Website kann mit
                  unterschiedlichen Designkartendecks gespielt werden. Unter dem
                  Tab "Play" in der Menüleiste sind alle verfügbaren
                  TMAP-Kartendecks sichtbar. <br />
                </p>

                <img
                  src="@/assets/tutorialScreenshots/PlayList.png"
                  alt="Screenshot der Play Page"
                  class="image"
                />
                <br />
                <p class="paragraph">
                  Mit klick auf das gewünschte Kartendeck wird es ausgewählt.
                </p>
              </div>
            </div>
            <!-- Carousel Page 2-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Nach der Auswahl eines der Kartendecks, kann man aus folgenden
                  Optionen wählen:
                  <br />
                </p>

                <img
                  src="@/assets/tutorialScreenshots/PlayModalGer.png"
                  alt="Screenshot des Pop-Up Fensters, "
                  class="image"
                />

                <ul class="paragraph" style="font-size: 15px;">
                  <li>
                    <b>Deckvorschau:</b> <br />In der Deckvorschau kann man
                    entweder alle Karten oder die Karten nach Kategorien
                    gefiltert ansehen. Für das Filtern gibt es einen Button, der
                    im linken unteren Eck zu finden ist.
                  </li>
                  <li>
                    <b>Spielen:</b> <br />Bei Klick auf Spielen kommt man zum
                    zuvor gewählten Kartendeck.<br /><br />
                  </li>
                </ul>
              </div>
            </div>
            <!-- Carousel Page 3-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  Mit den Pfeiltasten kann man zwischen den beiden Spielkarten
                  wechseln. Ein Klick auf den Button "Karte Umdrehen" im rechten
                  unteren Eck, einem Klick auf die Karte selbst oder das Drücken
                  der Leertaste dreht diese um. Im Eck links unten kann man neue
                  Karten ziehen, welche die aktuellen Karten ersetzen.
                </p>

                <img
                  src="@/assets/tutorialScreenshots/DeckGer.png"
                  alt="Screenshot der Spielkarte im Spiel"
                  class="image"
                  style="width: 650px;"
                />
              </div>
            </div>
            <!-- Carousel Page 4-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  <br /><br />
                  <button
                    type="button"
                    class="btn btn-secondary btn-lg btn-block "
                    style="background: green;"
                    @click="goToPlay()"
                  >
                    Los Gehts und viel Spaß beim Spielen mit den TMAP
                    Designkarten!
                  </button>
                  <br /><br />
                </p>
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <!-- Button back to Help-->
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Zurück Zu Hilfe
        </button>
      </div>
      <!-- Tutorial in English -->
      <div v-else>
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">
            How To Play
          </h1>
        </header>

        <!-- Carousel Tutorial English-->
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style="width: 100%; padding: 10px;"
          data-interval="0"
        >
          <ol
            class="carousel-indicators"
            style=" margin-bottom:1%; margin-top:99%;"
          >
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner" style="margin-bottom: 20px;">
            <div class="carousel-item active">
              <div class="container tutorial-container">
                <!-- Carousel Page 1 -->
                <p class="paragraph">
                  You can play with different decks of Design Cards on the
                  "Design Cards To Go"- Website. Pressing "Play" inside the Menu
                  bar shows all the available ones you can use. <br />
                </p>

                <img
                  src="@/assets/tutorialScreenshots/PlayList.png"
                  alt="Screenshot der Play Page"
                  class="image"
                />
                <br />
                <p class="paragraph">
                  Clicking on the card deck of your choice will pick it out for
                  you.
                </p>
              </div>
            </div>
            <!-- Carousel Page 2-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  After picking out the card deck, you have two choices to
                  continue. Deck Preview and Play :
                  <br />
                </p>

                <img
                  src="@/assets/tutorialScreenshots/PlayModalEng.png"
                  alt="Screenshot des Pop-Up Fensters, "
                  class="image"
                />

                <ul class="paragraph" style="font-size: 15px;">
                  <li>
                    <b>Deck Preview:</b> <br />In Deck Preview you can look at
                    all the cards in the deck or filter them by category. To
                    filter the cards you will find a button on the bottom left
                    corner of the webpage.
                  </li>
                  <li>
                    <b>Play:</b> <br />Pressing Play will bring you to the
                    actual Game.<br /><br />
                  </li>
                </ul>
              </div>
            </div>
            <!-- Carousel Page 3-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  To the left and right of the card you will see two arrow
                  buttons, they can be used to switch back and forth between
                  your two cards. To turn the current card around you can either
                  click on the button "Flip Card", click the card itself or use
                  the space bar on your keyboard. In the bottom left corner of
                  the webpage you find another button "Get new cards" which
                  switches out the two cards you currently have for new ones.
                </p>

                <img
                  src="@/assets/tutorialScreenshots/DeckEng.png"
                  alt="Screenshot der Spielkarte im Spiel"
                  class="image"
                  style="width: 650px;"
                />
              </div>
            </div>
            <!-- Carousel Page 4-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <p class="paragraph">
                  <br /><br />
                  <button
                    type="button"
                    class="btn btn-secondary btn-lg btn-block "
                    style="background: green;"
                    @click="goToPlay()"
                  >
                    Allright you finished this tutorial so lets start playing!
                  </button>
                  <br /><br />
                </p>
              </div>
            </div>

            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon carousel-button-color"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>

        <!-- Button back to Help -->
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Back To Help
        </button>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HelpDesignCards",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/help");
    },
    goToPlay() {
      this.$router.push("/playlist");
    },
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    this.german = true;
  },
};
</script>

<style scoped lang="scss">
.about,
.carousel-button-color {
  color: black;
}
.tutorial-container {
  width: 80% !important;
}
.paragraph {
  text-align: left;
  font-size: 18px;
}
.blockquote {
  font-size: 18px;
  color: rgb(56, 0, 0);
}

h2 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 10px;
}
.header {
  position: relative;
  color: black;
  user-select: none;
}

.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}

main {
  background: rgb(252, 248, 241);
  padding: 10px;
  margin-top: 5% !important;
}

.col {
  padding: 10px 0;
  text-align: center;
}

.button-back {
  margin-bottom: 10px;
}

.button-continue {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button-pos {
  position: absolute;
  bottom: 0;
  width: 80%;
}

.image {
  width: 400px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
//https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators .active {
  background-color: rgba(0, 0, 0, 0.274);
}
</style>
