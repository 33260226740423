<!--
Author: Patrick Gmasz

This is the modal which opens after clicking on a card deck cover in list of decks.
-->
<template>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-weight-bold">{{ game.gameName }}</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="text-center">
          {{ game.description }}
        </p>
        <br />
        <p style="color: red; font-weight: bold" class="text-center">
          {{ this.message }}
        </p>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-lg grey btn-block border-bottom mb-4"
          v-on:click="goToHelp"
        >
          {{ HelpText[getCurrentLocale] }}
        </button>
        <button
          class="btn btn-lg grey btn-block border-bottom mb-4"
          v-on:click="previewGame"
        >
          {{ DeckPreviewText[getCurrentLocale] }}
        </button>
        <button
          class="btn btn-lg grey btn-block border-bottom mbt-4"
          v-on:click="useGame"
        >
          {{ PlayText[getCurrentLocale] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
  name: "list-modal",
  props: {
    game: Object
  },
  data() {
    return {
      missingCards: "",
      message: "",
      HelpText: {
        en: "Help",
        de: "Hilfe"
      },
      DeckPreviewText: {
        en: "Deck Preview",
        de: "Deckvorschau"
      },
      PlayText: {
        en: "Play",
        de: "Spielen"
      }
    };
  },
  methods: {
    goToHelp() {
      $(".close").click();
      this.$router.push("/help");
    },
    useGame() {
      var self = this;
      this.$store.commit("updateSelectedGame", this.game); //save selected game in store

      var gameID = this.game.id;
      const apiUrl = API_URL; // eslint-disable-line
      axios //get card deck from database;
        .post(apiUrl, {
          request: "getCards",
          game: gameID
        })
        .then(function(response) {
          if (!self.checkCardDeck(response.data)) {
            if (self.missingCards) {
              self.message = "No cards found in this deck.";
            } else {
              self.message =
                "There are some erros within the deck. Please try again later.";
            }
          } else {
            //initialize store
            self.$store.commit("updateCardDeck", response.data);
            if (response.data[1].length == 0) {
              self.$store.commit("updateAllCards", 1);
            } else {
              self.$store.commit("updateAllCards", 2);
            }
            self.$store.commit("updateCurrentCard", 1);
            if (self.$store.getters.getHelp) {
              self.$store.commit("updateHelp");
            }
            $(".close").click();
            self.$router.push("/play");
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    previewGame() {
      var self = this;
      this.$store.commit("updateSelectedGame", this.game); //save selected game in store

      var gameID = this.game.id;
      const apiUrl = API_URL; // eslint-disable-line

      axios //get card deck from database;
        .post(apiUrl, {
          request: "getCards",
          game: gameID
        })
        .then(function(response) {
          if (!self.checkCardDeck(response.data)) {
            self.message =
              "There are some erros within the deck. Please try again later.";
          } else {
            //initialize store
            self.$store.commit("updateCardDeck", response.data);
            if (response.data[1].length == 0) {
              self.$store.commit(
                "updateAllCards",
                response.data[0].length + response.data[2].length
              );
            } else {
              self.$store.commit(
                "updateAllCards",
                response.data[0].length +
                response.data[1].length +
                response.data[2].length
              );
            }
            self.$store.commit("updateCurrentCard", 1);
            if (self.$store.getters.getHelp) {
              self.$store.commit("updateHelp");
            }
            $(".close").click();
            self.$router.push("/viewall");
          }
        })
        .catch(function(err) {
          console.log(err);
        });

      axios //get categories
        .post(apiUrl, {
          request: "categories",
          game: gameID
        })
        .then(function(response) {
          var categories = new Array();
          for (var i = 0; i < response.data.length; i++) {
            categories.push(response.data[i].title);
          }
          self.$store.commit("updateCategories", categories);
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    checkCardDeck(deck) {
      if (deck == "ERROR") {
        //CHECK IF API RETURNED ERROR
        console.log(
          "Got error as card deck. There is something faulty with the deck."
        );
        return false;
      }
      if (deck[2].length == 0) {
        // CHECK IF THERE ARE INSTRUCTION CARDS
        console.log("No instruction cards in deck.");
        this.missingCards = true;
        return false;
      }
      if (deck[0].length == 0) {
        // CHECK IF THERE ARE CARDS AT DECK [0], WHICH IS A MUST
        console.log("No playcards in first deck.");
        this.missingCards = true;
        return false;
      }
      if (deck[0].length == 0 && deck[1].length == 0) {
        // CHECK IF THERE ARE PLAY CARDS IN THE DECK
        console.log("No playcards in deck.");
        this.missingCards = true;
        return false;
      }
      var level = "";
      switch (this.game.generationLevel) {
        case "L1":
          level = 1;
          break;
        case "L2":
          level = 2;
          break;
        case "L3":
          level = 3;
          break;
        case "L4":
          level = 4;
          break;
        default:
          console.log("Generation level: " + this.game.generationLevel);
          return false;
      }
      for (var i = 0; i < deck[0].length - 1; i++) {
        if (deck[0][i].length < level) {
          //CHECK IF THERE IS A CARD WITH LESS LEVELS THAN GENERATION LEVEL
          console.log(
            "Generation level is: L" +
            level +
            ", but got a card with " +
            deck[0][i].length +
            "layers."
          );
          return false;
        }

        if (deck[0][i].length != deck[0][i + 1].length) {
          //CHECK IF THERE ARE CARDS WITH DIFFERENT NODE SIZES
          console.log("Cards have inconsistent layers");
          return false;
        }
      }

      for (i = 0; i < deck[1].length - 1; i++) {
        if (deck[1][i].length < level) {
          //CHECK IF THERE IS A CARD WITH LESS LEVELS THAN GENERATION LEVEL
          console.log(
            "Generation level is: L" +
            level +
            ", but got a card with " +
            deck[1][i].length +
            "layers."
          );
          return false;
        }

        if (deck[1][i].length != deck[1][i + 1].length) {
          //CHECK IF THERE ARE CARDS WITH DIFFERENT NODE SIZES
          console.log("Cards have inconsistent layers");
          return false;
        }
      }

      for (i = 0; i < deck[2].length - 1; i++) {
        if (deck[2][i].length < level) {
          //CHECK IF THERE IS A CARD WITH LESS LEVELS THAN GENERATION LEVEL
          console.log(
            "Generation level is: L" +
            level +
            ", but got an instruction card with " +
            deck[1][i].length +
            "layers."
          );
          return false;
        }

        if (deck[2][i].length != deck[2][i + 1].length) {
          //CHECK IF THERE ARE CARDS WITH DIFFERENT NODE SIZES
          console.log("Cards have inconsistent layers");
          return false;
        }
      }

      if (deck[0].length != 0 && deck[1].length != 0) {
        if (
          deck[0][0].length == deck[1][0].length &&
          deck[0][0].length == deck[2][0].length
        ) {
          return true;
        } else {
          console.log("Card levels in different decks are not consistent.");
          return false;
        }
      } else if (deck[0].length != 0) {
        if (deck[0][0].length == deck[2][0].length) {
          return true;
        } else {
          console.log("Card levels in different decks are not consistent.");
          return false;
        }
      } else {
        if (deck[1][0].length == deck[2][0].length) {
          return true;
        } else {
          console.log("Card levels in different decks are not consistent.");
          return false;
        }
      }
    }
  },
  mounted() {
    var self = this;
    $(".modal").on("hide.bs.modal", function() {
      self.message = "";
    });
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    }
  }
};
</script>

<style>
.modal-dialog {
    margin-top: 5% !important;
    width: 80%;
}
</style>
