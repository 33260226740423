<!-- Help Page showing Design Card Tutorial -->
<template>
  <div class="container">
    <navbar pos="Design Cards To Go" />

    <main
      class="d-flex justify-content-center mb-3 pl-4 pr-4 ml-1 mr-1"
      style="padding-top: 10px;"
    >
      <!-- Tutorial in German -->
      <div v-if="getCurrentLocale == 'de'">
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">Design Karten</h1>
        </header>

        <p
          class="paragraph border-bottom border-secondary mb-2"
          style="padding-bottom: 20px;"
        >
          Es gibt Design Karten in unterschiedlichen Stilen und Ausführungen:
          manche haben Text, andere zeigen Bilder, manche haben die Größe
          regulärer Spielkarten, andere sind größer, oder quadratisch, es gibt
          kein richtig oder falsch und die Ausführungen solcher Karten können
          grenzenlos sein. Man kann Design Karten allein verwenden, besser
          funktionieren sie jedoch in einer Gruppe von zwei oder mehr Personen.
          Design Karten bauen auf dem Prinzip des “Design Thinking” auf welches
          von der

          <a
            class="link-dark"
            href="https://hpi-academy.de/design-thinking/was-ist-design-thinking/"
            >HPI Academy</a
          >
          wie folgt beschrieben wird:
        </p>

        <blockquote class="blockquote border-bottom border-secondary mb-2">
          <p>
            <b>
              “Design Thinking ist eine systematische Herangehensweise an
              komplexe Problemstellungen aus allen Lebensbereichen. <br />Der
              Ansatz geht weit über die klassischen Design-Disziplinen wie
              Formgebung und Gestaltung hinaus. Im Gegensatz zu vielen
              Herangehensweisen in Wissenschaft und Praxis, die von der
              technischen Lösbarkeit die Aufgabe angehen, stehen Nutzerwünsche
              und -bedürfnisse sowie nutzerorientiertes Erfinden im Zentrum des
              Prozesses. <br />Design Thinker schauen durch die Brille des
              Nutzers auf das Problem und begeben sich dadurch in die Rolle des
              Anwenders.”
            </b>
          </p>
        </blockquote>
        <p class="paragraph" style="padding-top: 20px;">
          Wofür sollen Design Karten aber nun verwendet werden? <br />Im
          Idealfall helfen diese, neue Ideen zu generieren oder einen anderen
          Blickwinkel auf ein Problem oder eine Situation zu bieten. Zudem kann
          das spielen mit den Karten in einer dafür offenen Runde Spass machen
          und neuen Schwung bringen.
        </p>
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Zurück
        </button>
      </div>
      <!-- Tutorial in English -->
      <div v-else>
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">
            Design Cards
          </h1>
        </header>

        <p
          class="paragraph border-bottom border-secondary mb-2"
          style="padding-bottom: 20px;"
        >
          Design Cards come in different styles and executions, some come with
          text, some with pictures, some come in the size of a play card, others
          are bigger or smaller than regular play cards, the variations can be
          limitless. They can be used by a single entity but are even more
          useful and productive when used in groups of two or more people.<br />
          Design Cards are based on the principle of “Design Thinking” which is
          described by the
          <a
            class="link-dark"
            href="https://hpi-academy.de/en/design-thinking/what-is-design-thinking/"
            >HPI Academy</a
          >

          as follows:
        </p>

        <blockquote class="blockquote border-bottom border-secondary mb-2">
          <p>
            <b>
              “Design Thinking is a systematic, human-centered approach to
              solving complex problems within all aspects of life. <br />The
              approach goes far beyond traditional concerns such as shape and
              layout. And unlike traditional scientific and engineering
              approaches, which address a task from the view of technical
              solvability, user needs and requirements as well as user-oriented
              invention are central to the process. <br />This approach calls
              for continuous feedback between the developer of a solution and
              the target users. Design Thinkers step into the end user's shoes -
              not only interviewing them, but also carefully observing their
              behaviors. <br />Solutions and ideas are concretized and
              communicated in the form of prototypes as early as possible, so
              that potential users can test them and provide feedback - long
              before their completion or launch. In this way, Design Thinking
              generates practical results.“</b
            >
          </p>
        </blockquote>
        <p class="paragraph" style="padding-top: 20px;">
          But what are Design Cards generally used for? <br />They are used to
          help with an ideation process such as generating new ideas. Design
          Cards can also provide a way to gain a different perspective on a
          subject. These cards can be fun and bring momentum when used with an
          open mind.
        </p>
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Back
        </button>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HelpDesignCards",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/help");
    },
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    this.german = true;
  },
};
</script>

<style scoped lang="scss">
.about,
.paragraph {
  text-align: left;
  font-size: 18px;
}
.blockquote {
  font-size: 18px;
  color: rgb(56, 0, 0);
}

h2 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
}
.header {
  position: relative;
  color: black;
  user-select: none;
}

.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}

main {
  background: rgb(252, 248, 241);
  padding: 10px;
  margin-top: 5% !important;
}

.col {
  padding: 10px 0;
  text-align: center;
}

.button-back {
  margin-bottom: 10px;
}

.button-continue {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button-pos {
  position: absolute;
  bottom: 0;
  width: 80%;
}
</style>
