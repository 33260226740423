<template>
  <div class="loggedIn">
    <navbar pos="Design Cards To Go" />
    <router-view></router-view>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "LoggedIn",
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  components: {
    Navbar,
  },
};
</script>

<style scoped lang="scss">
.about,
.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgb(252, 248, 241);
}
</style>
