<!--
Author: Patrick Gmasz

This is the play card, which is shown in play and deck preview.
-->
<template>
  <div class="mycard">
    <div class="card-top d-flex align-items-center" :style="handleTop">
      <p class="m-3">{{ top }}</p>
    </div>
    <div class="card-middle d-flex align-items-center" :style="handleMid">
      <p class="m-3">{{ mid }}</p>
    </div>
    <div class="card-bottom d-flex align-items-center" :style="handleBottom">
      <p class="m-3">{{ bottom }}</p>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "playcard",
  props: {
    top: {
      type: String,
      default: null,
    },
    mid: {
      type: String,
      default: null,
    },
    bottom: {
      type: String,
      default: null,
    },
    top_color: {
      type: String,
      default: "bb6161",
    },
    mid_color: {
      type: String,
      default: "832015",
    },
    bottom_color: {
      type: String,
      default: "bb6161",
    },
  },
  methods: {
    handleCardSize() {
      //handles size of card dependend on innerHeight and innerwidth,
      //also handles font size dependend on this height.
      var card = $(".mycard");
      var card_top = $(".card-top");
      var card_mid = $(".card-middle");
      var card_bottom = $(".card-bottom");

      var height = window.innerHeight - 150;
      var width = window.innerWidth - 50;

      if (height > 300) {
        height = 300;
      }

      if ((height / 4) * 3 >= width) {
        card.css("width", width);
        card.css("height", (width / 3) * 4);
        if (this.mid == null && this.top != null && this.bottom != null) {
          card_top.css("font-size", width * 0.04);
        } else {
          card_top.css("font-size", width * 0.05);
        }
        card_mid.css("font-size", width * 0.04);
        card_bottom.css("font-size", width * 0.04);
      } else {
        var widthCalc = (height / 4) * 3;
        card.css("height", height);
        card.css("width", widthCalc);
        if (this.mid == null && this.top != null && this.bottom != null) {
          card_top.css("font-size", widthCalc * 0.04);
        } else {
          card_top.css("font-size", widthCalc * 0.05);
        }
        card_mid.css("font-size", widthCalc * 0.04);
        card_bottom.css("font-size", widthCalc * 0.04);
      }
    },
    handleCardParts() {
      //handles the height of the different parts of the card
      if (this.mid != null && this.top == null && this.bottom == null) {
        $(".card-middle").css("height", "100%");
      } else if (this.mid == null && this.top != null && this.bottom != null) {
        $(".card-top").css("height", "50%");
        $(".card-bottom").css("height", "50%");
      } else {
        $(".card-top").css("height", "20%");
        $(".card-middle").css("height", "55%");
        $(".card-bottom").css("height", "25%");
      }
    },
  },
  computed: {
    handleTop() {
      var card_height;
      var content;
      var text;
      if (this.mid != null && this.top == null && this.bottom == null
        || this.mid == null && this.top == null && this.bottom == null) {
        card_height = "0";
      } else if (this.mid == null && this.top != null && this.bottom != null) {
        card_height = "50%";
        content = "center";
        text = "center";
      } else {
        card_height = "20%";
        content = "flex-end";
        text = "right";
      }

      //https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
      var r = parseInt(this.top_color.substr(0, 2), 16);
      var g = parseInt(this.top_color.substr(2, 2), 16);
      var b = parseInt(this.top_color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      var font_color = yiq >= 120 ? "black" : "white"; //Auto font color sensitivity
      
      return {
        "--top-color": "#" + this.top_color,
        color: font_color,
        height: card_height,
        "justify-content": content,
        "text-align": text,
      };
    },
    handleMid() {
      var card_height;
      var midOnly = false; 

      if (this.mid != null && this.top == null && this.bottom == null
            || this.mid == null && this.top == null && this.bottom == null) {
        card_height = "100%";
        midOnly = true;
      } else if (this.mid == null && this.top != null && this.bottom != null) {
        card_height = "0";
      } else {
        card_height = "55%";
      }

      //https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
      var r = parseInt(this.mid_color.substr(0, 2), 16);
      var g = parseInt(this.mid_color.substr(2, 2), 16);
      var b = parseInt(this.mid_color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      var font_color = yiq >= 120 ? "black" : "white"; //Auto font color sensitivity

      //add border to middle part if no top and bottom
      if (midOnly) {
        return {
          "--mid-color": "#" + this.mid_color,
          color: font_color,
          height: card_height,
          "border-radius": 10 + "px",
        } 
      } else return {
        "--mid-color": "#" + this.mid_color,
        color: font_color,
        height: card_height,
      };
    },
    handleBottom() {
      var card_height;
      if (this.mid != null && this.top == null && this.bottom == null
        || this.mid == null && this.top == null && this.bottom == null) {
        card_height = "0";
      } else if (this.mid == null && this.top != null && this.bottom != null) {
        card_height = "50%";
      } else {
        card_height = "25%";
      }

      //https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
      var r = parseInt(this.bottom_color.substr(0, 2), 16);
      var g = parseInt(this.bottom_color.substr(2, 2), 16);
      var b = parseInt(this.bottom_color.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      var font_color = yiq >= 120 ? "black" : "white"; //Auto font color sensitivity
    
      return {
        "--bottom-color": "#" + this.bottom_color,
        color: font_color,
        height: card_height,
      };
    },
  },
  mounted() {
    this.handleCardSize();
  },
  created() {
    window.addEventListener("resize", this.handleCardSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleCardSize);
  },
};
</script>

<style scoped lang="scss">
.mycard {
  border-radius: 10px; 
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.85);
}
.mycard:hover {
  cursor: pointer;
}
.card-top {
  background: var(--top-color);

  white-space: pre-wrap;
  user-select: none;
  overflow: hidden;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-middle {
  background: var(--mid-color);

  justify-content: center;

  white-space: pre-wrap;
  user-select: none;
}
.card-bottom {
  background: var(--bottom-color);

  justify-content: center;

  white-space: pre-wrap;
  user-select: none;

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
