<template>
  <div class="cardPreview">
    <navbar pos="Design Cards To Go" />
      <div class="container">
        <div class="row justify-content-center mb-3 pl-4 pr-4">
          <div class="header col-12 col-md-12 pt-3">
            <div>
              <div class="headline border-bottom border-dark mb-3">
                <h1> {{ texts.headerText[getCurrentLocale] }}</h1>
              </div>
              <div class="list row justify-content-center">
                  <!-- key is the highest parent nodes id -->
                  <div 
                    style="margin: 25px;"
                    v-for="card in cards" 
                    :key="card[0].id"
                  >
                    <div class="d-flex">
                      <div class="mr-auto p-2">
                        <button
                          type="button" 
                          class="card-option-button"
                          @click="openModalForDeleteConfirmation(card)"
                          data-toggle="modal"
                          data-target="#modalDeleteCard"
                        >
                          <i class="fas fa-solid fa-trash"></i>
                        </button>
                      </div>
                      <div class="p-2">
                        <button type="button" 
                          class="card-option-button" 
                          @click="openModalForEditCard(card)"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <template v-if="isBackAvailable">
                          <button type="button" 
                            class="card-option-button" 
                            @click="flipCard(card)"
                          >
                            <i class="fas fa-undo"></i>
                          </button>
                        </template>
                      </div>
                    </div>
                    <div class= "my-card" @click="openModalForEditCard(card)">
                      <template v-if="isFrontShown(card) || !isBackAvailable">
                        <SettingsPlaycard
                          :top="getTextForPosition(card, 'TOP')"
                          :mid="getTextForPosition(card, 'MID')"
                          :bottom="getTextForPosition(card, 'BOTTOM')"
                          :top_color="getColorForPosition(card, 'TOP')"
                          :mid_color="getColorForPosition(card, 'MID')"
                          :bottom_color="getColorForPosition(card, 'BOTTOM')"
                        />
                      </template>
                      <template v-else>
                        <SettingsPlaycard
                          :mid_color="getColorForPosition(card, 'BACK')"
                          :mid="getTextForPosition(card, 'BACK')"
                        />
                      </template>
                    </div>
                  </div>
              </div>
              <!--
                <div class="alert alert-danger alert-dismissible fade show" role="alert" id="infoAlert">
                {{ texts.alertText[getCurrentLocale] }}
              </div>
              -->
              <div style="margin: 5px">
                <button
                  type="button"
                  class="btn btn-lg add-button"
                  data-toggle="modal"
                  data-target="#modalAddCard"
                  @click="resetSelectedCard"
                >
                  <i class="fas fa-plus"></i>
                </button>
              </div>
              <button
                type="submit"
                class="btn btn-lg primary-action-btn"
                @click="saveCardDeck"
              >
                {{ texts.saveButtonText[getCurrentLocale] }}
              </button>
            </div>
          </div>
        </div>

        <!-- English Modals -->
        <!-- Add card Modal-->
        <div v-if="getCurrentLocale == 'en'">
          <div 
            class="modal fade"
            id="modalAddCard"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetInput">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <p class="cardFormInputTitle">
                      Place a text on the card's positions
                    </p>
                    <div class="form-group" v-for="position in getAvailableTextPositions" :key="position">
                      <label for="inputText{position}">{{ texts.positionTexts[position][getCurrentLocale] }}:</label>
                      <input 
                        type="text"
                        class="form-control"
                        :id="'inputText'+position"
                        placeholder="Enter a text"
                      >
                    </div>
                    <p v-if="error.value" style="color: red">{{ error.text[getCurrentLocale] }}</p>
                    <div v-if="isDeckSplitted">
                      <p class="cardFormInputTitle">
                        Assign card to a deck
                      </p>
                      <select class="form-control" v-model="selectedDeck">
                        <option value="deck1">Deck 1</option>
                        <option value="deck2">Deck 2</option>
                      </select>
                    </div>
                    <p class="cardFormInputTitle">
                        Choose a category
                      </p>
                      <select class="form-control" v-model="selectedCategory">
                        <option v-for="category in categories" :key="category.id" :value="category"> 
                          {{ category.name }} 
                        </option>
                      </select>
                    <p class="cardFormInputTitle">
                        Pick a card color
                    </p>
                    <div class="form-group">
                      <v-swatches
                          v-model="cardColor"
                          show-fallback
                          fallback-input-type="color"
                          popover-x="right"
                      ></v-swatches>
                    </div>
                  </form>
                  <div v-if="cardEditMode">
                    <button type="button" class="btn btn-lg primary-action-btn" @click="saveChangesOfCardEdit">Save changes</button>
                  </div>
                  <div v-else>
                    <button type="button" class="btn btn-lg primary-action-btn" @click="addCard">Add card</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Delete Confirmation Modal-->
        <div v-if="getCurrentLocale == 'en'">
          <div 
            class="modal fade"
            id="modalDeleteCard"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <i class="far fa-times-circle fa-2x"></i>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Do you really want to delete this card?
                  <div>
                    <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                    <button type="button" class="btn btn-lg btn-block border-bottom mbt-4 delete-button" data-dismiss="modal" @click="deleteCard">DELETE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- German Modals -->
        <!-- Add card Modal-->
        <div v-if="getCurrentLocale == 'de'">
          <div 
            class="modal fade"
            id="modalAddCard"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetInput">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form>
                    <p class="cardFormInputTitle">
                      Füge Text auf den Kartenpositionen hinzu
                    </p>
                    <div class="form-group" v-for="position in getAvailableTextPositions" :key="position">
                      <label for="inputText{position}">{{ texts.positionTexts[position][getCurrentLocale] }}:</label>
                      <input 
                        type="text"
                        class="form-control"
                        :id="'inputText'+position"
                        placeholder="Gib einen Text ein"
                      >
                    </div>
                    <p v-if="error.value" style="color: red">{{ error.text[getCurrentLocale] }}</p>
                    <div v-if="isDeckSplitted">
                      <p class="cardFormInputTitle">
                        Ordne die Karte einem Deck zu
                      </p>
                      <select class="form-control" v-model="selectedDeck">
                        <option value="deck1">Deck 1</option>
                        <option value="deck2">Deck 2</option>
                      </select>
                    </div>
                    <p class="cardFormInputTitle">
                        Wähle eine Kartenfarbe
                    </p>
                    <div class="form-group">
                      <v-swatches
                          v-model="cardColor"
                          show-fallback
                          fallback-input-type="color"
                          popover-x="right"
                      ></v-swatches>
                    </div>
                  </form>
                  <div v-if="cardEditMode">
                    <button type="button" class="btn btn-lg primary-action-btn" @click="saveChangesOfCardEdit">Änderungen speichern</button>
                  </div>
                  <div v-else>
                    <button type="button" class="btn btn-lg primary-action-btn" @click="addCard">Karte hinzufügen</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Delete Confirmation Modal-->
        <div v-if="getCurrentLocale == 'de'">
          <div 
            class="modal fade"
            id="modalDeleteCard"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <i class="far fa-times-circle fa-2x"></i>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Willst du diese Karte wirklich löschen?
                  <div>
                    <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4 " data-dismiss="modal" aria-label="Close">Abbrechen</button>
                    <button type="button" class="btn btn-lg btn-block border-bottom mbt-4 delete-button" data-dismiss="modal" @click="deleteCard">LÖSCHEN</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
</template>


<script>
import Navbar from "@/components/Navbar.vue";
import $ from "jquery";
import VSwatches from "vue-swatches";
import SettingsPlaycard from "../components/SettingsPlaycard.vue";
import axios from "axios";
export default {
  name: "cardPreview",
  components: {
    Navbar,
    VSwatches,
    SettingsPlaycard,
},
  data() {
    return {
      error: {
        value: false,
        text: "",
      },
      texts: {
        headerText: {
          en: "Start Adding Cards to Your Deck",
          de: "Füge deinem Deck Karten hinzu"
        },
        alertText: {
          en: "Cannot add an empty card!",
          de: "Eine leere Karte kann nicht hinzugefügt werden!"
        },
        saveButtonText: {
          en: "Save",
          de: "Speichern"
        },
        positionTexts: {
          TOP: {
            en: "Top",
            de: "Oben"
          },
          MID: {
            en: "Middle",
            de: "Mitte"
          },
          BOTTOM: {
            en: "Bottom",
            de: "Unten"
          },
          BACK: {
            en: "Backside",
            de: "Rückseite"
          }
        }
      },
      gameID: this.$store.getters.getSelectedGame.id,
      generationLevel: this.$store.getters.getSelectedGame.generationLevel,
      cards: [],
      cardBacks: [], //highest parent ids of cards which display their card backs
      unsavedChanges: false,
      categories: this.$store.getters.getFrameworkCategories,
      //for modal:
      cardEditMode: false, //if true card is edited, else new card is added
      cardColor: "#832015", //used for VSwatches
      selectedDeck: "deck1", //default deck1
      selectedCard: "",
      cardToDelete: "", 
      selectedCategory: "",
      //if game mode is edit helping arrays are used
      deletedCardIDs: [],
      addedCards: [],
      editedCards: [],
    };
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
    getLoggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    getAvailableTextPositions() {
      var level = this.generationLevel.slice(-1); //1, 2, 3, 4
      var positions = [];
      for (var i = 1; i <= level; i++) {
        var lNode = 'L' + i;
        positions.push(this.$store.getters.getSelectedGame[lNode]);
      }
      return positions;
    },
    isDeckSplitted() {
      return (this.$store.getters.isDeckSplit);
    },
    isBackAvailable() {
      return (this.generationLevel === "L4") //back only available in generation level 4
      //return(this.getAvailableTextPositions.find((element) => element === 'BACK') !== undefined);
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    },
  },
  mounted() {
    if(this.$store.getters.getEditGame) {
      this.cards = this.$store.getters.getCardDeck[0].concat(this.$store.getters.getCardDeck[1]);
    }
    //hide info alert first
    //$('#infoAlert').hide(); 
  },
  methods: {    
    addCard() {
      if (!this.checkIfCardIsNotEmpty()) {
        this.error.value = true;
        this.error.text = {
          en: "Can not add card with empty text fields, please fill in at least one!",
          de: "Eine Karte muss mindestens ein ausgefülltes Textfeld besitzen!"
        };
        return;
      };

      var level = this.generationLevel.slice(-1); //1,2,3,4
      var card = [];
      for (var i = 0; i < level; i++) {
        if (this.selectedCategory.id !== undefined) {
          console.log("it is not undefined");
          var categoryIDCopy = this.selectedCategory.id.slice();
        } else {
          categoryIDCopy = "";
        }
        var node = {
          id: this.$store.getters.getTempID, 
          title: "", 
          description: document.getElementById("inputText" + this.getAvailableTextPositions[i]).value,
          color: this.cardColor.substring(1).slice(),
          deckType: this.selectedDeck.slice(),
          categoryID: categoryIDCopy,
        };
        if (this.generationLevel === "L3" || this.generationLevel === "L4") {
          //change bottom and top color to a lighter color
          if (this.getAvailableTextPositions[i] === 'TOP' || this.getAvailableTextPositions[i] === 'BOTTOM') {
            node.color = this.adjustBrightness(this.cardColor.substring(1).slice(), 60);
          }
        }
        card.push(node);
        this.$store.commit("incrementTempID");
      }
      this.cards.push(card);

      if (this.$store.getters.getEditGame) {
        this.addedCards.push(card);
      }
      
      this.closeModal();
      this.unsavedChanges = true;
    },
    checkIfCardIsNotEmpty() {
      var level = this.generationLevel.slice(-1); //1,2,3,4
      for (var i = 0; i < level; i++) {
        if (document.getElementById("inputText" + this.getAvailableTextPositions[i]).value) {
          return true; //at least one field is filled out
        };
      }
      return false;
    },
    /**
     * reset form input of modal
     */
    resetInput() {
      $('#modalAddCard').on('hidden.bs.modal', function () {
        $(this).find('form').trigger('reset');
      });
      this.error.value = false;
      this.cardEditMode = false;
      this.selectedDeck = "deck1";
      this.selectedCategory = "";
    },
    resetSelectedCard() {
      this.resetInput();
      this.selectedCard = "";
    },
    getTextForPosition(card, position) {
      var indexOfPosition = this.getAvailableTextPositions.indexOf(position);
      if (indexOfPosition === -1) { //position not available in nodes
        return null;
      } else return card[indexOfPosition].description;
    },
    getColorForPosition(card, position) {
      var indexOfPosition = this.getAvailableTextPositions.indexOf(position);
      if (indexOfPosition === -1) { //position not available in nodes
        return "";
      } else return card[indexOfPosition].color;
    },
    openModalForEditCard(card) {
      this.cardEditMode = true;
      this.error.value = false;
      //fill modal form with data from card
      this.selectedCard = card;
      var textPositions = this.getAvailableTextPositions;      
      for (var i = 0; i < textPositions.length; i++) {
        document.getElementById("inputText" + textPositions[i]).value = this.selectedCard[i].description;
      }
      this.selectedDeck = this.selectedCard[0].deckType.slice(); //deckType of first node is sufficient since all nodes are in the same deck
      
      this.selectedCategory = Object.assign({}, this.categories.find(element => element.id === this.selectedCard[0].categoryID));
      if (this.generationLevel === "L2") { //no mid position available, only top and bottom
        this.cardColor = '#' + this.selectedCard[0].color.slice();
      } else {
        var indexOfPosition = this.getAvailableTextPositions.indexOf('MID');
        this.cardColor = '#' + this.selectedCard[indexOfPosition].color.slice();
      }
      $('#modalAddCard').modal('show')
    },
    openModalForDeleteConfirmation(card) {
      this.cardToDelete = card;
    },
    closeModal() {
      $('#modalAddCard').modal('hide');
      $('#modalAddCard').find('form').trigger('reset');
    },
    saveChangesOfCardEdit() {
      if (!this.checkIfCardIsNotEmpty()) { 
        this.closeModal();
        $('#infoAlert').show();
        this.cardEditMode = false;
        return;
      }

      this.unsavedChanges = true;
      var textPositions = this.getAvailableTextPositions;
      for (var i = 0; i < textPositions.length; i++) {
        this.selectedCard[i].description = document.getElementById("inputText" + textPositions[i]).value;
        //initialize color from VSwatches with a copy to prevent reference
        this.selectedCard[i].color = this.cardColor.substring(1).slice();
        this.selectedCard[i].deckType = this.selectedDeck.slice();
        if (this.selectedCategory.id !== undefined) {
          this.selectedCard[i].categoryID = this.selectedCategory.id.slice();
        } else {
          this.selectedCard[i].categoryID = "";
        }
        
        if (this.generationLevel === "L3" || this.generationLevel === "L4") {
          //change bottom and top color to a lighter color
          if (textPositions[i] === 'TOP' || textPositions[i] === 'BOTTOM') {
            this.selectedCard[i].color = this.adjustBrightness(this.cardColor.substring(1).slice(), 60);
          }
        }
      }
      
      if(this.$store.getters.getEditGame) {
        if ((this.selectedCard[0].id >= 0) && //not an added card because index is negative from store
            (this.editedCards.find((element) => element[0].id === this.selectedCard[0].id) === undefined)) {
          this.editedCards.push(this.selectedCard);
        }
      }
      this.closeModal();
      this.cardEditMode = false;
    },
    adjustBrightness(color, amt) {
      //https://stackoverflow.com/a/37600815
      var R = parseInt(color.substring(0, 2), 16);
      var G = parseInt(color.substring(2, 4), 16);
      var B = parseInt(color.substring(4, 6), 16);

      R = R + amt;
      G = G + amt;
      B = B + amt;

      if (R > 255) R = 255;
      else if (R < 0) R = 0;

      if (G > 255) G = 255;
      else if (G < 0) G = 0;

      if (B > 255) B = 255;
      else if (B < 0) B = 0;

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return RR + GG + BB;
    },
    deleteCard() {
      this.unsavedChanges = true;
      if (this.getEditGame) {
        //highest parent node id is enough because nodes lower ids will be deleted automatically by database
        this.deletedCardIDs.push(this.cardToDelete[0].id);
      }
      //remove from cards
      var indexOfCard = this.cards.findIndex(element => {
        return element[0].id === this.cardToDelete[0].id;
      });
      this.cards.splice(indexOfCard, 1);
    },
    flipCard(card) {
      var cardIndex = this.cardBacks.findIndex((element) => element === card[0].id);
      if (cardIndex === -1) { 
        this.cardBacks.push(card[0].id)
      } else {
        this.cardBacks.splice(cardIndex, 1);
      }
    },
    isFrontShown(card) {
      return (this.cardBacks.find((element) => element === card[0].id) === undefined);
    },
    /**
     * Saves card deck in database
     */
    async saveCardDeck() {
      this.unsavedChanges = false;
      if(this.getCreateNewGame) {
        //add all cards in db
        for(var card of this.cards) {
          var parentID = "";
          for (var node of card) {
            parentID = await this.insertNodeInDatabase(node, parentID);
          }
        }
      } else if (this.getEditGame) {
        //insert newly added cards
        for(card of this.addedCards) {
          parentID = "";
          for (node of card) {
            parentID = await this.insertNodeInDatabase(node, parentID);
          }
        }
        //update cards
        var promises = [];
        for(card of this.editedCards) {
          for (node of card) {
            promises.push(this.updateNodeInDatabase(node));
          }
        }
        //delete cards
        for (var cardID of this.deletedCardIDs) {
          promises.push(this.deleteNodeInDatabase(cardID));
        }
        Promise.all(promises)
          .then(() => {
            console.log("Successfully updated and deleted nodes");
          });
      } else {
        console.log("no correct modus found, nothing saved in database");
      }
      //route to create page
      this.$router.push("deckSelection");
    },
    async insertNodeInDatabase(node, parentID) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      try {
        const response = await axios
          .post(apiUrl, {
            request: "createCard",
            parentID: parentID,
            gameID: this.gameID,
            title: node.title.toString(),
            description: node.description.toString(),
            color: node.color.toString(),
            deckType: node.deckType.toString(),
            categoryID: node.categoryID,
          });
          return response.data;
      } catch(err) {
        console.log(err);
      }
    },
    async updateNodeInDatabase(node) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      try {
        const response = await axios
          .post(apiUrl, {
            request: "updateCard",
            cardID: node.id,
            gameID: this.gameID,
            title: node.title.toString(),
            description: node.description.toString(),
            color: node.color.toString(),
            deckType: node.deckType.toString(),
            categoryID: node.categoryID,
          });
          return response.data;
      } catch(err) {
        console.log(err);
      }
    },
    async deleteNodeInDatabase(nodeID) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      try {
        const response = await axios
          .post(apiUrl, {
            request: "deleteNode",
            id: nodeID,
          });
          return response.data;
      } catch(err) {
        console.log(err);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to create
    next((vm) => {
      if (!vm.getLoggedIn || !(vm.getCreateNewGame|| vm.getEditGame)) {
          console.log("Accessed view all page without initializing a game.");
          vm.$router.push("/create");
      }
    });
  },
 /* beforeRouteLeave (to, from, next) {
    if (this.unsavedChanges) {
      const answer = window.confirm(
        "you might lose the data, please save the data before you leave."
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },*/
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
#header {
  margin-top: 5% !important;
}

.btn {
  margin-top: 3% !important;
}

.delete-button {
  background: rgb(150, 1, 1) !important;
  border: 0.2ch;
  color: white;
}

.delete-button:hover {
  background: rgb(211, 15, 15) !important;
  border: 0.2ch;
  color: white;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.my-card:hover {
  transform: scale(1.02);
}

.add-button {
  background: rgba(0, 192, 58, 0.9) !important;
  border: 0.5ch;
  margin: 10px;
  padding: 15px;
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.add-button:hover {
  background: rgb(2, 216, 66) !important;
}

.card-option-button {
  font-size: 22px;
  border: none;
  background-color: transparent;
  outline: none;
}

.card-option-button:hover {
  background-color: transparent;
  color: rgb(61, 61, 61);
  transform: scale(1.1);
}

.fa-times-circle {
  color: red;
}

.btn {
  margin: 1%;
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
    background: rgb(53, 52, 52);
    color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}
.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}

.cardFormInputTitle {
  font-size:130%;
  padding-top: 3%;
}


</style>