<template>
  <div class="FirstLevelSettings">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pl-3 pr-3">
            <div class="header col-12 col-md-12 pt-3">
              <!-- Card Text Settings German -->
              <div v-if="getCurrentLocale == 'de'">
                <div class="headline border-bottom border-dark mb-3">
                  <h1>Füge hier die Kategorien deiner Karten hinzu</h1>
                </div>
                <h2 style="font-size: 25px; margin: 15px">
                  Hier siehst du eine Liste deiner Kategorien:
                </h2>
                <!-- Preview of CardNames-->
                <div
                  style="
                    background-color: white;
                    border-radius: 4px;
                    margin-left: 15%;
                    margin-right: 15%;
                  "
                >
                  <div
                    v-for="option in options"
                    :key="option.id"
                    style="text-align: left; padding: 0.5rem 0"
                    class="option-item"
                    :class="{ active: selectedOptionID === option.id }"
                    @click="
                      (selectedOptionID = option.id),
                        (selectedOptionTitle = option.title)
                    "
                  >
                    <button
                      class="card-list btn btn-lg btn-block"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      @click="
                        (selectedOptionID = option.id),
                          (selectedOptionTitle = option.title),
                          changeTitleAndDescription()
                      "
                    >
                      <div>
                        <i class="far fa-edit"></i>
                      </div>
                      <p style="margin: 0 0 0 0.5rem; padding: 0">
                        {{ option.title }}
                      </p>
                    </button>
                  </div>
                </div>

                <!-- Add Button-->
                <div style="margin: 5px">
                  <button
                    type="button"
                    class="btn btn-lg add-button"
                    data-toggle="modal"
                    data-target="#modalAdd"
                    @click="setTitleAndDescription()"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <!-- Buttons Back and next -->
                <button type="button" class="btn btn-lg grey" @click="goBack">
                  Zurück
                </button>

                <button
                  type="next"
                  class="btn btn-lg grey"
                  @click="submitFirstLevelSettings(true)"
                >
                  Weiter
                </button>
              </div>
              <!-- Card Text Settings English -->
              <div v-else>
                <div class="headline border-bottom border-dark mb-3">
                  <h1>Enter a name and description for each of your Cards</h1>
                </div>

                <h2 style="font-size: 25px; margin: 15px">
                  Here is a List of your Cards:
                </h2>
                <!-- Preview of CardNames-->
                <div
                  style="
                    background-color: white;
                    border-radius: 4px;
                    margin-left: 15%;
                    margin-right: 15%;
                  "
                >
                  <div
                    v-for="option in options"
                    :key="option.id"
                    style="text-align: left; padding: 0.2rem 0"
                    class="option-item"
                    :class="{ active: selectedOptionID === option.id }"
                    @click="
                      (selectedOptionID = option.id),
                        (selectedOptionTitle = option.title)
                    "
                  >
                    <button
                      class="card-list btn btn-lg btn-block"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      @click="
                        (selectedOptionID = option.id),
                          (selectedOptionTitle = option.title),
                          changeTitleAndDescription()
                      "
                    >
                      <div>
                        <i class="far fa-edit"></i>
                      </div>
                      <p style="margin: 0 0 0 0.5rem; padding: 0">
                        {{ option.title }}
                      </p>
                    </button>
                  </div>
                </div>

                <!-- Add Button-->
                <div style="margin: 5px">
                  <button
                    type="button"
                    class="btn btn-lg add-button"
                    data-toggle="modal"
                    data-target="#modalAdd"
                    @click="setTitleAndDescription()"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
                <!-- Buttons Back and next -->
                <button type="button" class="btn btn-lg grey" @click="goBack">
                  Back
                </button>

                <button
                  type="next"
                  class="btn btn-lg grey"
                  @click="submitFirstLevelSettings(true)"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="getCurrentLocale == 'de'">
          <div
            class="modal fade"
            id="modalAdd"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            style="margin-top: 80px"
          >
            <div class="modal-dialog" style="margin-top: 80px">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title font-weight-bold">
                    {{ this.getSelectedGame.gameName }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <!-- <span aria-hidden="true">&times;</span> -->
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="nameInput">Name:</label>
                    <input
                      type="name"
                      class="form-control"
                      id="nameInput"
                      aria-describedby="emailHelp"
                      placeholder="Namen hinzufügen"
                      :value="title"
                    />
                  </div>
                  <div class="form-group">
                    <!--<label for="nameInput"
                      >Text auf der Karte:</label
                    >
                    <div class="textBox">
                      <textarea
                        class="form-control"
                        id="descriptionInput"
                        rows="3"
                        placeholder="Schreibe hier eine Beschreibung für deine Karten"
                        :value="description"
                      ></textarea>
                    </div>-->
                    <div
                      class="form__field"
                      style="margin-top: 4% margin-bottom:4%"
                    >
                      <div class="form__label">
                        <label>Wähle eine Farbe:</label>
                        <div class="form__input" @click="saveChangesTemp()">
                          <v-swatches
                            v-model="backgroundColor"
                            show-fallback
                            fallback-input-type="color"
                            popover-x="right"
                          ></v-swatches>
                        </div>
                      </div>
                    </div>
                    <!--
                  <div class="custom-file">
                    <input type="file" class="custom-file-input" id="customFile" />
                    <label class="custom-file-label" for="customFile"
                      >Upload a background photo(NOT IMPLEMENTED)</label
                    >
                  </div>
                  --></div>
                  <button
                    type="button"
                    class="btn btn-lg grey"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                    @click="deselectAllOptions"
                  >
                    Abbrechen
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg grey"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="deleteNode()"
                  >
                    Löschen
                  </button>
                  <button
                    type="submit"
                    class="btn btn-lg grey"
                    @click="saveChanges()"
                  >
                    Speichern
                  </button>
                </div>
                <div class="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="modal fade"
            id="modalAdd"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
            style="margin-top: 80px"
          >
            <div class="modal-dialog" style="margin-top: 80px">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title font-weight-bold">
                    {{ this.getSelectedGame.gameName }}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <!-- <span aria-hidden="true">&times;</span> -->
                  </button>
                </div>
                <div class="modal-body">
                  <div class="form-group">
                    <label for="nameInput">Name:</label>
                    <input
                      type="name"
                      class="form-control"
                      id="nameInput"
                      aria-describedby="emailHelp"
                      placeholder="Enter a name"
                      :value="title"
                    />
                  </div>
                  <div class="form-group">
                    <label for="nameInput">Text on card:</label>
                    <div class="textBox">
                      <textarea
                        class="form-control"
                        id="descriptionInput"
                        rows="3"
                        placeholder="Enter a description"
                        :value="description"
                      ></textarea>
                    </div>
                    <div
                      class="form__field"
                      style="margin-top: 4% margin-bottom:4%"
                    >
                      <div class="form__label">
                        <label>Pick a color:</label>
                        <div class="form__input" @click="saveChangesTemp()">
                          <v-swatches
                            v-model="backgroundColor"
                            show-fallback
                            fallback-input-type="color"
                            popover-x="right"
                          ></v-swatches>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-lg grey"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="closeModal"
                    @click="deselectAllOptions"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg grey"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="deleteNode()"
                  >
                    Delete
                  </button>
                  <button
                    type="submit"
                    class="btn btn-lg grey"
                    @click="saveChanges()"
                  >
                    Save
                  </button>
                </div>
                <div class="modal-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "../components/TabCreateCards.vue";
import VSwatches from "vue-swatches";
import axios from "axios";
import $ from "jquery";
export default {
  name: "FirstLevelSettings",
  components: {
    Navbar,
    TabCreateCards,
    VSwatches,
},
  data() {
    return {
      backgroundColor: "#000000",
      options: [],
      selectedOptionID: "",
      selectedOptionTitle: "",
      L2Nodes: [],
      title: "",
      description: "",
    };
  },
  computed: {
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    selectedCard() {
      const currentID = this.selectedOptionID;
      const card = this.options.find((o) => o.id == currentID);
      if (card) {
        this.changeBackgroundColor(card.color);
      }
      return card;
    },
    getSelectedGame() {
      return this.$store.getters.getSelectedGame;
    },
    calculateDescriptionValue() {
      if (document.getElementById("descriptionInput").value === "") {
        return this.selectedCard.description;
      }
      return document.getElementById("descriptionInput").value;
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
  },
  methods: {
    setTitleAndDescription() {
      //add
      if (this.selectedOptionID == "") {
        this.title = "";
        this.description = "";
      }
    },
    changeTitleAndDescription() {
      //edit
      if (this.selectedOptionID != "") {
        this.title = this.selectedCard.title;
        this.description = this.selectedCard.description;
      }
    },
    saveChangesTemp() {
      //every time when computed method is called values were resetting therefore a temporary saving on color pick
      this.title = document.getElementById("nameInput").value;
      this.description = document.getElementById("descriptionInput").value;
    },
    changeBackgroundColor(color) {
      if (color) {
        this.backgroundColor = "#" + color;
      } else {
        this.backgroundColor = "#000000";
      }
    },

    deselectAllOptions() {
      this.selectedOptionID = "";
    },
    checkDepth() {
      //checks if the levels are valid Example: If one L1 element has children nodes until L3 all L1 elements should have until L3
      var returnBoolean = true;
      if (this.$store.getters.getL2Nodes.length != 0) {
        this.$store.getters.getL1Nodes.forEach((element) => {
          if (
            !this.$store.getters.getL2Nodes.some(
              (element2) => element2.parentID == element.id
            )
          ) {
            returnBoolean = false;
          }
        });
      }
      if (this.$store.getters.getL3Nodes.length != 0) {
        this.$store.getters.getL2Nodes.forEach((element) => {
          if (
            !this.$store.getters.getL3Nodes.some(
              (element2) => element2.parentID == element.id
            )
          ) {
            returnBoolean = false;
          }
        });
      }
      if (this.$store.getters.getL4Nodes.length != 0) {
        this.$store.getters.getL3Nodes.forEach((element) => {
          if (
            !this.$store.getters.getL4Nodes.some(
              (element2) => element2.parentID == element.id
            )
          ) {
            returnBoolean = false;
          }
        });
      }
      return returnBoolean;
    },
    submitFirstLevelSettings(calledByNextButton) {
      var card = { id: this.selectedOptionID, title: this.selectedOptionTitle };
      this.$store.commit("updateSelectedParentCardL1", card);
      this.$store.commit("updateL1Nodes", this.options);

      //Disabled for debbuging
      if (this.selectedOptionID && !calledByNextButton) {
        //it redirects to secondLevelSettings if it is not called by next button
        this.$router.push("secondLevelSettings");
      } else {
        if (this.$store.getters.getL1Nodes.length != 0 && this.checkDepth()) {
          this.$router.push("instructionsSettings");
        } else {
          alert(
            "Each element must have the same depth! (if one element has L3 Card every card must have at least 1 L3 element)"
          );
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    setupOtherNodes() {
      //on first visit gets all the nodes from database and puts in Vuex store.
      var self = this;
      var tempArray = [];
      let promises = [];
      let promises2 = [];
      this.$store.commit("updateL2Nodes", tempArray);
      this.$store.commit("updateL3Nodes", tempArray);
      this.$store.commit("updateL4Nodes", tempArray);
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios;
      this.$store.getters.getL1Nodes.forEach((element) => {
        promises.push(
          axios
            .post(apiUrl, {
              request: "cardsWithParentID",
              gameID: this.getSelectedGame.id.toString(),
              selectedParentCardID: element.id,
            })
            .then(function (response) {
              if (tempArray.length == 0) {
                tempArray = response.data;
              } else {
                tempArray = tempArray.concat(response.data);
              }
              self.$store.commit("updateL2Nodes", tempArray);
            })
          /*.catch(function(err) {
            console.log(err);
          });*/
        );
      });
      Promise.all(promises)
        .then(function () {
          tempArray = [];
          self.$store.getters.getL2Nodes.forEach((element) => {
            promises2.push(
              axios
                .post(apiUrl, {
                  request: "cardsWithParentID",
                  gameID: self.getSelectedGame.id.toString(),
                  selectedParentCardID: element.id,
                })
                .then(function (response) {
                  if (tempArray.length == 0) {
                    tempArray = response.data;
                  } else {
                    tempArray = tempArray.concat(response.data);
                  }
                  self.$store.commit("updateL3Nodes", tempArray);
                })
            );
          });
        })
        .then(function () {
          Promise.all(promises2).then(function () {
            tempArray = [];
            self.$store.getters.getL3Nodes.forEach((element) => {
              axios
                .post(apiUrl, {
                  request: "cardsWithParentID",
                  gameID: self.getSelectedGame.id.toString(),
                  selectedParentCardID: element.id,
                })
                .then(function (response) {
                  if (tempArray.length == 0) {
                    tempArray = response.data;
                  } else {
                    tempArray = tempArray.concat(response.data);
                  }
                  self.$store.commit("updateL4Nodes", tempArray);
                });
            });
          });
        });
    },
    getTitles(response) {
      this.options = response.data;
    },
    selectAndGoNext(option) {
      this.selectedOptionID = option.id;
      this.selectedOptionTitle = option.title;
      this.submitFirstLevelSettings(false);
    },
    saveChanges() {
      //if no option is selected it puts in vuex store a new node with status create else updates the node in vuex store
      if (document.getElementById("nameInput").value.length === 0) {
        alert("Name cannot be empty!");
        return;
      }
      if (document.getElementById("descriptionInput").value.length < 2) {
        alert("Description must be longer than 2 characters!");
        return;
      }
      $("#modalAdd").modal("hide");
      var status = "";
      if (this.selectedOptionID == "") {
        status = "create";
        this.options.push({
          id: this.$store.getters.getTempID + "",
          parentTitle: "",
          gameID: this.getSelectedGame.id.toString(),
          title: document.getElementById("nameInput").value,
          description: document.getElementById("descriptionInput").value,
          color: this.backgroundColor.substring(1),
          status: status,
          deckType: "deck1",
        });
        this.$store.commit("incrementTempID");
      } else {
        this.options.forEach((element) => {
          if (element.id == this.selectedOptionID) {
            element.title = document.getElementById("nameInput").value;
            element.description =
              document.getElementById("descriptionInput").value;
            element.color = this.backgroundColor.substring(1);
            if (element.status !== "create") {
              element.status = "update";
            }
          }
        });
      }
      this.$store.commit("updateL1Nodes", this.options);
      this.$store.commit("updateHighestChangedLayer", "L1");
    },
    deleteNode() {
      //finding and deleting the nodes first from Vuex Store then calls delete from database method
      if (!confirm("Do you really want to delete the selected node")) {
        return;
      }
      var selectedCard = this.selectedCard;
      var tempArray = this.$store.getters.getL1Nodes.filter(
        (element) => element.id != this.selectedCard.id
      );
      this.options = tempArray;
      this.$store.commit("updateL1Nodes", tempArray);
      var childrenNodesL2 = this.$store.getters.getL2Nodes.filter(
        (element) =>
          element.parentID == selectedCard.id ||
          element.parentTitle == selectedCard.title
      );
      var childrenNodesL3 = [];
      childrenNodesL2.forEach((element) => {
        childrenNodesL3 = childrenNodesL3.concat(
          this.$store.getters.getL3Nodes.filter(
            (elementL3) =>
              elementL3.parentID == element.id ||
              elementL3.parentTitle == element.title
          )
        );
      });
      var childrenNodesL4 = [];
      childrenNodesL3.forEach((element) => {
        childrenNodesL4 = childrenNodesL4.concat(
          this.$store.getters.getL4Nodes.filter(
            (elementL4) =>
              elementL4.parentID == element.id ||
              elementL4.parentTitle == element.title
          )
        );
        var newNodes = this.$store.getters.getL4Nodes.filter(
          (element) => !childrenNodesL4.includes(element)
        );
        this.$store.commit("updateL4Nodes", newNodes);
        newNodes = this.$store.getters.getL3Nodes.filter(
          (element) => !childrenNodesL3.includes(element)
        );
        this.$store.commit("updateL3Nodes", newNodes);
        newNodes = this.$store.getters.getL2Nodes.filter(
          (element) => !childrenNodesL2.includes(element)
        );
        this.$store.commit("updateL2Nodes", newNodes);
      });
      if (typeof selectedCard.id === typeof "string") {
        this.deleteFromDatabase(selectedCard.id);
      }
      this.selectedOptionID = "";
      this.selectedOptionTitle = "";
    },
    deleteFromDatabase(id) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(apiUrl, {
          request: "deleteNode",
          id: id,
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  mounted() {
    if (this.$store.getters.getL1FirstVisit) {
      if (!this.$store.getters.getCreateNewGame) {
        var self = this;
        const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line

        axios
          .post(apiUrl, {
            request: "cardsWithParentID",
            gameID: this.getSelectedGame.id.toString(),
            selectedParentCardID: "",
          })
          .then(function (response) {
            self.getTitles(response);
            self.$store.commit("updateL1Nodes", self.options);
            self.$store.commit("updateL1FirstVisit", false);
            self.setupOtherNodes();
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      this.$store.commit("updateL1FirstVisit", false);
    } else {
      this.options = this.$store.getters.getL1Nodes;
    }
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to loggedIn
    next((vm) => {
      if (vm.getLoggedIn == false) {
        console.log("Accessed view all page without initializing a game.");
        vm.$router.push("/loggedIn");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
.option-item {
  &:hover {
    cursor: pointer;
  }
}

.active {
  background-color: rgb(217, 217, 255);
}

#header {
  margin-top: 20px;
  color: black;
}
.form-control {
  height: 200%;
}
.btn {
  margin-top: 1%;
  margin: 1%;
}

.deselectButton {
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #000;
}
#descriptionInput {
  height: 30%;
}

.add-button {
  background: rgba(0, 192, 58, 0.9) !important;
  border: 0.5ch;
  margin: 10px;
  padding: 15px;
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.add-button:hover {
  background: rgb(2, 216, 66) !important;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.card-list {
  display: inline-flex !important;
}
</style>
