<!--
Author: Patrick Gmasz

This is the Play page of the website.
-->
<template>
  <div class="play">
    <navbar pos="Design Cards To Go" />
    <div class="d-flex justify-content-center">
      <div class="cardcol">
        <div class="d-flex justify-content-center h-100">
          <div
            class="card-box d-flex align-items-center justify-content-center"
          >
            <div class="p-1">
              <!-- Show play card(s) -->
              <template v-if="playCards[1] == null">
                <!-- If card deck is not splitted into 2 different card decks,
                 only 1 card will be shown -->
                <div
                  id="carouselControls"
                  class="carousel slide carousel-inner"
                  data-ride="carousel"
                  data-interval="false"
                >
                  <template v-if="!this.playBacks[0]">
                    <!-- Front side of card -->
                    <div @click="swapPlay(0)">
                      <playcard
                        :top="getCardText('TOP', 0, 'NORMAL')"
                        :mid="getCardText('MID', 0, 'NORMAL')"
                        :bottom="getCardText('BOTTOM', 0, 'NORMAL')"
                        :top_color="getCardColor('TOP', 0, 'NORMAL')"
                        :mid_color="getCardColor('MID', 0, 'NORMAL')"
                        :bottom_color="getCardColor('BOTTOM', 0, 'NORMAL')"
                      />
                    </div>
                  </template>
                  <template v-else>
                    <!-- Back side of card (if it exists, 
                    checked in swapPlay method)-->
                    <div @click="swapPlay(0)">
                      <playcard
                        :mid="getCardText('BACK', 0, 'NORMAL')"
                        :mid_color="getCardColor('TOP', 0, 'NORMAL')"
                      />
                    </div>
                  </template>
                </div>
              </template>
              <template v-else>
                <!-- If card deck is splitted into 2 different decks -->
                <div
                  id="carouselControls"
                  class="carousel slide"
                  data-ride="carousel"
                  data-interval="false"
                >
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <template v-if="!playBacks[0]">
                        <!-- Front side of card 1 -->
                        <div @click="swapPlay(0)">
                          <template v-if="playCards.length > 0">
                            <playcard
                              :top="getCardText('TOP', 0, 'NORMAL')"
                              :mid="getCardText('MID', 0, 'NORMAL')"
                              :bottom="getCardText('BOTTOM', 0, 'NORMAL')"
                              :top_color="getCardColor('TOP', 0, 'NORMAL')"
                              :mid_color="getCardColor('MID', 0, 'NORMAL')"
                              :bottom_color="
                                getCardColor('BOTTOM', 0, 'NORMAL')
                              "
                            />
                          </template>
                        </div>
                      </template>
                      <template v-else>
                        <!-- Back side of card 1 (if it exists, 
                        checked in swapPlay method)-->
                        <div @click="swapPlay(0)">
                          <playcard
                            :top="null"
                            :bottom="null"
                            :mid="getCardText('BACK', 0, 'NORMAL')"
                            :mid_color="getCardColor('MID', 0, 'NORMAL')"
                          />
                        </div>
                      </template>
                    </div>
                    <div class="carousel-item">
                      <template v-if="!playBacks[1]">
                        <!-- Front side of card 2 -->
                        <div @click="swapPlay(1)">
                          <playcard
                            :top="getCardText('TOP', 1, 'NORMAL')"
                            :mid="getCardText('MID', 1, 'NORMAL')"
                            :bottom="getCardText('BOTTOM', 1, 'NORMAL')"
                            :top_color="getCardColor('TOP', 1, 'NORMAL')"
                            :mid_color="getCardColor('MID', 1, 'NORMAL')"
                            :bottom_color="getCardColor('BOTTOM', 1, 'NORMAL')"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <!-- Back side of card 2 (if it exists, 
                        checked in swapPlay method)-->
                        <div @click="swapPlay(1)">
                          <playcard
                            :mid="getCardText('BACK', 1, 'NORMAL')"
                            :mid_color="getCardColor('MID', 1, 'NORMAL')"
                          />
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottombar />
  </div>
</template>

<script>
import Bottombar from "../components/Bottombar.vue";
import Navbar from "../components/Navbar.vue";
import Playcard from "../components/Playcard.vue";
import $ from "jquery";

export default {
  components: {
    Navbar,
    Bottombar,
    Playcard,
  },
  data() {
    return {
      playCards: [],
      playBacks: [],
      instructionCards: [],
      instructionBacks: [],
      hasBack: Boolean,
      drawnCards: [], //remember which cards were already drawn
    };
  },
  computed: {
    getGameName() {
      //returns the name of the selected game.
      return this.$store.getters.getSelectedGame.gameName;
    },
    getCurrentLocale() {
      //returns the current language
      return this.$store.getters.getCurrentLocale;
    },
  },
  methods: {
    keyPressed: function(event) {
      //Space
      if (event.which == 32) {
        this.swapPlay(this.$store.getters.getCurrentCard - 1);
      }
      //left
      if (event.which == 37) {
        $("#carouselControls").carousel("prev");
      }
      //right
      if (event.which == 39) {
        $("#carouselControls").carousel("next");
      }

      this.handleHeight();
      this.$forceUpdate();
    },
    getCardText(position, index, type) {
      //returns the text for the given position (TOP, MID, BOTTOM, BACK) of the card
      //index = card index
      //type needs to be either NORMAL (for playcards) or INSTRUCTION
      var game = this.$store.getters.getSelectedGame;
      var card = null;
      var cards = null;

      if (type == "NORMAL") {
        cards = this.playCards;
        card = this.playCards[index];
      } else if (type == "INSTRUCTION") {
        cards = this.instructionCards;
        card = this.instructionCards[index];
      } else {
        console.log("Wrong type.");
        return null;
      }

      if (card == null) {
        console.log("Card is null.");
      }
      var layers = new Array();
      layers.push(game.L1);
      layers.push(game.L2);
      layers.push(game.L3);
      layers.push(game.L4);

      for (var pos = 0; pos < layers.length; pos++) {
        if (layers[pos] == position) {
          if (cards[index][pos].description == null) {
            // If multiple nodes are in this layer
            var text = new String();
            for (var i = 0; i < cards[index][pos].length; i++) {
              text += cards[index][pos][i].description + "\n\n";
            }
            return text;
          }
          return cards[index][pos].description;
        }
      }
      console.log("No layer for position: " + position);
      return null;
    },
    getCardColor(position, index, type) {
      //returns the color for the given position (TOP, MID, BOTTOM, BACK) of the card
      //index = card index
      //type needs to be either NORMAL (for playcards) or INSTRUCTION
      var game = this.$store.getters.getSelectedGame;
      var card = null;

      if (type == "NORMAL") {
        card = this.playCards[index];
      } else if (type == "INSTRUCTION") {
        card = this.instructionCards[index];
      } else {
        console.log("Wrong type.");
        return null;
      }
      if (card == null) {
        console.log("Card is null.");
      }
      var layers = new Array();
      layers.push(game.L1);
      layers.push(game.L2);
      layers.push(game.L3);
      layers.push(game.L4);

      for (var pos = 0; pos < layers.length; pos++) {
        if (layers[pos] == position) {
          if (card[pos].length == 0) {
            console.log("Node is empty.");
            return "FFFFFF";
          }
          if (card[pos].color == null) {
            if (card[pos][0].color == "" || card[pos][0].color == null) {
              return "FFFFFF";
            } else {
              if (card[pos][0].color.charAt(0) == "#") {
                return card[pos][0].color.substring(1);
              } else {
                return card[pos][0].color;
              }
            }
          } else {
            if (card[pos].color == "") {
              return "FFFFFF";
            }
            if (card[pos].color.charAt(0) == "#") {
              return card[pos].color.substring(1);
            } else {
              return card[pos].color;
            }
          }
        }
      }
      return "FFFFFF";
    },
    swapPlay(index) {
      if (this.hasBack) {
        this.playBacks[index] = !this.playBacks[index];
        this.$forceUpdate();
      }
    },
    swapInstruction(index) {
      if (this.hasBack) {
        this.instructionBacks[index] = !this.instructionBacks[index];
        this.$forceUpdate();
      }
    },
    handleHeight() {
      //handles size of column where card will be in, needs to be changed after resize so card always stays centered.
      var div = $(".cardcol");
      var height = window.innerHeight - 150;
      div.css("height", height);
    },
    setAllCardsToFrontSide() {
      for (var i = 0; i < this.playBacks.length; ++i) {
        this.playBacks[i] = false;
      }
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
  mounted() {
    var self = this;
    //sets function to increment and decrement current card when user presses prev or next
    $("#carouselControls").on("slide.bs.carousel", function(event) {
      if (event.direction == "left") {
        self.$store.commit("incrementCurrentCard");
      } else {
        self.$store.commit("decrementCurrentCard");
      }
      //300 must be equal to css: transition-duration: 0.3s;
      self.sleep(300).then(() => self.setAllCardsToFrontSide());
      self.$forceUpdate();
    });
    this.handleHeight();
  },
  created() {
    //fill props when creating the view
    var cardDeck = this.$store.getters.getCardDeck;
    if (cardDeck[1].length == 0) {
      console.log("Only one card deck");
      var random = Math.floor(Math.random() * cardDeck[0].length);
      this.playCards[0] = cardDeck[0][random];
      this.playBacks[0] = false;
      this.drawnCards.push([random]); //add card indizes to global list
    } else {
      for (var i = 0; i < 2; i++) {
        var random2 = Math.floor(Math.random() * cardDeck[i].length);
        this.playCards[i] = cardDeck[i][random2];
        this.playBacks[i] = false;
        this.drawnCards.push([random2]); //add card indizes to global list
      }
    }

    //Initialize instruction cards.
    this.instructionCards = cardDeck[2];
    for (var j = 0; j < cardDeck[2].length; j++) {
      this.instructionBacks[j] = false;
    }

    var game = this.$store.getters.getSelectedGame;
    var layers = new Array();
    layers.push(game.L1);
    layers.push(game.L2);
    layers.push(game.L3);
    layers.push(game.L4);

    var back = false;
    for (var pos = 0; pos < layers.length; pos++) {
      if (layers[pos] == "BACK") {
        back = true;
      }
    }
    this.hasBack = back;

    window.addEventListener("resize", this.handleHeight); //event listener to handle the card size when changing the window size
    window.addEventListener("keyup", this.keyPressed);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleHeight);
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to playlist
    next((vm) => {
      if (vm.getGameName == null) {
        console.log("Accessed play page without initializing a game.");
        vm.$router.push("/playlist");
      }
    });
  },
  watch: {
    "$store.state.reload": function() {
      //Watcher for the SHUFFLE button
      var cardDeck = this.$store.getters.getCardDeck;

      //udpate for all "cardDecks" -> in this case one card deck are the roles, another one all other cards, and the third one the instructions
      var i;
      for (i = 0; i < cardDeck.length - 1; i++) {
        //minus one because no new instruction card is needed
        if (cardDeck[i].length > 1) {
          var newCard;
          do {
            var random = Math.floor(Math.random() * cardDeck[i].length);
            newCard = cardDeck[i][random];
          } while (
            newCard == this.playCards[i] ||
            this.drawnCards[i].includes(random)
          );

          this.playCards[i] = newCard;
          this.playBacks[i] = false;
          this.drawnCards[i].push(random);

          //empty drawn cards list
          if (this.drawnCards[i].length >= cardDeck[i].length)
            this.drawnCards[i] = [];
          this.$forceUpdate();
        } else {
          console.log("Only one card at card[" + i + "].");
        }
      }
    },
    "$store.state.turnAround": function() {
      //Watcher for the turnAround button
      this.swapPlay(this.$store.getters.getCurrentCard - 1);
    },
  },
};
</script>

<style scoped lang="scss">
.cardcol {
  width: 0;
}
.carousel-inner {
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.85);
}

.card-box {
  margin-left: auto;
  margin-right: auto;
}
.carousel-item {
  transition-duration: 0.3s;
}
</style>
