var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about"},[_c('navbar',{attrs:{"pos":"Design Cards To Go"}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center mb-3 pl-4 pr-4"},[_c('div',{staticClass:"header col-12 col-md-6 pt-3"},[(_vm.getCurrentLocale == 'de')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" Besonderer Dank gilt allen, die zu diesem Projekt beigetragen haben: Ilhan Angin, Patrick Gmasz, Katharina Hoeldrich, Isabel Pribyl, Eva Pürmayr, Hannes Weisgrab and Diana Deac. ")])]):_c('div',[_vm._m(3),_vm._m(4),_vm._m(5),_c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" Special thanks to everyone who contributed to this project: Ilhan Angin, Patrick Gmasz, Katharina Hoeldrich, Isabel Pribyl, Eva Pürmayr, Hannes Weisgrab and Diana Deac. ")])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headline border-bottom border-dark mb-2"},[_c('h1',[_vm._v("Über uns")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" Idee und Kontakt: "),_c('a',{attrs:{"href":"http://www.drhoedl.com/"}},[_vm._v("Oliver Hödl")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" Die Design Card Web App wurde in Zusammenarbeit mit der "),_c('a',{attrs:{"href":"https://cosy.cs.univie.ac.at/"}},[_vm._v("Forschungsgruppe Cooperative Systems")]),_vm._v(" und dem "),_c('a',{attrs:{"href":"https://lehrerinnenbildung.univie.ac.at/en/fields-of-work/digital-education-and-learning-group/computational-empowerment-lab/"}},[_vm._v(" Computational Empowerment Lab der Universität Wien")]),_vm._v(" entwickelt. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headline border-bottom border-dark mb-2"},[_c('h1',[_vm._v("About")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" Idea and Contact: "),_c('a',{attrs:{"href":"http://www.drhoedl.com/"}},[_vm._v("Oliver Hödl")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" This project was developed in collaboration with the "),_c('a',{attrs:{"href":"https://cosy.cs.univie.ac.at/"}},[_vm._v("Cooperative Systems Research Group")]),_vm._v(" and "),_c('a',{attrs:{"href":"https://lehrerinnenbildung.univie.ac.at/en/fields-of-work/digital-education-and-learning-group/computational-empowerment-lab/"}},[_vm._v(" Computational Empowerment Lab of the University of Vienna")]),_vm._v(". ")])
}]

export { render, staticRenderFns }