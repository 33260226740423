<template>
  <div class="container">
    <navbar pos="Design Cards To Go" />
    <main>
      <!-- Log In window German -->
      <div v-if="getCurrentLocale == 'de'">
        <header class="header headline border-bottom border-dark mb-2">
          <h1>Erstelle ein neues Kartendeck</h1>
        </header>

        <div class="row justify-content-center">
          <h2>
            Um ein neues Kartendeck zu erstellen, ein bestehendes zu verändern
            oder mit einem eigens erstellten Kartendeck spielen zu können, log
            dich bitte ein.
          </h2>
        </div>
      </div>

      <!-- Log In window english -->
      <div v-else>
        <header class="header headline border-bottom border-dark mb-2">
          <h1>Create A New Card Deck</h1>
        </header>

        <div class="row justify-content-center">
          <h2>
            To be able to create a new card deck, to change an existing one or
            to play with one you designed, please log in!
          </h2>
        </div>
      </div>
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-lg grey"
        data-toggle="modal"
        data-target="#modalCenter"
      >
        Login
      </button>

      <!-- Modal Login German -->
      <div v-if="getCurrentLocale == 'de'">
        <div
          class="modal fade"
          id="modalCenter"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLongTitle">Login</h5> -->
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form class="px-4 py-3">
                  <div class="form-group">
                    <label for="exampleDropdownFormEmail1">Email Adresse</label>
                    <input
                      type="email"
                      class="form-control"
                      id="emailInput"
                      placeholder="email@beispiel.com"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleDropdownFormPassword1">Passwort</label>
                    <input
                      type="password"
                      class="form-control"
                      id="passwordInput"
                      placeholder="Passwort"
                    />
                  </div>
                  <div class="form-group">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="dropdownCheck"
                      />
                      <label class="form-check-label" for="dropdownCheck">
                        An mich erinnern
                      </label>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-lg grey" @click="login">
                    Einloggen
                  </button>
                </form>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#registerModal" data-toggle="modal" data-target="#registerModal" data-dismiss="modal"
                  >Du hast noch kein Konto? Meld dich hier an!</a
                >
                <a class="dropdown-item" href="#">Passwort vergessen?</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Login English -->
      <div v-else>
        <div
          class="modal fade"
          id="modalCenter"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLongTitle">Login</h5> -->
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <form class="px-4 py-3">
                  <div class="form-group">
                    <label for="exampleDropdownFormEmail1">Email address</label>
                    <input
                      type="email"
                      class="form-control"
                      id="emailInput"
                      placeholder="email@example.com"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleDropdownFormPassword1">Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="passwordInput"
                      placeholder="Password"
                    />
                  </div>
                  <div class="form-group">
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="dropdownCheck"
                      />
                      <label class="form-check-label" for="dropdownCheck">
                        Remember me
                      </label>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-lg grey" @click="login">
                    Sign in
                  </button>
                </form>
                <div class="dropdown-divider"></div>
                <!-- TODO href to corresponding links -->
                <a class="dropdown-item" href="#registerModal" data-toggle="modal" data-target="#registerModal" data-dismiss="modal">
                  New around here? Sign up</a>
                <a class="dropdown-item" href="#">Forgot password?</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Register New User German -->
      <div v-if="getCurrentLocale == 'de'">
        <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="registerMdalLabel">Registrieren</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetInput">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <label for="registerInputFirstName">Vorname</label>
                      <input type="text" class="form-control" id="registerInputFirstName" style="text-transform: capitalize;" placeholder="Vorname">
                    </div>
                    <div class="form-group">
                      <label for="registerInputLastName">Nachname</label>
                      <input type="text" class="form-control" id="registerInputLastName" style="text-transform: capitalize;" placeholder="Nachname">
                    </div>
                    <div class="form-group">
                      <label for="registerInputEmail">Email</label>
                      <input type="email" class="form-control" id="registerInputEmail" placeholder="email@beispiel.com">
                    </div>
                    <div class="form-group">
                      <label for="registerInputUsername">Username</label>
                      <input type="text" class="form-control" id="registerInputUsername" placeholder="Username">
                    </div>
                    <div class="form-group">
                      <label for="registerInputPassword">Passwort</label>
                      <input type="password" class="form-control" id="registerInputPassword" placeholder="Passwort">
                    </div>
                    <button type="submit" class="btn btn-lg grey" @click="registerUser">Registrieren</button>
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Register New User English -->
      <div v-else>
        <div class="modal fade" id="registerModal" tabindex="-1" role="dialog" aria-labelledby="registerModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="registerModalLabel">Sign Up</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="resetInput">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <label for="registerInputFirstName">First Name</label>
                      <input type="text" class="form-control" id="registerInputFirstName" style="text-transform: capitalize;" placeholder="First Name">
                    </div>
                    <div class="form-group">
                      <label for="registerInputLastName">Last Name</label>
                      <input type="text" class="form-control" id="registerInputLastName" style="text-transform: capitalize;" placeholder="Last Name">
                    </div>
                    <div class="form-group">
                      <label for="registerInputEmail">Email</label>
                      <input type="email" class="form-control" id="registerInputEmail" placeholder="email@example.com">
                    </div>
                    <div class="form-group">
                      <label for="registerInputUsername">Username</label>
                      <input type="text" class="form-control" id="registerInputUsername" placeholder="Username">
                    </div>
                    <div class="form-group">
                      <label for="registerInputPassword">Password</label>
                      <input type="password" class="form-control" id="registerInputPassword" placeholder="Password">
                    </div>
                    <button type="submit" class="btn btn-lg grey" @click="registerUser">Sign Up</button>
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import $ from "jquery";
export default {
  name: "FrameworkSettings",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
    };
  },
  methods: {
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },

    goBack() {
      this.$router.go(-2);
    },
    isLoginSuccessfull() {
      if (
        //only predefined admin user
        document.getElementById("emailInput").value === "admin" &&
        document.getElementById("passwordInput").value === "admin"
      ) {
        return true;
      }
      alert("You have entered a wrong password or email address");
      return false;
    },
    login() {
      if (this.isLoginSuccessfull()) {
        $("#modalCenter").hide();
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        this.$store.commit("updateLoggedIn", true);
        this.$router.push("loggedin");
      }
    },
    registerUser() {
      //method not implemented
      /*const crypto = require('crypto');
      const hash = crypto.createHash('sha1').update(document.getElementById("registerInputPassword").value).digest('hex');
      console.log("hashed password: "+hash); */
      
    },
    resetInput() {
      $('#registerModal').on('hidden.bs.modal', function () {
        $(this).find('form').trigger('reset');
      })
    }
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    if (this.$store.getters.getLoggedIn) {
      this.$router.push("loggedin");
    }
    this.german = true;
  },
};
</script>
<style scoped lang="scss">
.about,
h2 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
}
.header {
  position: relative;
  color: black;
  user-select: none;
}

.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}
.paragraph {
  text-align: left;
}

main {
  background: rgb(252, 248, 241);
  padding: 10px;
  margin-top: 20%;
}
</style>
