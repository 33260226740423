import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import PlayList from "../views/PlayList.vue";
import About from "../views/About.vue";
import LoggedIn from "../views/LoggedIn.vue";
import Play from "../views/Play.vue";
import ViewAll from "../views/ViewAll.vue";
import DeckSelection from "../views/DeckSelection.vue";
import NameSettings from "../views/NameSettings.vue";
import EnterInformation from "../views/EnterInformation.vue";
import InstructionsSettings from "../views/InstructionsSettings.vue";
import SplitDeckSettings from "../views/SplitDeckSettings.vue";
import CardViewSettings from "../views/CardViewSettings.vue";
import HowToPlay from "../views/HowToPlay.vue";
import Help from "../views/Help.vue";
import HelpDesignCards from "../views/HelpDesignCards.vue";
import HelpCreate from "../views/HelpCreate.vue";
import HelpTMAP from "../views/HelpTMAP.vue";
import HelpPlay from "../views/HelpPlay.vue";
import Create from "../views/Create.vue";
import CardTextSettings from "../views/CardTextSettings.vue";
import CardPreview from "../views/CardPreview";
import CardGenerationLevel from "../views/CardGenerationLevel"
import DeckSplitSetting from "../views/DeckSplitSetting"
import CardCategoriesSetting from "../views/CardCategoriesSetting"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/playlist",
    name: "PlayList",
    component: PlayList,
  },
  {
    path: "/create",
    name: "Create",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Create,
  },
  {
    path: "/loggedIn",
    name: "LoggedIn",
    component: LoggedIn,
    children: [
      {
        path: "",
        redirect: "deckSelection",
      },
      {
        path: "deckSelection",
        name: "deckSelection",
        component: DeckSelection,
      },
      {
        path: "nameSettings",
        name: "nameSettings",
        component: NameSettings,
      },
      {
        path: "enterInformation",
        name: "enterInformation",
        component: EnterInformation,
      },
      {
        path: "cardGenerationLevel",
        name: "cardGenerationLevel",
        component: CardGenerationLevel,
      },
      {
        path: "cardTextSettings",
        name: "CardTextSettings",
        component: CardTextSettings,
      },
      {
        path: "InstructionsSettings",
        name: "InstructionsSettings",
        component: InstructionsSettings,
      },
      {
        path: "splitDeckSettings",
        name: "splitDeckSettings",
        component: SplitDeckSettings,
      },
      {
        path: "cardViewSettings",
        name: "cardViewSettings",
        component: CardViewSettings,
      },
      {
        path: "cardPreview",
        name: "cardPreview",
        component: CardPreview,
      },
      {
        path: "deckSplitSetting",
        name: "deckSplitSetting",
        component: DeckSplitSetting,
      },
      {
        path: "cardCategoriesSetting",
        name: "cardCategoriesSetting",
        component: CardCategoriesSetting,
      },
    ],
  },
  {
    path: "/play",
    name: "Play",
    component: Play,
  },
  {
    path: "/viewall",
    name: "ViewAll",
    component: ViewAll,
  },
  {
    path: "/howToPlay",
    name: "HowToPlay",
    component: HowToPlay,
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
  },
  {
    path: "/helpDesignCards",
    name: "HelpDesignCards",
    component: HelpDesignCards,
  },
  {
    path: "/helpTmap",
    name: "HelpTmap",
    component: HelpTMAP,
  },
  {
    path: "/helpCreate",
    name: "HelpCreate",
    component: HelpCreate,
  },
  {
    path: "/helpPlay",
    name: "HelpPlay",
    component: HelpPlay,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  linkActiveClass: 'card-setting-active-link'
});

export default router;
