<template>
  <div class="deckSelection">
    <navbar pos="Design Cards To Go" />
    <div class="container">
      <div class="row justify-content-center mb-3 pl-4 pr-4">
        <div class="header col-12 col-md-12 pt-3">
          <div v-if="getCurrentLocale == 'de'">
            <div class="headline border-bottom border-dark mb-3 ">
              <h1>Neues Kartendeck Erstellen</h1>
            </div>

            <!-- Info button-->
            <button
              style="max-width: 40px;"
              type="button"
              class="btn btn-primary info-button"
              data-toggle="modal"
              data-target="#informationModal"
            >
              <i class="fas fa-info-circle"> </i>
            </button>
            <div class="mb-2">
              <h2 style="font-size: 25px;">
                Wähle ein bestehendes Kartendeck oder erstelle selbst eines
              </h2>
            </div>

            <div class="list row justify-content-center">
              <div
                style="max-width: 300px; margin-top: 10px; margin-bottom: 10px;"
                class="col-6"
                data-toggle="modal"
                data-target="#exampleModal"
                v-for="game in games"
                :key="game.id"
                @click="chooseDeck(game)"
              >
                <listcard
                  :gameName="game.gameName"
                  :cardColor="game.color"
                  class="mycard"
                />
              </div>
            </div>

            <div class="row justify-content-center">
              <button
                type="button"
                style="margin-top: 20px; margin-bottom: 20px;"
                class="btn btn-lg grey btn-block border-bottom mbt-4 createButton"
                @click="createNewGame()"
                id="createButton"
              >
                Neues Kartendeck Erstellen
              </button>
            </div>
          </div>
          <div v-else>
            <div class="headline border-bottom border-dark mb-3 ">
              <h1>Create New Card Deck</h1>
            </div>
            <!-- Info button-->
            <button
              style="max-width: 40px;"
              type="button"
              class="btn btn-primary info-button"
              data-toggle="modal"
              data-target="#informationModal"
            >
              <!-- TODO: Link to Tutorial-->
              <i class="fas fa-info-circle"> </i>
            </button>
            <div class="mb-2">
              <h2 style="font-size: 25px;">
                Choose an existing card deck to edit it or create one yourself
              </h2>
            </div>

            <div class="list row justify-content-center">
              <div
                style="max-width: 300px; margin-top: 10px; margin-bottom: 10px;"
                class="col-6"
                data-toggle="modal"
                data-target="#exampleModal"
                v-for="game in games"
                :key="game.id"
                @click="chooseDeck(game)"
              >
                <listcard
                  :gameName="game.gameName"
                  :cardColor="game.color"
                  class="mycard"
                />
              </div>
            </div>

            <div class="row justify-content-center">
              <button
                type="button"
                style="margin-top: 20px; margin-bottom: 20px;"
                class="btn btn-lg grey btn-block border-bottom mbt-4 createButton"
                @click="createNewGame()"
                id="createButton"
              >
                Create New Card Deck
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--Modals in German -->
      <div v-if="getCurrentLocale == 'de'">
        <div
          class="modal fade"
          id="informationModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title" id="exampleModalLongTitle">
                  Information
                </h3>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Ein Kartendeck bearbeiten</h4>
                <!-- Text from one of the earlier stages of the DCTG Web App, adapted by Katharina Hoeldrich -->
                <p>
                  Auf dieser Seite findest du eine Liste von verfügbaren Design
                  Karten Decks, welche du durch klicken auswählen kannst.
                  Daraufhin erhältst du die Optionen Deck Bearbeiten und Deck Löschen.

                  <br />
                  <!--
                    Es ist nur möglich Kartendecks zu bearbeiten, die man selbst
                  erstellt hat. Sollte man ein bestehendes Deck verändern
                  wollen, so kann man es kopieren und daraufhin das kopierte
                  Kartendeck verändern.
                  --> 
                </p>
                <h4>Neues Kartendeck Erstellen</h4>
                <p>
                  Um ein neues Kartendeck zu erstellen wirst du Schritt für
                  Schritt durch den Setup Prozess geführt.
                </p>
                <br />
                <!-- currently not uptodate, therefore not available -->
                <!--
                <p>
                  Solltest du noch weitere Fragen haben klicke auf
                  <button
                    data-dismiss="modal"
                    type="button"
                    class="btn btn-secondary btn-lg btn-block here-button"
                    @click="goToHelp()"
                  >
                    Hilfe
                  </button>
                </p>
                -->
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold">
                  {{ selectedGame.gameName }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-center">
                  {{ selectedGame.description }}
                </p>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-lg grey btn-block border-bottom mbt-4"
                  data-dismiss="modal"
                  v-on:click="editGame(selectedGame)"
                >
                  Deck Bearbeiten
                </button>
                <!--
                <button
                  class="btn btn-lg grey btn-block border-bottom mbt-4"
                  data-dismiss="modal"
                  v-on:click="copyDeck()"
                >
                  Deck Kopieren
                </button>
                -->
                <button
                  class="btn btn-lg grey btn-block border-bottom mbt-4 delete-button"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#modalDeleteGame"
                >
                  Deck LÖSCHEN
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div 
            class="modal fade"
            id="modalDeleteGame"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <i class="far fa-times-circle fa-2x"></i>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p class="text-center">
                    Willst du dieses Kartendeck wirklich löschen?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4" data-dismiss="modal" aria-label="Close">Abbrechen</button>
                  <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4 delete-button" data-dismiss="modal" @click="deleteGame">LÖSCHEN</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Modals in English -->
      <div v-else>
        <div
          class="modal fade"
          id="informationModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title" id="exampleModalLongTitle">
                  Information
                </h3>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h4>Edit A Card Deck</h4>
                <!-- Text from one of the earlier stages of the DCTG Web App, adapted by Katharina Hoeldrich -->
                <p>
                  On this page you can find a list of the available design card
                  decks. You can click to select a deck, there you can chose
                  your option of editing this deck or deleting it.
                  <br />
                <!-- 
                  Users can only edit or delete the decks, which have been
                  created (or have been copied) by them. When an existing deck
                  was copied by a user that didn't create it, they are then able
                  to make changes.
                -->  
                </p>
                <h4>Create a new Card Deck</h4>
                <p>
                  To Create a new deck the user will be lead through a step by
                  step setup.
                </p>
                <br />
                  <p>
                  For more info on how to change or create Card Decks click
                  <button
                    data-dismiss="modal"
                    type="button"
                    class="btn btn-secondary btn-lg btn-block here-button"
                    @click="goToHelp()"
                  >
                    Here
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title font-weight-bold">
                  {{ selectedGame.gameName }}
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p class="text-center">
                  {{ selectedGame.description }}
                </p>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-lg grey btn-block border-bottom mbt-4"
                  data-dismiss="modal"
                  v-on:click="editGame(selectedGame)"
                >
                  Edit Deck
                </button>
                <!--
                <button
                  class="btn btn-lg grey btn-block border-bottom mbt-4"
                  data-dismiss="modal"
                  v-on:click="copyDeck()"
                >
                  Copy Deck
                </button>
                -->
                <button
                  class="btn btn-lg grey btn-block border-bottom mbt-4 delete-button"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#modalDeleteGame"
                 
                >
                  DELETE Deck
                </button>
              </div>
            </div>
          </div>
        </div>

         <!-- Confirmation Modal of deleting a deck-->
        <div>
          <div 
            class="modal fade"
            id="modalDeleteGame"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <i class="far fa-times-circle fa-2x"></i>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p class="text-center">
                    Do you really want to delete the selected deck?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4" data-dismiss="modal" aria-label="Close">Cancel</button>
                  <button type="button" class="btn btn-lg grey btn-block border-bottom mbt-4 delete-button" data-dismiss="modal" @click="deleteGame">DELETE</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import Listcard from "../components/Listcard.vue";
import axios from "axios";

export default {
  name: "deckSelection",

  components: {
    Navbar,
    Listcard,
  },
  data() {
    return {
      games: [],
    };
  },
  methods: {
    goToHelp() {
      this.$router.push("/helpCreate");
    },

    deleteGame() {
      if (this.$store.getters.getSelectedGame.id == "1" || this.$store.getters.getSelectedGame.id == "2") {
        alert(
          "Please don't edit this version of TMAP. This version is used for User Tests."
        );
        return;
      }
     /* if (!confirm("Do you really want to delete the selected deck")) {
        return;
      }*/
      var self = this;
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(apiUrl, {
          request: "deleteGame",
          gameID: this.$store.getters.getSelectedGame.id,
        })
        .then(function() {
          self.getGames();
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    chooseDeck(game) {
      this.$store.commit("updateSelectedGame", game);
      //this.$router.push("nameSettings");
      //this.$store.commit("updateCreateNewGame", false);
    },
    createNewGame() {
      this.$store.commit("resetAllStates");
      this.$store.commit("updateCreateNewGame", true);
      this.$store.commit("updateEditGame", false);
      var game = {
          gameName: "",
          description: "",
          id: "",
          L1: "MID",
          L2: "INVISIBLE",
          L3: "INVISIBLE",
          L4: "INVISIBLE",
          generationLevel: "L1",
        };
      this.$store.commit("updateSelectedGame", game);
      this.$router.push("nameSettings");
    },
    editGame() {
      if (this.$store.getters.getSelectedGame.id == "1" || this.$store.getters.getSelectedGame.id == "2") {
        alert(
          "Please don't edit this version of TMAP. This version is used for User Tests."
        );
        return;
      }
      //load cards in store
      const apiUrl = API_URL; // eslint-disable-line
        axios
        .post(apiUrl, {
          request: "getCards",
          game: this.$store.getters.getSelectedGame.id,
        })
        .then((response) => {
          this.$store.commit("updateCardDeck", response.data);
          console.log("cards loaded in storage");
          //initalize deckSplit Setting
          if (response.data[1].length === 0) { //no cards in deck2
            this.$store.commit("updateDeckSplit", false);
          } else {
            this.$store.commit("updateDeckSplit", true);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //load card categories
      const frameworkSettingsUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(frameworkSettingsUrl, {
          request: "getCategories",
          gameID: this.$store.getters.getSelectedGame.id,
        })
        .then((response) => {
          this.$store.commit("updateFrameworkCategories", response.data);
          console.log("card categories loaded in storage");
        })
        .catch((err) => {
          console.log(err);
        });
      
      this.$store.commit("updateEditGame", true);
      this.$store.commit("updateCreateNewGame", false);
      this.$router.push("nameSettings");
    },
    copyDeck() {
      this.$store.commit("updateEditGame", false);
      this.$store.commit("updateCreateNewGame", false);
      this.$router.push("nameSettings");
    },
    getGames() {
      let self = this;
      const apiUrl = API_URL; // eslint-disable-line
        axios
        .post(apiUrl, {
          request: "games",
        })
        .then(function(response) {
          self.games = response.data;
          self.$store.commit(
            "updateGameNames",
            response.data.map(({ gameName }) => gameName)
          );
        })
        .catch(function(err) {
          console.log(err);
        });
    },

  },
  computed: {
    selectedGame() {
      return this.$store.getters.getSelectedGame;
    },
    getLoggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it i will route to playlist
    next((vm) => {
      if (vm.getLoggedIn == false) {
        console.log("Accessed view all page without initializing a game.");
        vm.$router.push("/create");
      }
    });
  },
  mounted() {
    this.$store.commit("resetAllStates");
    this.getGames();
  },
};
</script>

<style scoped lang="scss">
#header {
  margin-top: 5% !important;
}

.createButton {
  display: block;
  max-width: 52%;
  background: #4b2b97bd !important;
  border: #4b2b97 !important;
  border: 20px !important;
}

.createButton:hover {
  background: #4b2b97 !important;
}

.info-button {
  background: rgba(0, 0, 0, 0.5) !important;
  border: 0.2ch;
  margin-bottom: 10px;
}

.info-button:hover {
  background: rgba(27, 158, 66, 0.9) !important;
}

.here-button {
  margin-top: 10px;
  background: rgba(27, 158, 66, 0.9) !important;
  border: 0.2ch;
}

.delete-button {
  background: rgb(150, 1, 1) !important;
  border: 0.2ch;
}

.delete-button:hover {
  background: rgb(211, 15, 15) !important;
  border: 0.2ch;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.mycard {
  margin-left: 8%;
  margin-right: 8%;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.5);
}

.mycard:hover {
  transform: scale(1.02);
}

.my-btn {
  margin: 1.5%;
  margin-top: 3% !important;
}
.fa-times-circle {
  color: rgb(150, 1, 1);
}

</style>

<!--div class="modal-body">
              <p>
                <b>Deck Selection</b>
              </p>
              <p>
                On this page you can find a list of the available design card
                decks. You can click to select a deck to create a copy of the
                deck, edit the deck or delete the deck. Users can only edit or
                delete the decks, which have been created by them but they are
                allowed to create a copy of the desired deck to make changes.
              </p>
              <p>
                <b> Copying deck: </b> Copying a deck means that the User can
                create a copy of the desired deck and change it.
                However, the changes will not be saved on the selected deck and
                a new deck will be created.
              </p>

              <p>
                <b> Editing: </b>
                Only the user, who created the selected Deck, is allowed to edit
                the deck. All edits will be saved after the user reaches the
                final page and click on the save button.
              </p>
              <p>
                <b> Deleting: </b>
                Only the user, who created the selected Deck, is allowed to
                delete the deck. All of the nodes of the selected deck will be
                deleted immediately from the database and there is no way to
                restore them back.
              </p>
            </div -->
