<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "App",
  mounted() {
    this.setTheme();
    this.setColors();
  },
  methods: {
    setTheme(){
      const prefersColorSchemeDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      // if(  localStorage.getItem('theme') === 'dark' || prefersColorSchemeDark) {
      if(  localStorage.getItem('theme') === 'light') {
        document.body.style.setProperty('--background-color', '#ffffff');
        localStorage.setItem('theme','light')
        return;
      }
      localStorage.setItem('theme','dark')
    },
    selectGame(game) {
      this.selected = game;
    },
    setColors() {
       const apiUrl = API_URL;
      axios
        .put(apiUrl, {
          request: "setColors"
        })
        .then(response => {
          console.log("setColors function called successfully", response.data);
        })
        .catch(error => {
          console.error("Error calling setColors function", error);
        });
    }
  }

};
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  --background-color: #1e1e1e;
  margin-top: 80px !important;
  background: var(--background-color) !important;
}

.grey {
  background: #252525 !important;
  color: white !important;
}
.header {
  margin-top: 2%;
  color: black;
}
#frameworkHeader {
  color: black;
}
</style>
