<template>
  <div class="about">
    <navbar pos="Design Cards To Go" />
    <div class="container">
      <div class="row justify-content-center mb-3 pl-4 pr-4">
        <div class="header col-12 col-md-6 pt-3">
          <div v-if="getCurrentLocale == 'de'">
            <div class="headline border-bottom border-dark mb-2">
              <h1>Über uns</h1>
            </div>
            <p style="text-align: left">
              Idee und Kontakt:
              <a href="http://www.drhoedl.com/">Oliver Hödl</a>
            </p>
            <p style="text-align: left">
              Die Design Card Web App wurde in Zusammenarbeit mit der
              <a href="https://cosy.cs.univie.ac.at/"
                >Forschungsgruppe Cooperative Systems</a
              >
              und dem
              <a
                href="https://lehrerinnenbildung.univie.ac.at/en/fields-of-work/digital-education-and-learning-group/computational-empowerment-lab/"
              >
                Computational Empowerment Lab der Universität Wien</a
              >
              entwickelt.
            </p>

            <p style="text-align: left">
              Besonderer Dank gilt allen, die zu diesem Projekt beigetragen
              haben: Ilhan Angin, Patrick Gmasz, Katharina Hoeldrich, Isabel Pribyl, Eva
              Pürmayr, Hannes Weisgrab and Diana Deac.
            </p>
          </div>
          <div v-else>
            <div class="headline border-bottom border-dark mb-2">
              <h1>About</h1>
            </div>
            <p style="text-align: left">
              Idea and Contact:
              <a href="http://www.drhoedl.com/">Oliver Hödl</a>
            </p>
            <p style="text-align: left">
              This project was developed in collaboration with the
              <a href="https://cosy.cs.univie.ac.at/"
                >Cooperative Systems Research Group</a
              >
              and
              <a
                href="https://lehrerinnenbildung.univie.ac.at/en/fields-of-work/digital-education-and-learning-group/computational-empowerment-lab/"
              >
                Computational Empowerment Lab of the University of Vienna</a
              >.
            </p>
            <p style="text-align: left">
              Special thanks to everyone who contributed to this project: Ilhan
              Angin, Patrick Gmasz, Katharina Hoeldrich, Isabel Pribyl, Eva Pürmayr, Hannes
              Weisgrab and Diana Deac.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "About",
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  components: {
    Navbar,
  },
};
</script>

<style scoped lang="scss">
.about,
.header {
  margin-top: 100px;
  position: relative;
  color: black;
  user-select: none;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  content: "";
  z-index: -1;
  background: rgb(252, 248, 241);
}
</style>
