<template>
  <div class="container">
    <navbar pos="Design Cards To Go" />

    <main>
      <!-- Tutorials in German -->
      <div v-if="getCurrentLocale == 'de'">
        <!-- First Page with choices of Tutorials -->
        <header class="header headline border-bottom border-dark mb-2">
          <h1>Hilfe</h1>
        </header>
        <div class="row justify-content-center" v-if="row">
          <!-- Card for Design Card Help -->
          <div class="col">
            <div class="card" @click="goToHelp(1)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>Designkarten </b></h2>
                <p class="card-text">
                  Design Karten sind ein Hilfsmittel um Ideen zu generieren, sie
                  bauen auf dem Konzept des Design Thinking auf, mehr dazu
                  findest du hier
                </p>

                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(1)">
                    Weiter
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Card for TMAP Design Cards Help -->
          <div class="col">
            <div class="card" @click="goToHelp(2)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>TMAP Designkarten</b></h2>
                <p class="card-text">
                  Die TMAP Designkarten (TMAP steht für "technology-mediated
                  audience participation") entwickelt von
                  <a href="http://www.drhoedl.com/">Oliver Hödl</a>
                  werden verwendet um Ideen zu generieren das Publikum bei
                  Veranstaltungen mit Live-Musik technisch mit einzubinden.
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(2)">
                    Weiter
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Card for Game Play Help -->
          <div class="col">
            <div class="card" @click="goToHelp(3)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title">
                  <b>Spielanleitung</b>
                </h2>
                <p class="card-text">
                  Hier findest du eine Schritt-Für-Schritt Anleitung zum Spielen
                  mit den TMAP Designkarten!
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(3)">
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Card for Creating own design deck -->
          <!--
            <div class="col">
            <div class="card" @click="goToHelp(4)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>Eigenes Kartendeck erstellen</b></h2>
                <p class="card-text">
                  Du möchtest dir selbst ein Kartendeck erstellen? Du weißt aber
                  nicht wie du das am besten angehst? Dann bist du hier genau
                  richtig!
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(4)">
                    Weiter
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          -->
          <!-- tutorial on creation only available in english-->
          <div class="col">
            <div class="card" @click="goToHelp(4)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>Creating A New Card Deck</b></h2>
                <p class="card-text">
                  You want to create your own Design Cards card deck? Then this
                  Tutorial is the one to help you accomplish it.
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(4)">
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <!--Page showing information regarding design cards -->
        <div v-if="designcards">
          <button
            class="btn btn-outline-secondary button-back"
            @click="makeRowVisible"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
              @click="makeRowVisible"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            Zurück
          </button>
          <h2>Design Karten</h2>
        </div>
      </div>
      <!-- Tutorials in English -->
      <div v-else>
        <!-- First Page with choices of Tutorials -->
        <header class="header headline border-bottom border-dark mb-2">
          <h1>Help</h1>
        </header>
        <div class="row justify-content-center" v-if="row">
          <!-- Card for Design Card Help -->
          <div class="col">
            <div class="card" @click="goToHelp(1)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>Design Cards </b></h2>
                <p class="card-text">
                  Design Cards are a tool to help generate new ideas, they are
                  based on the concept of Design Thinking. For more information
                  press next.
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(1)">
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Card for TMAP Design Cards Help -->
          <div class="col">
            <div class="card" @click="goToHelp(2)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>TMAP Design Cards</b></h2>
                <p class="card-text">
                  The TMAP Design cards (TMAP standing for "technology-mediated
                  audience participation") were established by
                  <a href="http://www.drhoedl.com/">Oliver Hödl</a>
                  and are used to help generate new ideas to help the audience
                  participate in live music events in a technical matter.
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(2)">
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Card for Game Play Help -->
          <div class="col">
            <div class="card" @click="goToHelp(3)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>How To Play</b></h2>
                <p class="card-text">
                  Learn how to play with the TMAP Design Cards in this
                  Step-By-Step Tutorial!
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(3)">
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Card for Creating own design deck -->
          
            <div class="col">
            <div class="card" @click="goToHelp(4)">
              <img
                src="@/assets/stone.png"
                class="card-img-top"
                alt="stone background"
              />
              <div class="card-body">
                <h2 class="card-title"><b>Creating A New Card Deck</b></h2>
                <p class="card-text">
                  You want to create your own Design Cards card deck? Then this
                  Tutorial is the one to help you accomplish it.
                </p>
                <div class="button-pos">
                  <button class="btn btn-light" @click="goToHelp(4)">
                    Next
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Help",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
      row: true,
      designcards: false,
      tmap: false,
      createNewDeck: false,
    };
  },
  methods: {
    goToHelp(numberOfTutorial) {
      switch (numberOfTutorial) {
        case 1:
          this.$router.push("/helpDesignCards");
          break;
        case 2:
          this.$router.push("/helpTmap");
          break;
        case 3:
          this.$router.push("/helpPlay");
          break;
        case 4:
          this.$router.push("/helpCreate");
          break;
        default:
          break;
      }
    },
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    this.german = true;
  },
};
</script>

<style scoped lang="scss">
.about,
.paragraph {
  text-align: left;
}

h2 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-bottom: 10px;
}
.header {
  position: relative;
  color: black;
  user-select: none;
}

.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}
.paragraph {
  text-align: left;
}

main {
  background: rgba(252, 248, 241, 0.95);
  padding: 20px;
  margin-top: 8%;
}

.card {
  width: 250px;
  height: 100%;
  margin: auto;
  background: rgb(250, 250, 250);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 10, 0.15);
}

.card-body {
  padding: 10%;
}
.card-text {
  margin-bottom: 30px;
  color: black;
}

.col {
  padding: 10px 0;
  text-align: center;
}

.button-back {
  margin-bottom: 10px;
}

.button-continue {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button-pos {
  position: absolute;
  bottom: 0;
  width: 80%;
}
</style>
