<template>
  <div class="nameSettings">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pr-4">
            <div class="header col-12 col-md-12 pt-3">
              <div>
                <div class="headline border-bottom border-dark mb-3">
                  <h1> {{ texts.headerText[getCurrentLocale] }} </h1>
                </div>
                <form>
                  <div class="form-group">
                    <template v-if="getCurrentLocale == 'en'">
                      <input
                        type="text"
                        placeholder="Enter Name"
                        style="text-align: center"
                        class="form-control"
                        id="nameInput"
                        v-model="name"
                     />
                    </template>
                    <template v-else>
                      <input
                        type="text"
                        placeholder="Gib einen Namen ein"
                        style="text-align: center"
                        class="form-control"
                        id="nameInput"
                        v-model="name"
                     />
                    </template>
                  <p v-if="error.value" style="color: red">{{ error.text[getCurrentLocale] }}</p>
                  </div>
                  <button 
                    type="button" 
                    class="btn btn-lg secondary-action-btn" 
                    @click="goBack"
                  >
                    {{ texts.goBackButtonText[getCurrentLocale] }}
                  </button>
                  <button
                    type="button"
                    class="btn btn-lg primary-action-btn"
                    @click="goForward"
                  >
                    {{ texts.goForwardButtonText[getCurrentLocale] }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "@/components/TabCreateCards.vue";
//import axios from "axios";
export default {
  name: "NameSettings",
  components: {
    Navbar,
    TabCreateCards,
},
  data() {
    return {
      error: {
        value: false,
        text: "",
      },
      name: this.$store.getters.getSelectedGame.gameName,
      texts: {
        headerText: {
          en: "Enter a Name for Your Card Deck",
          de: "Gib deinem Kartendeck einen Namen"
        },
        goBackButtonText: {
          en: "Back",
          de: "Zurück"
        },
        goForwardButtonText: {
          en: "Next",
          de: "Weiter"
        }
      }
    };
  },
  computed: {
    getLoggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    }
  },
  methods: {
    goForward() {
      console.log(this.$store.getters.getSelectedGame);
      this.$router.push("enterInformation");
    },
    checkName(name) {
      if (this.$store.getters.getGameNames.includes(name) &&
        !(
          name == this.$store.getters.getSelectedGame.gameName &&
          this.$store.getters.getEditGame
        )
      ) {
        this.error.value = true;
        this.error.text = {
          en: "This name is already in use. Please choose a unique name for your deck.",
          de: "Dieser Name ist bereits vergeben. Bitte wähle einen einzigartigen Namen für dein Kartendeck."
          };
      } else {
        this.error.value = false;
        this.error.text = "";
      }
    },
    goBack() {
      this.$router.push("deckSelection");
    },
  },
  watch: {
    name(newValue) {
      this.checkName(newValue);
      if (this.error.value) {
        //if an error occurs the name should not be saved in store
        this.$store.commit("updateGameName", "");
      } else {
        this.$store.commit("updateGameName", newValue);
        this.name = newValue;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //if this page gets called directly (not via the modal), it will route to create
      if (!vm.getLoggedIn || !(vm.getCreateNewGame|| vm.getEditGame)) {
          console.log("Accessed view all page without initializing a game.");
          vm.$router.push("/create");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}

#header {
  margin-top: 5% !important;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.btn {
  margin: 1%;
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
    background: rgb(53, 52, 52);
    color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}
.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}

</style>
