var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('navbar',{attrs:{"pos":"Design Cards To Go"}}),_c('main',{staticClass:"d-flex justify-content-center mb-3 pl-7 pr-7 ml-0 mr-0",staticStyle:{"padding-top":"5px"}},[_c('div',[_vm._m(0),_c('div',{staticClass:"carousel slide",staticStyle:{"width":"100%","padding":"10px"},attrs:{"id":"carouselExampleIndicators","data-ride":"carousel","data-interval":"0"}},[_vm._m(1),_c('div',{staticClass:"carousel-inner",staticStyle:{"margin-bottom":"20px"}},[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_c('br'),_c('br'),_c('button',{staticClass:"btn btn-secondary btn-lg btn-block",staticStyle:{"background":"green"},attrs:{"type":"button"},on:{"click":function($event){return _vm.goToCreate()}}},[_vm._v(" Allright you finished this tutorial so lets start creating! ")]),_c('br'),_c('br')])])]),_vm._m(11),_vm._m(12)])]),_c('button',{staticClass:"btn btn-outline-secondary button-back",on:{"click":function($event){return _vm.goBack()}}},[_c('svg',{staticClass:"bi bi-chevron-left",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"}})]),_vm._v(" Back ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header headline border-bottom border-dark mb-2"},[_c('h1',{staticStyle:{"font-size":"30px"}},[_vm._v(" Creating your own Design Cards ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"carousel-indicators",staticStyle:{"margin-bottom":"1%","margin-top":"99%"}},[_c('li',{staticClass:"active",attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"0"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"1"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"2"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"3"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"4"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"5"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"6"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"7"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"8"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"9"}}),_c('li',{attrs:{"data-target":"#carouselExampleIndicators","data-slide-to":"10"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" On the left side you see the LogIn Page. You can find this page by clicking on the \"Create new card deck\" - Tab in the Navigation Bar on top of the page. Since you first have to log in to create a new card deck, you are asked to do so here: "),_c('br'),_vm._v(" On the right side you find the window that opens, for you to log in. If you have an account you can simply log on to it, if you don't, you can create a new account or reset your password if you forgot it. (The 'create new account' and 'reset password' - functionality has not been implemented yet) "),_c('br')]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"image",staticStyle:{"width":"500px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/EN_Create.png"),"alt":"Screenshot of the Deck Selection page"}})]),_c('div',[_c('img',{staticClass:"small-image",staticStyle:{"width":"300px","margin-left":"5px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/EN_LogIn.png"),"alt":"Screenshot of the pop-up window, showing edit, copy delete options"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" On the left side you see the overview of the card decks available. The information button on the top of the page gives a little guidance on what to do here, and brings you back to this tutorial if you want to learn more. "),_c('br'),_vm._v(" There are three available options: You can create new card decks, change or delete existing ones (under the condition that you were the original creator). "),_c('br'),_vm._v(" Clicking on a card brings you to a pop-up window where you can choose one of the before mentioned options (seen on the picture on the right hand side) "),_c('br')]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"image",staticStyle:{"width":"400px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/EN_DeckSelection.png"),"alt":"Screenshot of the Deck Selection page"}})]),_c('div',[_c('img',{staticClass:"image",staticStyle:{"width":"372px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/edit_delete_deck_modal.png"),"alt":"Screenshot of the pop-up window, showing edit, copy delete options"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" The creation and editing process consists of three parts, the first settings, where you specify general information of your deck. Then you create your instruction cards, and finally you can start adding cards to your deck. "),_c('br'),_vm._v(" After clicking 'Create New Card Deck' you start of by completing the first settings. Use the navigation menu on the left side, as seen in the picture, to navigate back and forth. ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"navigation-menu-image",attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/navigation_menu.png"),"alt":"Screenshot of the navigation menu"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" First, you will be forwarded to the page, where you can select a name for your card deck."),_c('br')]),_c('img',{staticClass:"image",staticStyle:{"width":"500px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/EN_NameSettings.png"),"alt":"Screenshot of the Name Settings page"}}),_c('br'),_c('p',{staticClass:"paragraph"},[_vm._v(" Next you will be asked to enter some information about your deck. How will these design cards help challenge its users? Be creative! ")]),_c('img',{staticClass:"image",staticStyle:{"width":"500px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/EN_EnterInformation.png"),"alt":"Screenshot of the Deck Information Page"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" Then, you can decide if you want to split your deck in two. If you choose this option, you can later assign the created cards to two sperate decks within your deck. In the playing mode two cards will be drawn at the same time, one from each deck (e.g., from the TMAP Design Cards a Role card, and an Interaction, Impact or Motivation card) ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"image",staticStyle:{"width":"500px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_split_setting.png"),"alt":"Screenshot of the Settings page for card deck splitting"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" Next, add some categories in which you want to group your cards. By clicking on the green plus, a pop-up window opens, in which you can specify the name of your category, as well as an optional description. You can edit or delete a category simply by clicking on it. ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"image",staticStyle:{"width":"500px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_categories_setting.png"),"alt":"Screenshot of the Settings page for card categories"}})]),_c('div',[_c('img',{staticClass:"small-image",staticStyle:{"width":"300px","margin-left":"5px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/modal_category_edit.png"),"alt":"Screenshot of modal category edits"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" In the last step of the first settings, you can choose a layout for your cards. The layout specifies the amount of text fields, which can be edited, as seen in the picture. You can later specify the texts of the ‘YOUR TEXT HERE’ text placeholders. Please note that the layout can not be changed once submitted. ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_layout_setting.png"),"alt":"Screenshot of the Settings page for the card layout"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" Now your game needs some instructions. For each of the three categories write a short description to help your users play. To update them, press each of the three buttons and fill out the description. The instructions will be placed on cards and added to the beginning of your deck. You can also pick a color for your instruction cards. ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"image",attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/instructions_setting.png"),"alt":"Screenshot of the Settings page for instruction cards"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"container tutorial-container"},[_c('p',{staticClass:"paragraph"},[_vm._v(" Finally, you can add cards to your deck. By clicking on the green plus button, a pop-up window opens, as seen in the right picture. You can decide on text, which should be placed on the card’s available text positions. The picture on the right shows the pop-up window for the third layout. Seeing that the third layout has the available positions top, middle, bottom and backside, you can enter a text for these positions. As the split option has been chosen, you need to assign your card to either ‘Deck 1’ or ‘Deck 2’. Additionally, you can assign a category to your deck and pick a color. "),_c('br'),_vm._v(" View your added cards directly, as seen in the left picture. You can delete them by clicking on the trash icon above. Edit a card by clicking directly on the card or the edit icon above. After you are finished, proceed by clicking on the 'Save' button. And Voilà - A card deck of your own! ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-around"}},[_c('div',[_c('img',{staticClass:"card-modal-image",staticStyle:{"height":"380px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_preview_setting.png"),"alt":"Screenshot of the card preview"}})]),_c('div',[_c('img',{staticClass:"card-modal-image",staticStyle:{"margin-left":"5px"},attrs:{"src":require("@/assets/tutorialScreenshots/CreateTutorial/UpdatedVersion/card_adding_modal_layout3.png"),"alt":"Screenshot of the pop-up window for card adding"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"carousel-control-prev",attrs:{"href":"#carouselExampleIndicators","role":"button","data-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon carousel-button-color",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Previous")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"carousel-control-next",attrs:{"href":"#carouselExampleIndicators","role":"button","data-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon carousel-button-color",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Next")])])
}]

export { render, staticRenderFns }