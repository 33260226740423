<!--
Author: Patrick Gmasz

This is the page, where all card decks are listed.
-->
<template>
  <div class="playlist">
    <navbar pos="Design Cards To Go" />
    <div class="container">
      <div class="list row justify-content-center">
        <div
          style="max-width: 300px; margin-top: 5%;"
          class="col-6 "
          data-toggle="modal"
          data-target=".card-details"
          v-for="game in games"
          :key="game.id"
          @click="selectGame(game)"
        >
          <listcard
            :gameName="game.gameName"
            :cardColor="game.color"
            class="mycard"
          />
        </div>
      </div>
      <div
        class="modal fade card-details"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <template v-if="this.selected != null">
          <list-modal :game="this.selected" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import ListModal from "../components/ListModal.vue"; //Imports the modal/ pop up window
import Listcard from "../components/Listcard.vue"; //Imports the Card Design
import axios from "axios";

export default {
  name: "PlayList",
  components: {
    Navbar,
    ListModal,
    Listcard,
  },
  data() {
    return {
      games: [],
      selected: null,
    };
  },
  computed: {
    getLoggedIn() {
      return this.$store.getters.getLoggedIn;
    },
  },
  methods: {
    selectGame(game) {
      this.selected = game;
    },
  },
  mounted() {
      // this.helloFromVue();
    let self = this;
      const apiUrl = API_URL;
      axios
      .post(apiUrl, {
        request: "games",
      })
      .then(function(response) {
        var allGames = response.data;
        //if the user is not logged in only tmap cards should be shown
        if(!self.getLoggedIn) { 
          self.games.push(allGames.find(element => element.id == 1));
          self.games.push(allGames.find(element => element.id == 2));
        } else self.games = allGames;
      })
      .catch(function(error) {
        //console.log(err);
        if (!error.response) {
          // network error
        } else {
          // http status code
          const code = error.response.status;
          console.log(code);
          // response data
          const res = error.response.data;
          console.log(res);
        }
      });
  },
};
</script>

<style scoped lang="scss">
.list {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.mycard {
  margin: 20% auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.5);
}

.mycard:hover {
  transform: scale(1.05);
}
</style>
