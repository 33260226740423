<template>
  <div class="enterInformation">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pl-4 pr-4">
            <div class="header col-12 col-md-12 pt-3">
              <div>
                <div class="headline border-bottom border-dark mb-3">
                  <h1>{{ texts.headerText[getCurrentLocale] }}</h1>
                </div>
                <div class="textBox">
                  <template v-if="getCurrentLocale == 'en'">
                    <textarea
                      placeholder="What is this deck used for? How is it used? How many players are needed to play? Etc."
                      class="form-control"
                      id="descriptionInput"
                      rows="3"
                      v-model="description"
                    ></textarea>
                  </template>
                  <template v-else>
                    <textarea
                      placeholder="Was macht man mit deinem Deck? Wie ist es zu verwenden? Wie viele Spieler braucht es? Etc."
                      class="form-control"
                      id="descriptionInput"
                      rows="3"
                      v-model="description"
                    ></textarea>
                  </template>
                </div>
                <button type="submit" class="btn btn-lg secondary-action-btn" @click="goBack">
                  {{ texts.goBackButtonText[getCurrentLocale] }}
                </button>
                <button type="submit" class="btn btn-lg primary-action-btn" @click="goForward">
                  {{ texts.goForwardButtonText[getCurrentLocale] }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "@/components/TabCreateCards.vue";

export default {
  name: "EnterInformation",
  components: {
    Navbar,
    TabCreateCards,
  },
  data() {
    return {
      texts: {
        headerText: {
          en: "Enter some Information about your deck",
          de: "Schreibe Informationen zu deinem Kartendeck"
        },
        goBackButtonText: {
          en: "Back",
          de: "Zurück"
        },
        goForwardButtonText: {
          en: "Next",
          de: "Weiter"
        }
      }
    };
  },
  computed: {
    description: {
      get() {
        return this.$store.getters.getSelectedGame.description;
      },
      set(newDescription) {
        this.$store.commit("updateDescription", newDescription);
      }
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    },
  },
  methods: {
    goForward() {
      this.$router.push("deckSplitSetting");
    },
    goBack() {
      this.$router.push("nameSettings");
    },
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to loggedIn
    next((vm) => {
      if (!vm.getLoggedIn || !(vm.getCreateNewGame|| vm.getEditGame)) {
         console.log("Accessed view all page without initializing a game.");
          vm.$router.push("/create");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
.form-control {
  resize: none;
  height: 100%;
  width: 100%;
  margin-top: 0%;
}
.btn {
  margin: 1%;
  margin-top: 10px;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
    background: rgb(53, 52, 52);
    color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}
.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}
</style>
