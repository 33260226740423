<template>
  <div class="CardViewSettings">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pl-4 pr-4">
            <div class="header col-12 col-md-12 pt-3">
              <!-- Card View Settings German -->
              <div v-if="getCurrentLocale == 'de'">
                <div class="headline border-bottom border-dark mb-3 ">
                  <h1>Wähle aus wie dein Kartendeck aussehen wird</h1>
                </div>

                <div style="width: 100%; display: flex; justify-content: center;">
                  <ul
                    style="display: inline-flex; padding-left: 0; border-radius: 4px; overflow: hidden; margin-top: 2rem; background-color: transparent; flex-wrap: wrap;"
                  >
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref"
                          >Oben:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref"
                          v-model="top"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref2"
                          >Mitte:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref2"
                          v-model="mid"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            :disabled="option.assigned"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref2"
                          >Unten:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref3"
                          v-model="bottom"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            :disabled="option.assigned"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref2"
                          >Rückseite:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref4"
                          v-model="back"
                          disabled
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            :disabled="option.assigned"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                  </ul>
                </div>

                <div
                  style="width: 100%; display: flex; justify-content: center; margin-top: 1.5rem;"
                >
                  <label>
                    Rückseite anzeigen
                    <input type="checkbox" v-model="showBackPreview"
                  /></label>
                </div>
                <div style="width: 100%; display: flex; justify-content: center">
                  <div class="card-size">
                    <SettingsPlaycard
                      :top="topText"
                      :mid="midText"
                      :bottom="bottomText"
                      :top_color="colors.top"
                      :mid_color="colors.mid"
                      :bottom_color="colors.bottom"
                    />
                  </div>
                </div>
                <button type="submit" class="btn btn-lg grey" @click="goBack()">
                  Zurück
                </button>
                <button
                  type="next"
                  class="btn btn-lg grey"
                  @click="submitCardViewSettings()"
                >
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                  ></div>
                  <span v-if="loading" class="px-1">Wird gespeichert</span>
                  <span v-else>Speichern</span>
                </button>
              </div>
              <div v-else>
                <div class="headline border-bottom border-dark mb-3 ">
                  <h1>Decide where your text should go</h1>
                </div>

                <div style="width: 100%; display: flex; justify-content: center;">
                  <ul
                    style="display: inline-flex; padding-left: 0; border-radius: 4px; overflow: hidden; margin-top: 2rem; background-color: transparent; flex-wrap: wrap;"
                  >
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref"
                          >Top:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref"
                          v-model="top"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref2"
                          >Middle:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref2"
                          v-model="mid"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref2"
                          >Bottom:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref3"
                          v-model="bottom"
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                    <li class="list-group-item">
                      <form class="form-inline">
                        <label class="my-1 mr-2" for="inlineFormCustomSelectPref2"
                          >Back:</label
                        >
                        <select
                          class="form-control form-control-lg"
                          id="inlineFormCustomSelectPref4"
                          v-model="back"
                          disabled
                        >
                          <option value="" selected></option>
                          <option
                            v-for="option in assignableOptions"
                            :key="option.value"
                            :value="option.value"
                            :disabled="option.assigned"
                            >{{ option.text }}</option
                          >
                        </select>
                      </form>
                    </li>
                  </ul>
                </div>

                <div
                  style="width: 100%; display: flex; justify-content: center; margin-top: 1.5rem;"
                >
                  <label>
                    Show Back
                    <input type="checkbox" v-model="showBackPreview"
                  /></label>
                </div>
                <div style="width: 100%; display: flex; justify-content: center">
                  <div class="card-size">
                    <SettingsPlaycard
                      :top="topText"
                      :mid="midText"
                      :bottom="bottomText"
                      :top_color="colors.top"
                      :mid_color="colors.mid"
                      :bottom_color="colors.bottom"
                    />
                  </div>
                </div>
                <button type="submit" class="btn btn-lg grey" @click="goBack()">
                  Back
                </button>
                <button type="submit" class="btn btn-lg grey" @click="submitInfo()">
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import SettingsPlaycard from "@/components/SettingsPlaycard.vue";
import axios from "axios";
import TabCreateCards from "../components/TabCreateCards.vue";
export default {
  name: "CardViewSettings",
  components: {
    Navbar,
    SettingsPlaycard,
    TabCreateCards,
  },
  data() {
    return {
      options: [],
      top: "",
      bottom: "",
      mid: "",
      back: "",
      showBackPreview: false,
      allInstructionCards: [],
      loading: false,
    };
  },
  mounted() {
    //automatically disabling non existing levels.
    this.setPositions();
    if (
      this.$store.getters.getL1Nodes.length !== 0 &&
      this.$store.getters.getL2Nodes.length === 0
    ) {
      this.mid = "Text";
      document.getElementById("inlineFormCustomSelectPref3").disabled = false;
      document.getElementById("inlineFormCustomSelectPref").disabled = false;
    }
    this.options.push({
      text: "Text",
      value: "Text",
    });

    if (this.$store.getters.getL2Nodes.length !== 0) {
      document.getElementById("inlineFormCustomSelectPref3").disabled = false;
      document.getElementById("inlineFormCustomSelectPref").disabled = false;
      this.options.push({
        text: "L2",
        value: "L2",
      });
    }
    if (this.$store.getters.getL3Nodes.length !== 0) {
      document.getElementById("inlineFormCustomSelectPref2").disabled = false;
      this.options.push({
        text: "L3",
        value: "L3",
      });
    }
    if (this.$store.getters.getL4Nodes.length !== 0) {
      document.getElementById("inlineFormCustomSelectPref4").disabled = false;
      this.options.push({
        text: "L4",
        value: "L4",
      });
    }
  },
  computed: {
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
    assignableOptions() {
      //returns which options are assignable
      const self = this;
      return this.options.map((option) => ({
        ...option,
        assigned: self.isAssignedAlready(option),
      }));
    },
    midText() {
      if (this.showBackPreview) {
        return this.back;
      }

      return this.mid;
    },
    topText() {
      if (this.showBackPreview) {
        return "";
      }

      return this.top;
    },
    bottomText() {
      if (this.showBackPreview) {
        return "";
      }

      return this.bottom;
    },
    colors() {
      if (this.showBackPreview) {
        return {
          top: "ffffff",
          mid: "ffffff",
          bottom: "ffffff",
        };
      }

      return {
        top: "bb6161",
        mid: "832015",
        bottom: "bb6161",
      };
    },
  },
  methods: {
    setPositions() {
      this.setSinglePosition("Text");
    },
    setSinglePosition(level) {
      //sets the positions of the levels on selected game
      var position = "";
      if (level == "Text") {
        position = this.$store.getters.getSelectedGame.L1;
      }
      if (level == "L2") {
        position = this.$store.getters.getSelectedGame.L2;
      }
      if (level == "L3") {
        position = this.$store.getters.getSelectedGame.L3;
      }
      if (level == "L4") {
        position = this.$store.getters.getSelectedGame.L4;
      }
      switch (position) {
        case "TOP":
          if (this.$store.getters.getL1Nodes.length != 0) {
            this.top = level;
          }
          break;
        case "BOTTOM":
          if (this.$store.getters.getL1Nodes.length != 0) {
            this.bottom = level;
          }
          break;
        case "MID":
          if (this.$store.getters.getL3Nodes.length != 0) {
            this.mid = level;
          }
          break;
        case "BACK":
          if (this.$store.getters.getL4Nodes.length != 0) {
            this.back = level;
          }
          break;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    isAssignedAlready(option) {
      return (
        this.top === option.value ||
        this.bottom === option.value ||
        this.mid === option.value ||
        this.back === option.value
      );
    },
    getCards(level) {
      //gets cards from specified level from database then when it's done calls insert of the next level
      var self = this;
      var tempArray = [];
      var promises = [];
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      if (level === "Text") {
        axios
          .post(apiUrl, {
            request: "cardsWithParentID",
            gameID: this.$store.getters.getSelectedGame.id,
            selectedParentCardID: "",
          })
          .then(function(response) {
            self.$store.commit("updateL1Nodes", response.data);
            self.insertL2Cards();
            console.log(response);
          })
          .catch(function(err) {
            console.log(err);
          });
      }
      if (level === "L2") {
        this.$store.getters.getL1Nodes.forEach((element) => {
          promises.push(
            axios
              .post(apiUrl, {
                request: "cardsWithParentID",
                gameID: this.$store.getters.getSelectedGame.id,
                selectedParentCardID: element.id,
              })
              .then(function(response) {
                tempArray = tempArray.concat(response.data);
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
        });
        Promise.all(promises).then(function() {
          self.$store.commit("updateL2Nodes", tempArray);
          self.addParentTitles("L2");
          self.insertL3Cards();
        });
      }
      promises = [];
      tempArray = [];
      if (level === "L3") {
        this.$store.getters.getL2Nodes.forEach((element) => {
          promises.push(
            axios
              .post(apiUrl, {
                request: "cardsWithParentID",
                gameID: this.$store.getters.getSelectedGame.id,
                selectedParentCardID: element.id,
              })
              .then(function(response) {
                tempArray = tempArray.concat(response.data);
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
        });
        Promise.all(promises).then(function() {
          console.log(tempArray);
          self.$store.commit("updateL3Nodes", tempArray);
          self.addParentTitles("L3");
          self.insertL4Cards();
        });
      }
    },
    addParentTitles(level) {
      //adds parent titles until the L1 level
      var tempArray = [];
      if (level === "L2") {
        tempArray = this.$store.getters.getL2Nodes;
        tempArray.forEach((element) => {
          element.L1Title = this.getParentTitle("Text", element.parentID);
        });
        this.$store.commit("updateL2Nodes", tempArray);
      }
      if (level === "L3") {
        tempArray = this.$store.getters.getL3Nodes;
        tempArray.forEach((element) => {
          var L2Node = this.getNodeByID("L2", element.parentID);
          var L1Node = this.$store.getters.getL1Nodes.find(
            (elementL1) => elementL1.id === L2Node.parentID
          );
          element.L1Title = this.getParentTitle("Text", L1Node.id);
          element.L2Title = this.getParentTitle("L2", element.parentID);
        });
        this.$store.commit("updateL3Nodes", tempArray);
      }
      if (level === "L4") {
        tempArray = this.$store.getters.getL4Nodes;
        tempArray.forEach((element) => {
          var L3Node = this.getNodeByID("L3", element.parentID);
          var L2Node = this.getNodeByID("L2", L3Node.parentID);
          var L1Node = this.getNodeByID("Text", L2Node.parentID);
          element.L1Title = this.getParentTitle("Text", L1Node.id);
          element.L2Title = this.getParentTitle("L2", L2Node.id);
          element.L3Title = this.getParentTitle("L3", L3Node.id);
        });
        this.$store.commit("updateL4Nodes", tempArray);
      }
    },
    getNodeByID(level, id) {
      var returnVal = Object;
      if (level == "Text") {
        returnVal = this.$store.getters.getL1Nodes.find(
          (element) => element.id === id
        );
      }
      if (level == "L2") {
        returnVal = this.$store.getters.getL2Nodes.find(
          (element) => element.id === id
        );
      }
      if (level == "L3") {
        returnVal = this.$store.getters.getL3Nodes.find(
          (element) => element.id === id
        );
      }
      if (level == "L4") {
        returnVal = this.$store.getters.getL4Nodes.find(
          (element) => element.id === id
        );
      }
      return returnVal;
    },
    getNodeIDByNode(level, node) {
      var returnValue = "";
      if (level === "Text") {
        this.$store.getters.getL1Nodes.forEach((element) => {
          if (node.L1Title === element.title) {
            returnValue = element.id;
          }
        });
      }
      if (level === "L2") {
        this.$store.getters.getL2Nodes.forEach((element) => {
          if (
            node.L2Title === element.title &&
            node.L1Title === element.L1Title
          ) {
            returnValue = element.id;
          }
        });
      }
      if (level === "L3") {
        this.$store.getters.getL3Nodes.forEach((element) => {
          if (
            node.L3Title === element.title &&
            node.L2Title === element.L2Title &&
            node.L1Title === element.L1Title
          ) {
            returnValue = element.id;
          }
        });
      }
      return returnValue;
    },
    insertL1Cards() {
      //inserts and updates L1 cards, calles getcards with L1
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var self = this;
      var tempArray = this.$store.getters.getL1Nodes;
      //this.$store.commit("updateL2Nodes", []);
      var promises = [];
      tempArray.forEach((element) => {
        if (element.status === "create") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "createCard",
                parentID: "",
                gameID: this.$store.getters.getSelectedGame.id.toString(),
                title: element.title.toString(),
                description: element.description.toString(),
                color: element.color.toString(),
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })

              .catch(function(err) {
                console.log(err);
              })
          );
        }
        if (element.status === "update") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "updateCard",
                parentID: "",
                cardID: element.id,
                gameID: this.$store.getters.getSelectedGame.id,
                title: element.title,
                description: element.description,
                color: element.color,
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
          // .then(this.getCards());
        }
      });
      Promise.all(promises).then(function() {
        self.getCards("Text");
      });
      // this.$store.commit("updateL2Nodes", tempArray);
    },

    insertL2Cards() {
      //inserts and updates L2 cards then calls get cards with L2
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var self = this;
      var tempArray = this.$store.getters.getL2Nodes;

      var parentID = "";
      //this.$store.commit("updateL2Nodes", []);
      var promises = [];
      tempArray.forEach((element) => {
        parentID = this.getNodeIDByNode("Text", element);
        if (element.status === "create") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "createCard",
                parentID: parentID,
                gameID: this.$store.getters.getSelectedGame.id.toString(),
                title: element.title.toString(),
                description: element.description.toString(),
                color: element.color.toString(),
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
        }
        if (element.status === "update") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "updateCard",
                parentID: parentID,
                cardID: element.id,
                gameID: this.$store.getters.getSelectedGame.id,
                title: element.title,
                description: element.description,
                color: element.color,
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
          // .then(this.getCards());
        }
      });
      Promise.all(promises).then(function() {
        self.getCards("L2");
      });
    },
    insertL3Cards() {
      //inserts and updates L3 cards then calls get cards with L3
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var self = this;
      var tempArray = this.$store.getters.getL3Nodes;
      var parentID = "";
      var promises = [];
      tempArray.forEach((element) => {
        parentID = this.getNodeIDByNode("L2", element);
        if (element.status === "create") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "createCard",
                parentID: parentID,
                gameID: this.$store.getters.getSelectedGame.id.toString(),
                title: element.title.toString(),
                description: element.description.toString(),
                color: element.color.toString(),
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })

              .catch(function(err) {
                console.log(err);
              })
          );
        }
        if (element.status === "update") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "updateCard",
                parentID: this.getNodeIDByNode("L2", element),
                cardID: element.id,
                gameID: this.$store.getters.getSelectedGame.id,
                title: element.title,
                description: element.description,
                color: element.color,
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })

              .catch(function(err) {
                console.log(err);
              })
          );
          // .then(this.getCards());
        }
        element.status = "";
      });
      Promise.all(promises).then(function() {
        self.getCards("L3");
      });
    },
    endSaving() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      setTimeout(() => {
        alert("Your game is saved now you will be redirected to Home Page");
        this.$router.push("deckSelection");
      }, 1200);
    },
    insertL4Cards() {
      //inserts and updates L2 cards then calls end saving
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var tempArray = this.$store.getters.getL4Nodes;
      var promises = [];
      var parentID = "";
      const self = this;
      tempArray.forEach((element) => {
        parentID = this.getNodeIDByNode("L3", element);
        if (element.status === "create") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "createCard",
                parentID: parentID,
                gameID: this.$store.getters.getSelectedGame.id.toString(),
                title: element.title.toString(),
                description: element.description.toString(),
                deckType: element.deckType,
                color: element.color.toString(),
              })
              .then(function(response) {
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
        }
        if (element.status === "update") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "updateCard",
                parentID: parentID,
                cardID: element.id,
                gameID: this.$store.getters.getSelectedGame.id,
                title: element.title,
                description: element.description,
                deckType: element.deckType,
                color: element.color,
              })
              .then(function(response) {
                console.log(response);
              })

              .catch(function(err) {
                console.log(err);
              })
          );
          // .then(this.getCards());
        }
      });
      Promise.all(promises).then(function() {
        self.endSaving();
      });
    },
    updateInstructionCards(updatedLevel) {
      //updates instruction cards of given level and calls insert for the next level
      const self = this;
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line

      axios
        .post(apiUrl, {
          request: "getInstructionCards",
          gameID: this.$store.getters.getSelectedGame.id + "",
        })
        .then(function(response) {
          self.$store.commit("updateInstructionCards", response.data);
          if (updatedLevel === "Text") {
            self.insertInstructionCardsL2();
          }
          if (updatedLevel === "L2") {
            self.insertInstructionCardsL3();
          }
          if (updatedLevel === "L3") {
            self.insertInstructionCardsL4();
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    insertInstructionCards() {
      //inserts L1 instruction cards then calls allInstructionCards is a temp object
      var promises = [];
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      const self = this;
      this.allInstructionCards.forEach((element) => {
        if (element.status === "update") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "updateCard",
                cardID: element.id,
                gameID: this.$store.getters.getSelectedGame.id,
                title: element.title,
                description: element.description,
                deckType: element.deckType,
                color: element.color,
              })
              .then(function(response) {
                console.log(response);
              })
              .catch(function(err) {
                console.log(err);
              })
          );
        } else if (element.status === "create") {
          if (
            element.title === "Multi Person Mode" ||
            element.title === "Single Person Mode" ||
            element.title === "General Information"
          ) {
            promises.push(
              axios
                .post(apiUrl, {
                  request: "createCard",
                  parentID: "",
                  gameID: self.$store.getters.getSelectedGame.id.toString(),
                  title: element.title.toString(),
                  description: element.description.toString(),
                  color: element.color.toString(),
                  deckType: element.deckType,
                })
                .then(function(response) {
                  console.log(response);
                })

                .catch(function(err) {
                  console.log(err);
                })
            );
          }
        }
      });
      Promise.all(promises).then(function() {
        self.updateInstructionCards("Text");
      });
    },
    getInstructionIDByTitle(title) {
      var returnID = "NOT FOUND";
      this.$store.getters.getInstructionCards.forEach((element) => {
        if (element.title === title) {
          returnID = element.id;
        }
      });
      return returnID;
    },
    insertInstructionCardsL2() {
      var promises = [];
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      const self = this;
      var parentTitle = "";
      this.allInstructionCards.forEach((element) => {
        parentTitle = "";
        if (element.title === "General Information L2") {
          parentTitle = "General Information";
        }
        if (element.title === "Single Person Mode L2") {
          parentTitle = "Single Person Mode";
        }
        if (element.title === "Multi Person Mode L2") {
          parentTitle = "Multi Person Mode";
        }
        if (parentTitle != "" && element.status === "create") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "createCard",
                parentID: this.getInstructionIDByTitle(parentTitle),
                gameID: this.$store.getters.getSelectedGame.id.toString(),
                title: element.title.toString(),
                description: element.description.toString(),
                color: element.color.toString(),
                deckType: element.deckType,
              })
              .then(function(response) {
                console.log(response);
              })

              .catch(function(err) {
                console.log(err);
              })
          );
        }
      });
      Promise.all(promises).then(function() {
        self.updateInstructionCards("L2");
      });
    },
    insertInstructionCardsL3() {
      var promises = [];
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      const self = this;
      var parentTitle = "";
      this.allInstructionCards.forEach((element) => {
        parentTitle = "";
        if (element.title === "General Information L3") {
          parentTitle = "General Information L2";
        }
        if (element.title === "Single Person Mode L3") {
          parentTitle = "Single Person Mode L2";
        }
        if (element.title === "Multi Person Mode L3") {
          parentTitle = "Multi Person Mode L2";
        }
        if (parentTitle != "" && element.status === "create") {
          promises.push(
            axios
              .post(apiUrl, {
                request: "createCard",
                parentID: this.getInstructionIDByTitle(parentTitle),
                gameID: this.$store.getters.getSelectedGame.id.toString(),
                title: element.title.toString(),
                description: element.description.toString(),
                color: element.color.toString(),
                deckType: element.deckType,
              })

              .catch(function(err) {
                console.log(err);
              })
          );
          element.status = "";
        }
      });
      Promise.all(promises).then(function() {
        self.updateInstructionCards("L3");
      });
    },
    insertInstructionCardsL4() {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var parentTitle = "";
      this.allInstructionCards.forEach((element) => {
        if (element.title === "General Information L4") {
          parentTitle = "General Information L3";
        }
        if (element.title === "Single Person Mode L4") {
          parentTitle = "Single Person Mode L3";
        }
        if (element.title === "Multi Person Mode L4") {
          parentTitle = "Multi Person Mode L3";
        }
        if (parentTitle != "" && element.status === "create") {
          axios
            .post(apiUrl, {
              request: "createCard",
              parentID: this.getInstructionIDByTitle(parentTitle),
              gameID: this.$store.getters.getSelectedGame.id.toString(),
              title: element.title,
              description: element.description,
              deckType: element.deckType,
              color: element.color,
            })
            .then(function(response) {
              console.log(response);
            })

            .catch(function(err) {
              console.log(err);
            });
          parentTitle = "";
          element.status = "";
        }
      });
    },
    getPosition(level) {
      //returns position of given level
      if (level === this.top) {
        return "TOP";
      }
      if (level === this.back) {
        return "BACK";
      }
      if (level === this.mid) {
        return "MID";
      }
      if (level === this.bottom) {
        return "BOTTOM";
      }
      return "INVISIBLE";
    },
    updateGame() {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var selectedGame = this.$store.getters.getSelectedGame;
      const self = this;
      axios
        .post(apiUrl, {
          request: "updateGame",
          gameID: selectedGame.id,
          gameName: selectedGame.gameName,
          description: selectedGame.description,
          L1Location: self.getPosition("Text"),
          L2Location: self.getPosition("L2"),
          L3Location: self.getPosition("L3"),
          L4Location: self.getPosition("L4"),
          generationLevel: self.getGenerationLevel(),
        })
        .then(function(response) {
          console.log(response);
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    getGenerationLevel() {
      if (this.$store.getters.getL2Nodes.length == 0) {
        return "Text";
      }
      if (this.$store.getters.getL3Nodes.length == 0) {
        return "Text";
      }
      if (this.$store.getters.getL4Nodes.length == 0) {
        return "L2";
      }
      return "L3";
    },
    createGame() {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var selectedGame = this.$store.getters.getSelectedGame;
      const self = this;
      axios
        .post(apiUrl, {
          request: "createGame",
          gameName: selectedGame.gameName,
          description: selectedGame.description,
          L1Location: self.getPosition("L1"),
          L2Location: self.getPosition("L2"),
          L3Location: self.getPosition("L3"),
          L4Location: self.getPosition("L4"),
          generationLevel: self.getGenerationLevel(),
        })
        .then(function(response) {
          self.$store.commit("updateSelectedGame", response.data[0]);
          self.insertAllCards("L1");
          self.insertInstructionCards();
        })
        .catch(function(err) {
          console.log(err);
        });
    },
    insertAllCards(highestLevel) {
      //highestLevel is the highest level where the changes is made to avoid extra for loops and start chain from that level
      if (highestLevel === "L4") {
        this.insertL4Cards();
      } else if (highestLevel === "L3") {
        this.insertL3Cards();
      } else if (highestLevel === "L2") {
        this.insertL2Cards();
      } else if (highestLevel === "L1") {
        this.insertL1Cards();
      }
    },
    getParentTitle(parentLevel, parentID) {
      var returnValue = "";
      if (parentLevel === "L1") {
        this.$store.getters.getL1Nodes.forEach((element) => {
          if (parentID === element.id) {
            returnValue = element.title;
          }
        });
      } else {
        if (parentLevel === "L2") {
          this.$store.getters.getL2Nodes.forEach((element) => {
            if (parentID === element.id) {
              returnValue = element.title;
            }
          });
        } else {
          if (parentLevel === "L3") {
            this.$store.getters.getL3Nodes.forEach((element) => {
              if (parentID === element.id) {
                returnValue = element.title;
              }
            });
          }
        }
      }
      return returnValue;
    },
    submitCardViewSettings() {
      // adds all parent titles if copy deck option is selected sets status of all nodes to create then calls createGame method
      this.loading = true;
      if (
        this.top != "" ||
        this.bottom != "" ||
        this.mid != "" ||
        this.back != ""
      ) {
        //var self = this;
        //self.addParentTitles("L2");
        //self.addParentTitles("L3");
        //self.addParentTitles("L4");
        this.allInstructionCards = this.$store.getters.getInstructionCards;
        if (!this.$store.getters.getCreateNewGame) {
          if (!this.$store.getters.getEditGame) {
            var instructionTempArray = this.$store.getters.getInstructionCards;
            instructionTempArray.forEach((element) => {
              element.status = "create";
            });
            this.$store.commit("updateInstructionCards", instructionTempArray);
            var tempArray = this.$store.getters.getL1Nodes;
            this.$store.commit("updateHighestChangedLayer", "L1");
            tempArray.forEach((element) => {
              element.status = "create";
            });
            this.$store.commit("updateL1Nodes", tempArray);
            tempArray = this.$store.getters.getL2Nodes;
            tempArray.forEach((element) => {
              element.status = "create";
            });
            this.$store.commit("updateL2Nodes", tempArray);
            tempArray = this.$store.getters.getL3Nodes;
            tempArray.forEach((element) => {
              element.status = "create";
            });
            this.$store.commit("updateL3Nodes", tempArray);
            tempArray = this.$store.getters.getL4Nodes;
            tempArray.forEach((element) => {
              element.status = "create";
            });
            this.$store.commit("updateL4Nodes", tempArray);
            this.createGame();
          } else {
            var highestLevel = this.$store.getters.getHighestChangedLayer;
            this.insertAllCards(highestLevel);
            this.insertInstructionCards();
            this.updateGame();
          }
        } else {
          this.createGame();
        }
      } else {
        alert("PLEASE CHOOSE LAYER POSITIONS");
        return;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to loggedIn
    next((vm) => {
      if (vm.getLoggedIn == false) {
        console.log("Accessed view all page without initializing a game.");
        vm.$router.push("/loggedIn");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
#header {
  text-align: left;
  margin-top: 20px;
}
#inlineFormCustomSelectPref {
  height: 100%;
  margin-top: 0%;
}
#inlineFormCustomSelectPref2 {
  height: 100%;
}
#inlineFormCustomSelectPref3 {
  height: 100%;
}
#inlineFormCustomSelectPref4 {
  height: 100%;
}
.btn {
  margin-top: 1%;
  margin: 1%;
}
.card-size {
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.5);
}
.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}
</style>
