<template>
  <div class="cardGenerationLevel">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards />
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pl-4 pr-4">
            <div class="header col-12 col-md-12 pt-3">
              <div>
                <div class="headline border-bottom border-dark mb-3">
                  <h1> {{ texts.headerText[getCurrentLocale] }} </h1>
                </div>
                <p>
                  {{ texts.informationText[getCurrentLocale] }}
                </p>
                <div style="width: 100%; display: flex; justify-content: center">
                  <form>
                    <div class="form-row">
                      <div class="form-group col">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                               value="L1" v-model="selectedLayout" key="form-input-1">
                        <label class="form-check-label" for="inlineRadio1">
                          <SettingsPlaycard
                            class="card-layout"
                            :mid="texts.cardPlaceholderText[getCurrentLocale]"
                          />
                        </label>
                      </div>
                      <div class="form-group col">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                               value="L2" v-model="selectedLayout" key="form-input-2">
                        <label class="form-check-label" for="inlineRadio2">
                          <SettingsPlaycard
                            class="card-layout"
                            :top="texts.cardPlaceholderText[getCurrentLocale]"
                            :bottom="texts.cardPlaceholderText[getCurrentLocale]"
                          />
                        </label>
                      </div>
                      <div class="form-group col">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3"
                               value="L4" v-model="selectedLayout" key="form-input-3">
                        <label class="form-check-label" for="inlineRadio3">
                          <SettingsPlaycard
                            class="card-layout"
                            :top="texts.cardPlaceholderText[getCurrentLocale]"
                            :mid="texts.cardPlaceholderText[getCurrentLocale]"
                            :bottom="texts.cardPlaceholderText[getCurrentLocale]"
                          />
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="alert alert-danger alert-dismissible fade show" role="alert" id="infoAlert">
                  {{ texts.alertText[getCurrentLocale] }}
                </div>
                <button type="submit" class="btn btn-lg secondary-action-btn" @click="goBack">
                  {{ texts.goBackButtonText[getCurrentLocale] }}
                </button>
                <button type="next" class="btn btn-lg primary-action-btn" @click="submitFirstSettings">
                  {{ texts.goForwardButtonText[getCurrentLocale] }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "../components/TabCreateCards.vue";
import SettingsPlaycard from "../components/SettingsPlaycard.vue";
import $ from "jquery";
import axios from "axios";

export default {
  name: "cardGenerationLevel",
  components: {
    Navbar,
    TabCreateCards,
    SettingsPlaycard
  },
  data() {
    return {
      texts: {
        headerText: {
          en: "Choose Your Card Layout",
          de: "Wähle dein Kartenlayout"
        },
        informationText: {
          en: "Note: the card layout cannot be changed once submitted",
          de: "Info: Das Layout kann nicht mehr geändert werden"
        },
        goBackButtonText: {
          en: "Back",
          de: "Zurück"
        },
        goForwardButtonText: {
          en: "Save and Continue",
          de: "Speichern und Fortfahren"
        },
        cardPlaceholderText: {
          en: "YOUR TEXT HERE",
          de: "HIER STEHT DEIN TEXT"
        },
        alertText: ""
      }
    };
  },
  computed: {
    selectedLayout: {
      get() {
        return this.$store.getters.getSelectedGame.generationLevel;
      },
      set(newValue) {
        this.$store.commit("updateGenerationLevel", newValue);
      }
    },
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
    getLoggedIn() {
      return this.$store.getters.getLoggedIn;
    },
    isBackDisabled() {
      return (!this.$store.getters.getSelectedGame.generationLevel == "L3" || !this.$store.getters.getSelectedGame.generationLevel == "L4");
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    }
  },
  mounted() {
    //if a layout was already chosen and submitted, the options are disabled
    if ((this.$store.getters.getCreateNewGame && this.$store.getters.getFirstSettingsSubmitted)
      || this.$store.getters.getEditGame) {
      this.disableOptions();
    }
    $("#infoAlert").hide();

  },
  methods: {
    disableOptions() {
      $("#inlineRadio1").prop("disabled", true);
      $("#inlineRadio2").prop("disabled", true);
      $("#inlineRadio3").prop("disabled", true);
      $("#inlineRadio3").prop("disabled", true);
      $(".card-layout").css({ "cursor": "default" });
    },
    goBack() {
      this.$router.push("cardCategoriesSetting");
    },
    async submitFirstSettings() {
      if (!this.checkFirstSettings()) {
        $("#infoAlert").show();
        return;
      }

      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      var selectedGame = this.$store.getters.getSelectedGame;
      this.setLNodes(selectedGame);

      if (this.$store.getters.getCreateNewGame) {
        axios
          .post(apiUrl, {
            request: "createGame",
            gameName: selectedGame.gameName,
            description: selectedGame.description,
            L1: selectedGame.L1,
            L2: selectedGame.L2,
            L3: selectedGame.L3,
            L4: selectedGame.L4,
            generationLevel: selectedGame.generationLevel
          })
          .then((response) => {
            selectedGame.id = response.data; //set id
            //this.$router.push("instructionsSettings");
            this.$store.commit("updateSelectedGame", selectedGame);
            this.handleCategorySaving();
            this.$store.commit("updateFirstSettingsSubmitted", true);
          })
          .catch((err) => {
            console.log(err);
          });

      } else if (this.$store.getters.getEditGame) {
        axios
          .post(apiUrl, {
            request: "updateGame",
            gameID: selectedGame.id,
            gameName: selectedGame.gameName,
            description: selectedGame.description,
            L1: selectedGame.L1,
            L2: selectedGame.L2,
            L3: selectedGame.L3,
            L4: selectedGame.L4,
            generationLevel: selectedGame.generationLevel
          })
          .then((response) => {
            console.log(response.data);
          })
          .catch((err) => {
            console.log(err);
          });

        //update deck split setting if changed from 2 decks to 1
        if (!this.$store.getters.isDeckSplit && this.$store.getters.getCardDeck[1].length > 0) {
          var cardsInDeck2 = this.$store.getters.getCardDeck[1];
          var promises = [];
          for (var card of cardsInDeck2) {
            for (var node of card) {
              promises.push(this.updateDeckSplitSetting(node, selectedGame.id));
            }
          }
          Promise.all(promises)
            .then(() => {
              this.handleCategorySaving();
              this.$store.commit("updateDeckSplit", false);
              console.log("Successfully updated cards");
            });
        } else {
          this.handleCategorySaving();
        }
      } else {
        console.error("undefined card adding/editing modus");
      }

    },
    handleCategorySaving() {
      var categories = this.$store.getters.getFrameworkCategories;
      var gameID = this.$store.getters.getSelectedGame.id;
      var promises = [];
      for (var category of categories) {
        if (Object.prototype.hasOwnProperty.call(category, "modification")) { //the category has been modified
          switch (category.modification) {
            case "ADDED":
              promises.push(this.insertCategory(category, gameID));
              break;
            case "UPDATED":
              promises.push(this.updateCategory(category));
              break;
            case "DELETED":
              promises.push(this.deleteCategory(category));
              break;
          }
        }
        ;
      }
      Promise.all(promises)
        .then(() => {
          this.reloadDeck();
          this.reloadCategories();
          this.$router.push("instructionsSettings");
        });
    },
    reloadDeck() {
      const apiUrl = API_URL; // eslint-disable-line
      axios
        .post(apiUrl, {
          request: "getCards",
          game: this.$store.getters.getSelectedGame.id
        })
        .then((response) => {
          this.$store.commit("updateCardDeck", response.data);
          console.log("cards loaded in storage");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reloadCategories() {
      //load card categories
      const frameworkSettingsUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(frameworkSettingsUrl, {
          request: "getCategories",
          gameID: this.$store.getters.getSelectedGame.id
        })
        .then((response) => {
          this.$store.commit("updateFrameworkCategories", response.data);
          console.log("card categories loaded in storage");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkFirstSettings() {
      //returns true if every setting is filled out correctly
      var selectedGame = this.$store.getters.getSelectedGame;
      if (!selectedGame.gameName && !selectedGame.description) {
        this.texts.alertText = {
          en: "Please enter a name and description for your deck to continue.",
          de: "Bitter gib einen Namen und eine Beschreibung für dein Kartendeck ein."
        };
      } else if (!selectedGame.gameName) {
        this.texts.alertText = {
          en: "Please enter a name for your deck to continue.",
          de: "Bitter gib einen Namen für dein Kartendeck ein."
        };
      } else if (!selectedGame.description) {
        this.texts.alertText = {
          en: "Please enter a description for your deck to continue.",
          de: "Bitter gib eine Beschreibung für dein Kartendeck ein."
        };
      }
      return (!this.texts.alertText); //no error detected
    },
    setLNodes(selectedGame) {
      //sets the text positions of the game
      switch (selectedGame.generationLevel) {
        case ("L1"):
          selectedGame.L1 = "MID";
          selectedGame.L2 = "INVISIBLE";
          selectedGame.L3 = "INVISIBLE";
          selectedGame.L4 = "INVISIBLE";
          break;
        case ("L2"):
          selectedGame.L1 = "TOP";
          selectedGame.L2 = "BOTTOM";
          selectedGame.L3 = "INVISIBLE";
          selectedGame.L4 = "INVISIBLE";
          break;
        case ("L3"):
          selectedGame.L1 = "TOP";
          selectedGame.L2 = "MID";
          selectedGame.L3 = "BOTTOM";
          selectedGame.L4 = "INVISIBLE";
          break;
        case ("L4"):
          selectedGame.L1 = "TOP";
          selectedGame.L2 = "MID";
          selectedGame.L3 = "BOTTOM";
          selectedGame.L4 = "BACK";
          break;
        default:
          console.log("unknown generation level");
      }
    },
    //methods, which make requests to the database
    updateDeckSplitSetting(node, gameID) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(apiUrl, {
          request: "updateCard",
          cardID: node.id,
          gameID: gameID,
          title: node.title.toString(),
          description: node.description.toString(),
          color: node.color.toString(),
          deckType: "deck1"
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async insertCategory(category, gameID) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      try {
        const response = await axios
          .post(apiUrl, {
            request: "createCategory",
            gameID: gameID,
            name: category.name,
            description: category.description
          });
        category.id = response.data; //setting the id from the database
        return response.data;
      } catch (err) {
        console.log(err);
      }
    },
    updateCategory(category) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(apiUrl, {
          request: "updateCategory",
          name: category.name,
          categoryID: category.id,
          description: category.description
        })
        .then(() => {
          console.log("successfully updated category");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteCategory(category) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      axios
        .post(apiUrl, {
          request: "deleteCategory",
          categoryID: category.id
        })
        .then(() => {
          console.log("successfully deleted category");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to loggedIn
    next((vm) => {
      if (!vm.getLoggedIn || !(vm.getCreateNewGame || vm.getEditGame)) {
        console.log("Accessed view all page without initializing a game.");
        vm.$router.push("/create");
      }
    });
  }
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}

#header {
  margin-top: 5% !important;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.btn {
  margin: 1%;
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
  background: rgb(53, 52, 52);
  color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}

.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}

.card-size {
  margin: 20px auto;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.5);
}

.form-group {
  margin-left: 20px;
}

</style>