<!--
Author: Patrick Gmasz 
Changes to original made by Katharina Hoeldrich

This is the Home page of the website.
-->
<template>
  <div class="home">
    <navbar pos="Design Cards To Go" />
    <div class="container">
      <div class="row justify-content-center mb-3 pl-4 pr-4">
        <div class="header col-12 col-md-7 pt-4">
          <div class="headline border-bottom border-dark mb-2 ">
            <h1>Design Cards To Go</h1>
          </div>
          <p>{{ descriptionText[getCurrentLocale] }}</p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-8 col-md-6 card-stack-pos">
          <router-link to="/playlist">
            <img
              src="@/assets/card_logo.png"
              class="img-fluid img-max"
              data-toggle="tooltip"
              data-placement="top"
              v-bind:title="startPlayingText[getCurrentLocale]"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "Home",
  data() {
    return {
      //returns the text in the language that is currently picked
      descriptionText: {
        en: "Start thinking, anytime, anywhere!",
        de: "Start thinking, anytime, anywhere!",
      },
      startPlayingText: {
        en: "Start playing!",
        de: "Fange gleich an zu spielen!",
      },
    };
  },
  components: {
    Navbar,
  },
  computed: {
    //Returns the language of the text (german or english)
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
};
</script>

<style scoped lang="scss">
.about,
.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.about::before,
.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.8;
  content: "";
  z-index: -1;
  background: rgb(252, 248, 241);
}

.card-stack-pos {
  position: fixed;
  top: 45%;
}

.img-max {
  max-width: 400px;
  width: 110%;
}

.card-stack-pos:hover {
  transform: scale(1.02);
}
</style>
