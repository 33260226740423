<!-- Help Page showing Design Card Tutorial -->
<template>
  <div class="container">
    <navbar pos="Design Cards To Go" />

    <main
      class="d-flex justify-content-center mb-3 pl-4 pr-4 ml-1 mr-1"
      style="padding-top: 10px;"
    >
      <!-- Tutorial in German -->
      <div v-if="getCurrentLocale == 'de'">
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">TMAP Design Karten</h1>
        </header>
        <!-- Carousel Tutorial German-->
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style="width: 100%; padding: 10px;"
          data-interval="0"
        >
          <ol
            class="carousel-indicators"
            style=" margin-bottom:1%; margin-top:99%;"
          >
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
          </ol>
          <div class="carousel-inner" style="margin-bottom: 20px;">
            <!-- Carousel Page 1 -->
            <div class="carousel-item active">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->
                <p class="paragraph">
                  Die TMAP (technology-mediated audience
                  participation)-Designkarten sind ein Tool um spielend
                  <b>Ideen</b> zu entwickeln. Die Ideen dienen dazu, bei
                  <b>Veranstaltungen mit Live-Musik</b> das
                  <b>Publikum möglichst gut einzubinden</b>. Gesucht sind Ideen,
                  die den Einsatz von <b>technischen Hilfsmitteln</b> erfordern.
                  <a href="http://www.drhoedl.com/">Oliver Hödl</a>
                  hat diese Karten erfunden und erklärt sie in diesem Video:
                  <br />
                  <br />
                  <a href="https://www.youtube.com/watch?v=Foz_YU2A-MA">
                    <!-- Photo used from www.drhoedl.com -->
                    <img
                      src="@/assets/tutorialScreenshots/design-cards.jpg"
                      alt="Picture of TMAP Design Cards"
                      class="image-link"
                  /></a>
                  <br />
                </p>
              </div>
            </div>
            <!-- Carousel Page 2-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->
                <div class="row">
                  <img src="@/assets/lila.png" class="card" />
                  <img src="@/assets/rot.png" class="card" />
                  <img src="@/assets/grün.png" class="card" />
                  <img src="@/assets/blau.png" class="card" />
                  <img src="@/assets/gelb.png" class="card" />
                </div>

                <p class="paragraph">
                  Das Deck ist unterteilt in eine lila-farbene Anleitungskarte,
                  in rote Rollenkarten, grüne Interaktionskarten, blaue
                  Effektkarten und gelbe Motivationskarten. Jede Karte
                  entspricht dabei einem anderen Aspekt, der wichtig ist, um das
                  Publikum in die Show einzubinden. Das Spiel kann alleine oder
                  in einer Gruppe gespielt werden.
                </p>
              </div>
            </div>
            <!-- Carousel Page 3-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;"><b>Vorbereitung</b></h2>
                <p class="paragraph">
                  Um das Spiel zu spielen, sollte an eine
                  <b>Ausgangssituation</b>
                  mit Live-Musik gedacht werden: <br />
                  Beispiele dafür könnten ein Orchesterauftritt bei einem
                  Konzert, ein DJ-Auftritt bei einem Elektrofestival oder ein
                  Chorauftritt in der Kirche sein. Überlegen Sie sich am besten
                  wo und mit wie vielen Leuten die Veranstaltung stattfindet.
                  <br />
                  Du solltest auch <b>Stifte und Papier</b> bereithalten, um
                  deine Ideen notieren zu können.
                </p>
              </div>
            </div>
            <!-- Carousel Page 4-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;"><b>Spielanleitung</b></h2>
                <p class="paragraph">
                  Zu Beginn jeder Runde bekommt man <b>zwei Karten</b>. Eine der
                  Karten ist eine <b>Rollenkarte (rot)</b>, die zweite Karte
                  (grün, blau oder gelb) enthält die jeweilige <b>Aufgabe</b>.
                  Die Aufgaben sind absichtlich sehr offen formuliert, um die
                  Kreativität nicht einzuschränken und ihr freien Lauf zu
                  lassen. Das Lesen der Vorderseite sollte immer zuerst
                  geschehen, die Hinweise auf der Rückseite sollen nur verwendet
                  werden, wenn dies notwendig ist. <br />
                  Es können beliebig viele Runden gespielt werden, indem man
                  neue Karten zieht. Die vorherigen Ideen zur Ausgangssituation
                  können so erweitert oder von einem anderen Blickwinkel
                  betrachtet werden. Es können aber auch ganz neue Ideen
                  entstehen. Wir empfehlen mindestens drei Runden zu spielen.
                </p>
                <p class="paragraph">
                  Versetze dich nun in die Rolle, die auf der roten Karte zu
                  finden ist und versuche sie mit der Ausgangssituation auf der
                  zweiten Karte in Verbindung zu bringen. Das Ziel ist es neue
                  Ideen im Bezug auf die zuvor überlegte Ausgangssituation zu
                  finden. Gesucht sind Ideen, die das Publikum mit Hilfe von
                  technischen Hilfsmitteln in das Live-Musik-Event einbinden.
                  Lass dir gerne etwas Zeit und notiere deine Ideen.
                </p>
              </div>
            </div>
            <!-- Carousel Page 5-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;"><b>Beispiel</b></h2>
                <p class="paragraph">
                  Eine mögliche Idee die man in dem untigen Beispiel haben
                  könnte, wäre dass TeilnehmerInnen sich mit dem Smartphone per
                  App beteiligen indem sie über den nächsten Song abstimmen.
                  Zudem könnte man klatschen um Zustimmung für einen Vorschlag
                  zu zeigen.
                </p>
                <div class="row justify-content-center">
                  <img src="@/assets/rolle.png" class="bigcard" />
                  <img src="@/assets/aufgabe.png" class="bigcard" />
                </div>
              </div>
            </div>
            <!-- Carousel Page 6-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <h2 style="text-align: left;">
                  <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                  <b>Erweiterungen und Hinweise</b>
                </h2>
                <p class="paragraph">
                  <b>Gruppenmodus:</b> <br />
                  Beim Spielen in einer Gruppe drücken alle SpielerInnen
                  gleichzeitig auf "Spielen". Somit bekommt jeder
                  unterschiedliche Karten und denkt zuerst selbstständig über
                  diese nach. Nach der Denkphase gibt es eine offene Diskussion,
                  in der alle SpielerInnen ihre Ideen der Reihe nach teilen.
                  <br />
                  Bei einer Gruppengröße von sechs oder mehr Personen empfehlen
                  wir, kleinere Gruppen von drei bis vier Personen zu bilden.
                </p>

                <p class="paragraph">
                  <b>Wichtig:</b><br />
                  Die Karten können nicht falsch verwendet werden! <br />
                  Es ist erwiesen, dass Tools wie Design Karten hilfreich sind,
                  um die Vorstellungskraft der NutzerInnen zu verbessern. Lass
                  der Kreativität also freien Lauf. Wenn die gezogenen Karten
                  dich auf keine Ideen bringen, kannst du einfach eine neue
                  Karte ziehen. <br />
                  Es kann auch passieren, dass die
                  <b>Rolle nicht optimal mit der Aufgabe zusammenpasst</b>. Das
                  kann leider nicht immer verhindert werden, da beide Karten
                  zufällig gezogen werden und es hier mehr als 300
                  Kombinationsmöglichkeiten gibt.
                </p>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              class="carousel-control-prev-icon carousel-button-color"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              class="carousel-control-next-icon carousel-button-color"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- Button back to Help-->
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Zurück
        </button>
      </div>
      <!-- Tutorial in English -->
      <div v-else>
        <header class="header headline border-bottom border-dark mb-2">
          <h1 style="font-size: 30px;">
            TMAP Design Cards
          </h1>
        </header>

        <!-- Carousel Tutorial English-->
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-ride="carousel"
          style="width: 100%; padding: 10px;"
          data-interval="0"
        >
          <ol
            class="carousel-indicators"
            style=" margin-bottom:1%; margin-top:99%;"
          >
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
          </ol>
          <div class="carousel-inner" style="margin-bottom: 20px;">
            <!-- Carousel Page 1 -->
            <div class="carousel-item active">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->
                <p class="paragraph">
                  The TMAP (technology-mediated audience participation) design
                  cards are a tool to playfully generate new
                  <b>ideas</b>. These ideas are meant to help
                  <b>include the audience</b> of a <b>Live-Music event</b> by
                  using <b>technical aids</b>.
                  <a href="http://www.drhoedl.com/">Oliver Hödl</a>
                  has invented these cards and explains them in this video:
                  <br />
                  <br />
                  <a href="https://www.youtube.com/watch?v=Foz_YU2A-MA">
                    <!-- Photo used from www.drhoedl.com -->
                    <img
                      src="@/assets/tutorialScreenshots/design-cards.jpg"
                      alt="Picture of TMAP Design Cards"
                      class="image-link"
                  /></a>
                  <br />
                </p>
              </div>
            </div>
            <!-- Carousel Page 2-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->
                <div class="row">
                  <img src="@/assets/lila.png" class="card" />
                  <img src="@/assets/rot.png" class="card" />
                  <img src="@/assets/grün.png" class="card" />
                  <img src="@/assets/blau.png" class="card" />
                  <img src="@/assets/gelb.png" class="card" />
                </div>

                <p class="paragraph">
                  The deck is divided into a purple instruction card, red role
                  cards, green interaction cards, blue impact cards and yellow
                  motivation cards. Each card presents a different aspect that
                  is supposed to be used to engage the audience of a live music
                  event. The game can be played alone or in a group.
                </p>
              </div>
            </div>
            <!-- Carousel Page 3-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;"><b>Preparation</b></h2>
                <p class="paragraph">
                  To play the game, you first have to think of a
                  <b>specific live music setting</b>: <br />
                  Examples could include an orchestra performance at a concert,
                  a DJ performance at an electric festival, or a choir
                  performance in church. Also think about the number of people
                  in the audience and where this event would be taking place.
                  <br />
                  We recommend having <b>pen and paper</b> ready to write down
                  your ideas.
                </p>
              </div>
            </div>
            <!-- Carousel Page 4-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;"><b>Game Rules</b></h2>
                <p class="paragraph">
                  In the beginning of each round <b>two cards</b> are dealt. One
                  of the two cards is the <b>red role card</b>, the second card,
                  which is yellow, green or blue shows the <b>task</b>. The text
                  on the task-cards is intentionally phrased very broadly so
                  that it does not limit the users creativity. Try to always
                  read the front first, only turn the card around for the
                  explanations on the back if you really need them.
                  <br />
                  We recommend playing at least three rounds, by drawing new
                  cards, however the number of rounds played is up to the
                  player. Playing three or more rounds can help generate new
                  ideas, expand prior ones or change the perspective on the
                  issues at hand.
                </p>
                <p class="paragraph">
                  Now try putting yourself into the role on the red card and set
                  it into contex together with the task-card and the inital
                  situation you thought of. Look for ideas that engage the
                  audience using technical tools. Take some time and write down
                  your ideas.
                </p>
              </div>
            </div>
            <!-- Carousel Page 5-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;"><b>Example</b></h2>
                <p class="paragraph">
                  A possible idea for the cards below could be for the audience
                  to vote for the next song using a smart phone application. In
                  addition the audience could clap to show their approval for a
                  song proposal.
                </p>
                <div class="row justify-content-center">
                  <img src="@/assets/role.png" class="bigcard" />
                  <img src="@/assets/task.png" class="bigcard" />
                </div>
              </div>
            </div>
            <!-- Carousel Page 6-->
            <div class="carousel-item">
              <div class="container tutorial-container">
                <!--This text was originally used in the HowToPlay.vue File and was modified by Katharina Hoeldrich-->

                <h2 style="text-align: left;">
                  <b>Extensions and Notes</b>
                </h2>
                <p class="paragraph">
                  <b>Group mode:</b><br />
                  When playing in a group, all players press "Play" at the same
                  time. Therefore every player has their own set of cards and
                  thinks about them independently. After some time has passed,
                  the players have an open discussion in which every player
                  compares their ideas one after another.
                  <br />
                  If the group size is six or more, we recommend forming smaller
                  groups of three or four people.
                </p>

                <p class="paragraph">
                  <b>Important:</b><br />
                  <b>The cards can not be used incorrectly!</b> It has been
                  proven that tools like these are helpful in improving the
                  imagination of users. So let your creativity run wild.
                  However, some cards may not help thinking of new ideas. In
                  this case, you can simply draw a new card. <br />
                  It can happen, that your
                  <b
                    >role card does not make sense in combination to your
                    task.</b
                  >
                  Unfortunately, this cannot be changed since both cards are
                  drawn randomly and there are more than 300 different
                  combination possibilities.
                </p>
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              class="carousel-control-prev-icon carousel-button-color"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              class="carousel-control-next-icon carousel-button-color"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

        <!-- Button back to Help -->
        <button class="btn btn-outline-secondary button-back" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Back To Help
        </button>
      </div>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";

export default {
  name: "HelpDesignCards",
  components: {
    Navbar,
  },
  data() {
    return {
      german: Boolean,
    };
  },
  methods: {
    goBack() {
      this.$router.push("/help");
    },
    changeLang() {
      if (this.german) this.german = false;
      else this.german = true;
    },
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
  },
  mounted() {
    this.german = true;
  },
};
</script>

<style scoped lang="scss">
.about,
.carousel-button-color {
  color: black;
}
.tutorial-container {
  width: 80% !important;
}
.paragraph {
  text-align: left;
  font-size: 18px;
}
.blockquote {
  font-size: 18px;
  color: rgb(56, 0, 0);
}

h2 {
  color: rgb(0, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  padding: 10px;
}
.header {
  position: relative;
  color: black;
  user-select: none;
}

.row {
  margin: 20px;
  align-content: center;
  text-align: center;
}

main {
  background: rgb(252, 248, 241);
  padding: 10px;
  margin-top: 5% !important;
}

.col {
  padding: 10px 0;
  text-align: center;
}

.button-back {
  margin-bottom: 10px;
}

.button-continue {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button-pos {
  position: absolute;
  bottom: 0;
  width: 80%;
}

.image {
  width: 300px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

//https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}
//https://stackoverflow.com/questions/46249541/change-arrow-colors-in-bootstraps-carousel
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.carousel-indicators .active {
  background-color: rgba(0, 0, 0, 0.274);
}

.image-link:hover {
  transform: scale(1.02);
}

.image-link {
  width: 300px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 5px solid #555;
}

.card {
  width: 100px;
  max-width: 100%;
  height: auto;
  margin: 10px;
  background: transparent;
  display: block;
  align-content: center;
}
.bigcard {
  width: 200px;
  max-width: 100%;
  height: auto;
  margin: 5px;
  background: transparent;
  display: block;
  align-content: center;
}

.header {
  margin-top: 100px;
  position: relative;
  color: black;
  user-select: none;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  content: "";
  z-index: -1;
  background: rgb(252, 248, 241);
}
</style>
