<template>
  <div class="nameSettings">
    <navbar pos="Design Cards To Go" />
    <div class="row">
      <div class="col-2">
        <div class="d-flex justify-content-center mb-3 pl-4 pr-4" id="tabCreateCards">
          <TabCreateCards/>
        </div>
      </div>
      <div class="col-10">
        <div class="container">
          <div class="row justify-content-center mb-3 pr-4">
            <div class="header col-12 col-md-12 pt-3">
              <div>
                <div class="headline border-bottom border-dark mb-3">
                  <h1>{{ texts.headerText[getCurrentLocale] }}</h1>
                </div>
                <p>{{ texts.informationText[getCurrentLocale] }}</p>
                <form>
                  <div class="form-group">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="oneDeck" value="oneDeck" v-model="deckSplit"/>
                        <label class="form-check-label" for="oneDeck">{{ texts.deckSplitOneDeckText[getCurrentLocale] }}</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" id="twoDeck" value="twoDeck" v-model="deckSplit"/>
                        <label class="form-check-label" for="twoDeck">{{ texts.deckSplitTwoDeckText[getCurrentLocale] }}</label>
                    </div>
                  </div>
                  <button type="button" class="btn btn-lg secondary-action-btn" @click="goBack">
                    {{ texts.goBackButtonText[getCurrentLocale] }}
                  </button>
                  <button type="button" class="btn btn-lg primary-action-btn" @click="goForward">
                   {{ texts.goForwardButtonText[getCurrentLocale] }}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import TabCreateCards from "@/components/TabCreateCards.vue";
//import axios from "axios";
export default {
  name: "NameSettings",
  components: {
    Navbar,
    TabCreateCards,
},
  data() {
    return {
      texts: {
        headerText: {
          en: "Decide, if You Want to Group the Card Deck Into Two Decks",
          de: "Wähle aus, ob dein Deck in zwei Stapel aufgeteilt werden soll"
        },
        informationText: {
          en: "Split up cards into two decks?",
          de: "Willst du dein Karten auf zwei Decks aufteilen?"
        },
        goBackButtonText: {
          en: "Back",
          de: "Zurück"
        },
        goForwardButtonText: {
          en: "Next",
          de: "Weiter"
        },
        deckSplitOneDeckText: {
          en: "No (one deck only)",
          de: "Nein (nur ein Deck)"
        },
        deckSplitTwoDeckText: {
          en: "Yes (two decks)",
          de: "Ja (zwei Decks)"
        }
      }
    };
  },
  computed: {
    deckSplit: {
      get() {
        if (this.$store.getters.isDeckSplit) {
          return "twoDeck";
        } else return "oneDeck";
      },
      set(newDeckSplit) {
        if (newDeckSplit === "twoDeck") {
          this.$store.commit("updateDeckSplit", true);
        } else this.$store.commit("updateDeckSplit", false);
      }
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    },
  },
  methods: {
      goBack() {
        this.$router.push("enterInformation");
      },
      goForward() {
        this.$router.push("cardCategoriesSetting");
      },
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to create
    next((vm) => {
      if (!vm.getLoggedIn || !(vm.getCreateNewGame|| vm.getEditGame)) {
          console.log("Accessed view all page without initializing a game.");
          vm.$router.push("/create");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
#header {
  margin-top: 5% !important;
}
.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.btn {
  margin: 1%;
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
    background: rgb(53, 52, 52);
    color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}
.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}
</style>
