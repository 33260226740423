<!--
Author: Patrick Gmasz

This is the bottombar, used in play and preview pages.
-->
<template>
  <nav class="navbar fixed-bottom navbar-expand-lg  main-nav"
       :class="[
        currentTheme === 'light' ? 'navbar-light' : 'navbar-dark'
    ]">
    <div class="order-first order-md-0 d-flex justify-content-center w-100">
      <!-- For Preview Page -->
      <template v-if="this.viewAll">
        <div class="btn-group dropup centerNav leftDiv">
          <button
            type="button"
            class="dropbtn btn dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Filter
          </button>
          <div class="dropdown-menu dropdown-menu-left">
            <template v-for="category in getCategories">
              <a
                class="dropdown-item"
                :key="category"
                @click="setFilter(category)"
              >
                {{ category }}</a
              >
            </template>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="setFilter('Instructions')">{{
                InstructionsText[getCurrentLocale]
              }}</a>
            <a class="dropdown-item" @click="setFilter('All')">{{
                AllText[getCurrentLocale]
              }}</a>
          </div>
        </div>
      </template>
      <!-- For Play Page -->
      <template v-else>
        <div class="centerNav leftDiv hideDiv  " @click="updateReload">
          <span class="nav float-right icon-text " role="button" id="text">
            {{ ShuffleText[getCurrentLocale] }}
          </span>
          <a class="icons bottombar-color" id="icon"><i class="fas fa-sync"></i></a>
        </div>
      </template>
      <div>
        <a class="carousel-control-prev"
           href="#carouselControls"
           role="button"
           data-slide="prev">
          <i class="fas fa-caret-left"></i>
        </a>
      </div>
      <!-- Current/All Card -->
      <div  id="cardText">
        {{ CardText[getCurrentLocale] }} {{ currentCard }} / {{ allCards }}
      </div>
      <div>
        <a class="carousel-control-next "
           href="#carouselControls"
           role="button"
           data-slide="next">
          <i class="fas fa-caret-right"></i>
        </a>
      </div>
      <!--Options-->
      <div class="centerNav rightDiv">
        <div class="hideDiv ">
            <span class="nav" @click="updateTurnAround" role="button" id="text">
            {{ TurnAroundText[getCurrentLocale] }} <!-- adds button to bottom right -->
          </span>
          <a class="icons float-left bottombar-color" @click="updateTurnAround">
            <i class="fas fa-undo"></i>
          </a>
        </div>
        <div class="btn-group dropup mobileDropDown">
          <div class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" @click="updateTurnAround"
            >{{ TurnAroundText[getCurrentLocale]
              }}<i class="fas fa-undo margin1"></i
              ></a>
            <template v-if="!this.viewAll">
              <a class="dropdown-item" @click="updateReload">
                {{ ShuffleText[getCurrentLocale] }}
                <i class="fas fa-sync margin1"></i
                ></a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "bottombar",
  props: {
    viewAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      CardText: {
        en: "Card",
        de: "Karte"
      },
      AllText: {
        en: "All",
        de: "Alle"
      },
      InstructionsText: {
        en: "Instructions",
        de: "Anleitung"
      },
      ShuffleText: {
        en: "New",
        de: "Neu"
      },
      TurnAroundText: {
        en: "Flip",
        de: "Umdrehen"
      },
      MoreText: {
        en: "More",
        de: "Mehr"
      },
      message: {
        en: "Info: New Card was generated",
        de: "Info: Neue Karte wurde generiert"
      },
      currentTheme: ""
    };
  },
  computed: {
    currentCard() {
      return this.$store.getters.getCurrentCard;
    },
    allCards() {
      return this.$store.getters.getAllCards;
    },

    getCategories() {
      var categories = this.$store.getters.getCategories;
      //if there is no title in the card
      if (categories.length === 1 && categories[0] === "") {
        return [];
      } else return categories;
    },
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    }
  },
  methods: {
    updateTurnAround() {
      this.$store.commit("updateTurnAround");
    },
    updateReload() {
      this.$store.commit("updateReload");
      const currentLocale = this.$store.getters.getCurrentLocale;
      const messageFromPopUp = this.message[currentLocale];
      this.$toasted.show(messageFromPopUp,
        {
          position: "top-center",
          duration: 2500
        });
    },
    setFilter(category) {
      this.$store.commit("updateFilter", category);
    },
    getCurrentTheme() {
      this.currentTheme = localStorage.getItem("theme");
    },
    setCurrentTheme(theme) {
      localStorage.setItem("theme", this.currentTheme);
    },
    toggleDarkMode() {
      this.setCurrentTheme();
      if (this.currentTheme === "light") {
        document.body.style.setProperty("--background-color", "#ffffff");
        return;
      }
      document.body.style.setProperty("--background-color", "#1e1e1e");

    }
  }
};
</script>

<style scoped lang="scss">
.margin1 {
  margin-left: 5px;
}

.navbar .flex-1 {
  flex: 1;
  flex-basis: 100%;
  user-select: none;
  background: var(--background-color);
}

.navbar-light {
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 1px;
}

.navbar-dark {
  border-bottom-color: white;
  border-style: solid;
  border-width: 1px;
}

.dropbtn:hover {
  color: white;
}

.dropbtn:focus {
  box-shadow: none;
}

.dropbtn {
  padding: 0;
}

.dropdown-menu {
  background: var(--background-color);
}

.dropdown-item {
  user-select: none;
  cursor: pointer;
  font-size: 24px;
  color: gray;
}

.dropdown-menu .dropdown-item:hover {
  background: #252525 !important;
  color: white;
}

.dropdown-item:active {
  background-color: #252525 !important;
}

.dropdown-toggle {
  color: gray;
}
.bottombar-color{
  color: gray;
}
.icons {
  vertical-align: sub;
  //margin-left: 10px;
  float: right;
  overflow: hidden;
}

.icons:hover {
  cursor: pointer;
}

#text {
  overflow: hidden;
  float: left;
  color: gray;
  margin-left: 10px;
  margin-right: 10px;
}

#cardText {
  color: gray;
  text-align: center;
  margin: 7px;
  min-width: 85px;
}

@media screen and (max-width: 450px) {

  .mobileDropDown {
    display: block !important;
  }
}

.mobileDropDown {
  display: none;
}

.centerNav {
  color: white;
  text-align: center;
  margin: 7px;
}

.leftDiv {
  position: absolute;
  left: 0;
  padding-left: 5px;
}

.rightDiv {
  position: absolute;
  right: 0;
  padding-right: 5px;
}

.carousel-control-prev {
  filter: invert(100%);
  font-size: 40px;
  position: relative;
  width: auto;
  color: gray;

}

.carousel-control-next {
  filter: invert(100%);
  font-size: 40px;
  position: relative;
  width: auto;
  color: gray;
}
</style>
