<!--
Author: Patrick Gmasz

This is the top navbar, which is shown on every page of the webapp.
-->
<template>
  <nav class="navbar fixed-top navbar-expand-sm"
       :class="[currentTheme === 'light' ? 'navbar-light' : 'navbar-dark']" >
    <div>
      <router-link class="navbar-brand" to="/"> {{ pos }}</router-link>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="navbar-nav text-left">
        <router-link class="nav-link" to="/playlist">
          <i class="fas fa-play"></i>
          {{ PlayText[getCurrentLocale] }}
        </router-link
        >
        <router-link class="nav-link" to="/create">
          <i class="fas fa-plus"></i>
          {{ CreateText[getCurrentLocale] }}
        </router-link
        >

        <!--
        <router-link class="nav-link" to="/howToPlay">
          <i class="fas fa-question-circle"></i>
          {{ HowToPlayText[getCurrentLocale] }}</router-link
        > -->
        <router-link class="nav-link" to="/help">
          <i class="fas fa-question-circle"></i>
          {{ HelpText[getCurrentLocale] }}
        </router-link>
        <router-link class="nav-link" to="/about">
          <i class="fas fa-info-circle"></i>
          {{ AboutText[getCurrentLocale] }}
        </router-link>

        <div class="nav-link" role="button" @click="changeLanguage">
          {{ languageText[getCurrentLocale] }}
        </div>

      </div>
      <div class="nav navbar ml-auto">
        <div v-if="isLoggedIn" class="dropdown">
          <a href="#" class="nav-link dropdown-toggle" id="dropdownUserLink" data-toggle="dropdown"
             aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-user"></i>
            User
          </a>
          <div class="dropdown-menu">
            <button class="dropdown-item" type="button" @click="goToMyCardDecks">
              {{ myCardDecksText[getCurrentLocale] }}
            </button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" type="button" @click="handleLogout">
              {{ logoutText[getCurrentLocale] }}
            </button>
          </div>
        </div>

        <!-- switch-->
        <div>
          <label class="switch" style="display: flex; align-items: center;">
            <input type="checkbox"
                   v-model="currentTheme"
                   true-value="light"
                   false-value="dark"
                   @change="toggleDarkMode">
            <span class="slider"></span>
            <span class="mode">
            <span style="color: black" v-if="currentTheme === 'light'">{{lightText[getCurrentLocale]}}</span>
            <span style="color: white" v-else>{{darkText[getCurrentLocale]}}</span>
          </span>
          </label>
        </div>

        <!-- switch-->
      </div>

    </div>

  </nav>

</template>

<script>

export default {
  name: "navbar",
  props: {
    pos: String
  },
  data() {
    return {
      logoutText: {
        en: "Logout",
        de: "Ausloggen"
      },
      myCardDecksText: {
        en: "My Card Decks",
        de: "Meine Kartendecks"
      },
      languageText: {
        en: "DE", //When language in EN and you want DE you more likely look for DE
        de: "EN"
      },
      PlayText: {
        en: "Play",
        de: "Spielen"
      },
      HowToPlayText: {
        en: "How To Play",
        de: "Anleitung"
      },
      AboutText: {
        en: "About",
        de: "Über uns"
      },
      HelpText: {
        en: "Help",
        de: "Hilfe"
      },
      CreateText: {
        en: "Create New Card Deck",
        de: "Neues Kartendeck erstellen"
      },
      lightText: {
        en: "Light",
        de: "Hell"
      },
      darkText: {
        en: "Dark",
        de: "Dunkel"
      },
      currentTheme: ""
    };
  },

  methods: {
    changeLanguage() {
      if (this.$store.getters.getCurrentLocale === "en")
        this.$store.commit("updateCurrentLocale", "de");
      else this.$store.commit("updateCurrentLocale", "en");
    },
    handleLogout() {
      this.$store.commit("updateLoggedIn", false);
      this.$router.push("/create");
    },
    goToMyCardDecks() {
      //routes to card creation site
      this.$router.push("/create");
    },
    getCurrentTheme() {
      this.currentTheme = localStorage.getItem("theme");
    },
    setCurrentTheme(theme) {
      localStorage.setItem("theme", this.currentTheme);
    },
    toggleDarkMode() {
      this.setCurrentTheme();
      if (this.currentTheme === "light") {
        document.body.style.setProperty("--background-color", "#ffffff");
        return;
      }
      document.body.style.setProperty("--background-color", "#1e1e1e");

    }
  },
  computed: {
    getCurrentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
    isLoggedIn() {
      return this.$store.getters.getLoggedIn;
    }
  },
  mounted() {
    this.getCurrentTheme();
  }
};
</script>

<style scoped lang="scss">
.navbar {
  user-select: none;
  background: var(--background-color);
}

.navbar-light {
  border-bottom-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 1px;
}

.navbar-dark {
  border-bottom-color: white;
  border-style: solid;
  border-width: 1px;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.my-dropdown-toggle {
  border-radius: 5px;

  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}

#dropdownUserLink {
  color: #c4c4c4;
}

.dropdown-item:active {
  outline: none;
  box-shadow: none;
  background-color: dimgrey;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 22px;
  right: 35%;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 5px;
  left: 0;
  right: 0;
  bottom: -1px;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
  top: 0.5px;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.mode{
  color: rgba(0,0,0,.5);
  position: absolute;
  left: 55px;
  top: 3px;
}

</style>
