<template>
  <div class="InstructionsSettings">
    <navbar pos="Design Cards To Go" />
      <div class="container">
        <div class="row justify-content-center mb-3 pl-4 pr-4">
        <div class="header col-12 col-md-12 pt-3">
          <div>
            <div class="headline border-bottom border-dark mb-3 ">
              <h1> {{ texts.headerText[getCurrentLocale] }} </h1>
            </div>
            <p>
              {{ texts.descriptionText[getCurrentLocale] }}
            </p>
            <div
              style="background-color: white; border-radius: 3px; padding: 1rem;"
            >
              
                <div
                v-for="instruction in instructions"
                :key="instruction.id"
                style="text-align: left; padding: 0.5rem 0;"
                class="option-item"
                @click="initalizeSelectedInstruction(instruction)"
              >
                <button
                  class="card-list btn btn-lg btn-block"
                  data-toggle="modal"
                  data-target="#modalAdd"
                  @click="initalizeSelectedInstruction(instruction)"
                >
                  <div>
                    <i class="far fa-edit"></i>
                  </div>
                  <p style="margin: 0 0 0 0.5rem; padding: 0;">
                    {{ instruction.title }}
                  </p>
                </button>
              </div>
            </div>
            <div class="alert alert-danger alert-dismissible fade show" role="alert" id="infoAlert">
              {{ texts.alertText[getCurrentLocale] }}
            </div>
            <button
              type="next"
              class="btn btn-lg primary-action-btn"
              @click="submitInstructionsSettings"
            >
              {{ texts.goForwardButtonText[getCurrentLocale] }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal in English-->
    <div v-if="getCurrentLocale == 'en'"
      class="modal fade"
      id="modalAdd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">
              
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <!-- <span aria-hidden="true">&times;</span> -->
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="nameInput">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  aria-describedby="nameHelp"
                  placeholder="Enter a name"
                  v-model="selectedInstruction.title"
                  disabled
                />
              </div> 
              <div class="form-group">
                <label for="descriptionInput">Description:</label>
                <div class="textBox">
                  <textarea
                    class="form-control"
                    id="descriptionInput"
                    rows="3"
                    placeholder="Enter a description"
                    v-model="selectedInstruction.description"
                  ></textarea>
                </div>
              </div>
              <div class="form__field">
                <div class="form__label"></div>
                <label>Pick a color:</label>
                <div class="form__input">
                  <v-swatches
                    v-model="cardColor"
                    show-fallback
                    fallback-input-type="color"
                    popover-x="right"
                  ></v-swatches>
                </div>
              </div>
            </form>
            <button
              type="button"
              class="btn btn-lg secondary-action-btn"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-lg primary-action-btn"
              @click="saveChanges"
            >
              Save
            </button>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <!-- Modal in German -->
    <div v-else
      class="modal fade"
      id="modalAdd"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title font-weight-bold">
              
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <!-- <span aria-hidden="true">&times;</span> -->
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="nameInput">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  id="nameInput"
                  aria-describedby="nameHelp"
                  placeholder="Enter a name"
                  v-model="selectedInstruction.title"
                  disabled
                />
              </div> 
              <div class="form-group">
                <label for="descriptionInput">Beschreibung:</label>
                <div class="textBox">
                  <textarea
                    class="form-control"
                    id="descriptionInput"
                    rows="3"
                    placeholder="Füge eine Beschreibung hinzu"
                    v-model="selectedInstruction.description"
                  ></textarea>
                </div>
              </div>
              <div class="form__field">
                <div class="form__label"></div>
                <label>Wähle eine Kartenfarbe:</label>
                <div class="form__input">
                  <v-swatches
                    v-model="cardColor"
                    show-fallback
                    fallback-input-type="color"
                    popover-x="right"
                  ></v-swatches>
                </div>
              </div>
            </form>
            <button
              type="button"
              class="btn btn-lg secondary-action-btn"
              data-dismiss="modal"
              aria-label="Close"
              id="closeModal"
            >
              Abbrechen
            </button>
            <button
              type="submit"
              class="btn btn-lg primary-action-btn"
              @click="saveChanges"
            >
              Speichern
            </button>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar.vue";
import VSwatches from "vue-swatches";
import $ from "jquery";
import axios from "axios";
export default {
  name: "InstructionsSettings",
  components: {
    Navbar,
    VSwatches,
  },
  data() {
    return {
      texts: {
        headerText: {
          en: "Write Your Instruction Cards",
          de: "Schreibe deine Anleitungskarten"
        },
        descriptionText: {
          en: "Select all options and write a short description:",
          de: "Wähle hierzu alle Optionen und beschreibe sie mit mindestens 10 Buchstaben:"
        },
        goForwardButtonText: {
          en: "Save and Start Adding Cards",
          de: "Speichern und Karten Hinzufügen"
        },
        alertText: {
          en: "Please write all of your instruction cards to continue.",
          de: "Bitte schreibe alle Anleitungskarten um fortzufahren."
        },
      },
      cardColor: "#000000", //default card color for VSwatches form field
      instructions: [
        { title: "General Information", id: "General Information" , description: "" , color: ""},
        { title: "Multi Person Mode", id: "Multi Person Mode" , description: "" , color: ""},
        { title: "Single Person Mode", id: "Single Person Mode" , description: "" , color: ""},
      ],
      selectedInstruction: "",
      instructionCards: [],
    };
  },
  computed: {
    getCurrentLocale() {
      //checks which language is currently set
      return this.$store.getters.getCurrentLocale;
    },
    getLoggedIn() {
      //checks if the user successfully logged in
      return this.$store.getters.getLoggedIn;
    },
    getCreateNewGame() {
      return this.$store.getters.getCreateNewGame;
    },
    getEditGame() {
      return this.$store.getters.getEditGame;
    }
  },
  mounted() {
   if(this.$store.getters.getEditGame) {
      //initalize instructions from cards
      this.instructionCards = this.$store.getters.getCardDeck[2];
      var generationLevel = this.$store.getters.getSelectedGame.generationLevel;
      var descriptionPosition = ""; //determines where the description is placed in the nodes
      if (generationLevel === "L1") {
        descriptionPosition = 0;
      } else {
        descriptionPosition = 1;
      }
      for (var instructionCard of this.instructionCards) {
        var instruction = this.instructions.find(instruction => instruction.id === instructionCard[descriptionPosition].title);
        instruction.description = instructionCard[descriptionPosition].description;
        this.cardColor = '#' + instructionCard[descriptionPosition].color;
      }
    } else if (!this.$store.getters.getCreateNewGame) {
      console.log("unknown modus, must be create or edit modus");
    }

    //hide info alert first
    $('#infoAlert').hide(); 

    $('#modalAdd').on('shown.bs.modal', function () {
      $('#infoAlert').hide();
    });
  },
  methods: {
    generateInstructionCard(title, description, color) {
      var generationLevel = this.$store.getters.getSelectedGame.generationLevel;
      var card = [];
      switch (generationLevel) {
        case "L1":
          card.push({title: title, description: description, color: color});
          break;
        case "L2":
          card.push({title: title, description: title, color: color});
          card.push({title: title, description: description, color: color});
          break;
        case "L3":
          card.push({title: title, description: title, color: color});
          card.push({title: title, description: description, color: color});
          card.push({title: title, description: "", color: color});
          break;
        case "L4":
          card.push({title: title, description: title, color: color});
          card.push({title: title, description: description, color: color});
          card.push({title: title, description: "", color: color});
          card.push({title: title, description: "", color: color});
          break;
        default:
          console.log("generation level not found");
      }
      return card;
    },
    initalizeSelectedInstruction(instruction) {
      //initialize values of modal with a copy to prevent auto save
      this.selectedInstruction = Object.assign({}, this.instructions.find(element => element.id === instruction.id));
    },
    saveChanges() {
      //replace the description and color in instructions
      let instruction = this.instructions.find(element => element.id === this.selectedInstruction.id)
      instruction.description = this.selectedInstruction.description;
      $("#modalAdd").modal("hide");
    },
    async submitInstructionsSettings() {
      //check input values of instructions
      if (!this.checkInstructions()) {
        $('#infoAlert').show();
        return;
      }

      if (this.$store.getters.getEditGame) {
        //delete old nodes from db
        const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
        for (var card of this.instructionCards) {
          for (var node of card) {
            axios
            .post(apiUrl, {
              request: "deleteNode",
              id: node.id,
            })
            .catch((err) => {
              console.log(err)
            })
          }
        }
      }
      var instructionCards = []
      for(var instruction of this.instructions) {
        instructionCards.push(this.generateInstructionCard(instruction.title, instruction.description, this.cardColor.substring(1)));
      }

      for(card of instructionCards) {
        var parentID = "";
        for (node of card) {
           parentID = await this.insertNodeInDatabase(node, parentID);
        }
      }

      this.$router.push("cardPreview");
    },
    async insertNodeInDatabase(node, parentID) {
      const apiUrl = VUE_APP_FRAMEWORK_URL; // eslint-disable-line
      try {
        const response = await axios
          .post(apiUrl, {
            request: "createCard",
            parentID: parentID,
            gameID: this.$store.getters.getSelectedGame.id,
            title: node.title.toString(),
            description: node.description.toString(),
            color: node.color.toString(),
            deckType: "instruction",
          });
          return response.data;
      } catch(err) {
        console.log(err);
      }
    },
    checkInstructions() {
      for (var instruction of this.instructions) {
        if (!instruction.description) {
          return false;
        }
      }
      return true;
    },
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it will route to create
    next((vm) => {
      if (!vm.getLoggedIn && !(vm.getCreateNewGame|| vm.getEditGame)) {
          console.log("Accessed view all page without initializing a game.");
          vm.$router.push("/create");
      }
    });
  },
};
</script>

<style scoped lang="scss">
#tabCreateCards {
  margin-top: 10%;
}
.option-item {
  &:hover {
    cursor: pointer;
  }
}

.active {
  background-color: rgb(217, 217, 255);
}

#header {
  margin-top: 20px;
}
.form-control {
  height: 200%;
}
.btn {
  margin-top: 1%;
  margin: 1%;
}

.header {
  position: relative;
  color: rgb(0, 0, 0);
  user-select: none;
  z-index: 2;
}

.header::before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: "";
  z-index: -1;
  background: rgba(252, 248, 241, 0.95);
}

.card-list {
  display: inline-flex !important;
}

.primary-action-btn {
  background: rgb(18, 18, 18);
  color: white;
}

.primary-action-btn:hover {
    background: rgb(53, 52, 52);
    color: white;
}

.secondary-action-btn {
  background: transparent;
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);

}
.secondary-action-btn:hover {
  background: rgb(241, 240, 240);
  border-color: rgb(71, 71, 71);
  color: rgb(71, 71, 71);
}
</style>