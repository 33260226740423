<!--
Author: Patrick Gmasz

This is the Deck Preview page of the website.
-->
<template>
  <div class="play">
    <navbar pos="Design Cards To Go" />
    <div class="d-flex justify-content-center">
      <div class="cardcol">
        <div class="d-flex justify-content-center h-100">
          <div
            class="card-box d-flex align-items-center justify-content-center"
          >
            <div class="p-1">
              <!-- Show play card(s) -->
              <div
                id="carouselControls"
                class="carousel slide"
                data-ride="carousel"
                data-interval="false"
              >
                <template v-if="!filter">
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <template v-if="!this.playBacks[0]">
                        <!-- Front side of card 1 -->
                        <div @click="swapPlay(0)">
                          <playcard
                            :top="getCardText('TOP', 0)"
                            :mid="getCardText('MID', 0)"
                            :bottom="getCardText('BOTTOM', 0)"
                            :top_color="getCardColor('TOP', 0)"
                            :mid_color="getCardColor('MID', 0)"
                            :bottom_color="getCardColor('BOTTOM', 0)"
                          />
                        </div>
                      </template>
                      <template v-else>
                        <!-- Back side of card 1 (if it exists, checked in swapPlay method)-->
                        <div @click="swapPlay(0)">
                          <playcard
                            :top="null"
                            :bottom="null"
                            :mid="getCardText('BACK', 0)"
                            :mid_color="getCardColor('BACK', 0)"
                          />
                        </div>
                      </template>
                    </div>
                    <template v-for="i in playCards.length - 1">
                      <div class="carousel-item" :key="i">
                        <template v-if="!playBacks[i]">
                          <!-- Front side of other cards -->
                          <div @click="swapPlay(i)">
                            <playcard
                              :top="getCardText('TOP', i)"
                              :mid="getCardText('MID', i)"
                              :bottom="getCardText('BOTTOM', i)"
                              :top_color="getCardColor('TOP', i)"
                              :mid_color="getCardColor('MID', i)"
                              :bottom_color="getCardColor('BOTTOM', i)"
                            />
                          </div>
                        </template>
                        <template v-else>
                          <!-- Back side of other cards (if it exists, checked in swapPlay method)-->
                          <div @click="swapPlay(i)">
                            <playcard
                              :mid="getCardText('BACK', i)"
                              :mid_color="getCardColor('BACK', i)"
                            />
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottombar :viewAll="true" />
  </div>
</template>

<script>
import Bottombar from "../components/Bottombar.vue";
import Navbar from "../components/Navbar.vue";
import Playcard from "../components/Playcard.vue";
import $ from "jquery";

export default {
  components: {
    Navbar,
    Bottombar,
    Playcard,
  },
  data() {
    return {
      playCards: [],
      playBacks: [],
      hasBack: Boolean,
      categories: [],
      filter: false,
    };
  },
  computed: {
    getGameName() {
      //returns the name of the selected game.
      return this.$store.getters.getSelectedGame.gameName;
    },
  },
  methods: {
    keyPressed: function(event) {
      //Space
      if (event.which == 32) {
        this.swapPlay(this.$store.getters.getCurrentCard - 1);
      }
      //left
      if (event.which == 37) {
        $("#carouselControls").carousel("prev");
      }
      //right
      if (event.which == 39) {
        $("#carouselControls").carousel("next");
      }
      this.handleHeight();
      this.$forceUpdate();
    },
    getCardText(position, index) {
      //returns the text for the given part of the card
      var game = this.$store.getters.getSelectedGame;
      var card = this.playCards[index];
      if (card == null) {
        console.log("Card is null.");
      }
      var layers = new Array();
      layers.push(game.L1);
      layers.push(game.L2);
      layers.push(game.L3);
      layers.push(game.L4);

      var pos;
      for (pos = 0; pos < layers.length; pos++) {
        if (layers[pos] == position) {
          if (this.playCards[index][pos].description == null) {
            // If multiple nodes are in this layer
            var i;
            var text = new String();
            for (i = 0; i < this.playCards[index][pos].length; i++) {
              text += this.playCards[index][pos][i].description + "\n\n";
            }
            return text;
          }
          return this.playCards[index][pos].description;
        }
      }
      console.log("No layer for position: " + position);

      return null;
    },
    getCardColor(position, index) {
      //returns the color for the position (TOP, MID, BOTTOM, BACK) of the card
      //index = card index
      var game = this.$store.getters.getSelectedGame;
      var card = this.playCards[index];
      if (card == null) {
        console.log("Card is null.");
      }
      var layers = new Array();
      layers.push(game.L1);
      layers.push(game.L2);
      layers.push(game.L3);
      layers.push(game.L4);

      var pos;
      for (pos = 0; pos < layers.length; pos++) {
        if (layers[pos] == position) {
          if (card[pos].length == 0) {
            console.log("Node is empty.");
            return "FFFFFF";
          }
          if (card[pos].color == null) {
            if (card[pos][0].color == "" || card[pos][0].color == null) {
              return "FFFFFF";
            } else {
              if (card[pos][0].color.charAt(0) == "#") {
                return card[pos][0].color.substring(1);
              } else {
                return card[pos][0].color;
              }
            }
          } else {
            if (card[pos].color == "") {
              return "FFFFFF";
            }
            if (card[pos].color.charAt(0) == "#") {
              return card[pos].color.substring(1);
            } else {
              return card[pos].color;
            }
          }
        }
      }
      return "FFFFFF";
    },
    swapPlay(index) {
      if (this.hasBack) {
        this.playBacks[index] = !this.playBacks[index];
        this.$forceUpdate();
      }
    },
    setAllCardsToFrontSide() {
      for (var i = 0; i < this.playBacks.length; ++i) {
        this.playBacks[i] = false;
      }
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    handleHeight() {
      //handles size of column where card will be in, needs to be changed after resize so card always stays centered.
      var div = $(".cardcol");
      var height = window.innerHeight - 150;

      div.css("height", height);
    },
    setCardDeck(category) {
      //sets the card deck depending on the set filter of the user.
      var cardDeck = this.$store.getters.getCardDeck;
      this.playCards = new Array();
      this.playBacks = new Array();
      if (category == "Instructions" || category == "All") {
        for (var y = 0; y < cardDeck[2].length; y++) {
          this.playCards.push(cardDeck[2][y]);
        }
      }
      if (category != "Instructions") {
        if (cardDeck[1].length == 0) {
          for (var x = 0; x < cardDeck[0].length; x++) {
            if (cardDeck[0][x][0].title == category || category == "All") {
              this.playCards.push(cardDeck[0][x]);
            }
          }
        } else {
          for (var i = 0; i < 2; i++) {
            for (var j = 0; j < cardDeck[i].length; j++) {
              if (cardDeck[i][j][0].title == category || category == "All") {
                this.playCards.push(cardDeck[i][j]);
              }
            }
          }
        }
      }
      for (var k = 0; k < this.playCards.length; k++) {
        this.playBacks[k] = false;
      }
    },
  },
  mounted() {
    var self = this;
    //sets function to increment and decrement current card when user presses prev or next
    $("#carouselControls").on("slide.bs.carousel", function(event) {
      if (event.direction == "left") {
        self.$store.commit("incrementCurrentCard");
      } else {
        self.$store.commit("decrementCurrentCard");
      }
      //300 must be equal to css: transition-duration: 0.3s;
      self.sleep(300).then(() => self.setAllCardsToFrontSide());

      self.$forceUpdate();
    });

    this.handleHeight();
  },
  created() {
    this.setCardDeck("All");

    var game = this.$store.getters.getSelectedGame;
    var layers = new Array();
    layers.push(game.L1);
    layers.push(game.L2);
    layers.push(game.L3);
    layers.push(game.L4);
    var back = false;
    for (var pos = 0; pos < layers.length; pos++) {
      if (layers[pos] == "BACK") {
        back = true;
      }
    }
    this.hasBack = back;

    window.addEventListener("resize", this.handleHeight);
    window.addEventListener("keyup", this.keyPressed);
  },

  destroyed() {
    window.removeEventListener("resize", this.handleHeight);
  },
  beforeRouteEnter(to, from, next) {
    //if this page gets called directly (not via the modal), it i will route to playlist
    next((vm) => {
      if (vm.getGameName == null) {
        console.log("Accessed view all page without initializing a game.");
        vm.$router.push("/playlist");
      }
    });
  },
  watch: {
    "$store.state.filter": function(category) {
      $(".carousel").carousel(0);

      this.filter = true;
      this.setCardDeck(category);
      this.$store.commit("updateCurrentCard", 1);
      this.$store.commit("updateAllCards", this.playCards.length);
      this.filter = false;
      this.$forceUpdate();
    },
    "$store.state.turnAround": function() {
      this.swapPlay(this.$store.getters.getCurrentCard - 1);
    },
  },
};
</script>

<style scoped lang="scss">
.cardcol {
  width: 0;
}
.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
  font-size: 40px;
  position: relative;
  width: inherit;
  height: 1050px;
}
.carousel-inner {
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 6px 6px rgba(0, 0, 0, 0.85);
}

.card-box {
  margin-left: auto;
  margin-right: auto;
}
.carousel-item {
  transition-duration: 0.3s;
}
</style>
