var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('navbar',{attrs:{"pos":"Design Cards To Go"}}),_c('main',{staticClass:"d-flex justify-content-center mb-3 pl-4 pr-4 ml-1 mr-1",staticStyle:{"padding-top":"10px"}},[(_vm.getCurrentLocale == 'de')?_c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('button',{staticClass:"btn btn-outline-secondary button-back",on:{"click":function($event){return _vm.goBack()}}},[_c('svg',{staticClass:"bi bi-chevron-left",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"}})]),_vm._v(" Zurück ")])]):_c('div',[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('button',{staticClass:"btn btn-outline-secondary button-back",on:{"click":function($event){return _vm.goBack()}}},[_c('svg',{staticClass:"bi bi-chevron-left",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"}})]),_vm._v(" Back ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header headline border-bottom border-dark mb-2"},[_c('h1',{staticStyle:{"font-size":"30px"}},[_vm._v("Design Karten")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph border-bottom border-secondary mb-2",staticStyle:{"padding-bottom":"20px"}},[_vm._v(" Es gibt Design Karten in unterschiedlichen Stilen und Ausführungen: manche haben Text, andere zeigen Bilder, manche haben die Größe regulärer Spielkarten, andere sind größer, oder quadratisch, es gibt kein richtig oder falsch und die Ausführungen solcher Karten können grenzenlos sein. Man kann Design Karten allein verwenden, besser funktionieren sie jedoch in einer Gruppe von zwei oder mehr Personen. Design Karten bauen auf dem Prinzip des “Design Thinking” auf welches von der "),_c('a',{staticClass:"link-dark",attrs:{"href":"https://hpi-academy.de/design-thinking/was-ist-design-thinking/"}},[_vm._v("HPI Academy")]),_vm._v(" wie folgt beschrieben wird: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',{staticClass:"blockquote border-bottom border-secondary mb-2"},[_c('p',[_c('b',[_vm._v(" “Design Thinking ist eine systematische Herangehensweise an komplexe Problemstellungen aus allen Lebensbereichen. "),_c('br'),_vm._v("Der Ansatz geht weit über die klassischen Design-Disziplinen wie Formgebung und Gestaltung hinaus. Im Gegensatz zu vielen Herangehensweisen in Wissenschaft und Praxis, die von der technischen Lösbarkeit die Aufgabe angehen, stehen Nutzerwünsche und -bedürfnisse sowie nutzerorientiertes Erfinden im Zentrum des Prozesses. "),_c('br'),_vm._v("Design Thinker schauen durch die Brille des Nutzers auf das Problem und begeben sich dadurch in die Rolle des Anwenders.” ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph",staticStyle:{"padding-top":"20px"}},[_vm._v(" Wofür sollen Design Karten aber nun verwendet werden? "),_c('br'),_vm._v("Im Idealfall helfen diese, neue Ideen zu generieren oder einen anderen Blickwinkel auf ein Problem oder eine Situation zu bieten. Zudem kann das spielen mit den Karten in einer dafür offenen Runde Spass machen und neuen Schwung bringen. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"header headline border-bottom border-dark mb-2"},[_c('h1',{staticStyle:{"font-size":"30px"}},[_vm._v(" Design Cards ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph border-bottom border-secondary mb-2",staticStyle:{"padding-bottom":"20px"}},[_vm._v(" Design Cards come in different styles and executions, some come with text, some with pictures, some come in the size of a play card, others are bigger or smaller than regular play cards, the variations can be limitless. They can be used by a single entity but are even more useful and productive when used in groups of two or more people."),_c('br'),_vm._v(" Design Cards are based on the principle of “Design Thinking” which is described by the "),_c('a',{staticClass:"link-dark",attrs:{"href":"https://hpi-academy.de/en/design-thinking/what-is-design-thinking/"}},[_vm._v("HPI Academy")]),_vm._v(" as follows: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('blockquote',{staticClass:"blockquote border-bottom border-secondary mb-2"},[_c('p',[_c('b',[_vm._v(" “Design Thinking is a systematic, human-centered approach to solving complex problems within all aspects of life. "),_c('br'),_vm._v("The approach goes far beyond traditional concerns such as shape and layout. And unlike traditional scientific and engineering approaches, which address a task from the view of technical solvability, user needs and requirements as well as user-oriented invention are central to the process. "),_c('br'),_vm._v("This approach calls for continuous feedback between the developer of a solution and the target users. Design Thinkers step into the end user's shoes - not only interviewing them, but also carefully observing their behaviors. "),_c('br'),_vm._v("Solutions and ideas are concretized and communicated in the form of prototypes as early as possible, so that potential users can test them and provide feedback - long before their completion or launch. In this way, Design Thinking generates practical results.“")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"paragraph",staticStyle:{"padding-top":"20px"}},[_vm._v(" But what are Design Cards generally used for? "),_c('br'),_vm._v("They are used to help with an ideation process such as generating new ideas. Design Cards can also provide a way to gain a different perspective on a subject. These cards can be fun and bring momentum when used with an open mind. ")])
}]

export { render, staticRenderFns }